import React, {useEffect, useState} from 'react';
import {Dialog, Button} from '@material-ui/core';
// import QRCode from "react-qr-code";
import QRCode from 'qrcode.react';
import {useTranslation} from 'react-i18next';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import GetAppIcon from '@material-ui/icons/GetApp';

export default function ModalView(props) {
  const {t} = useTranslation();
  const close = () => props.setModalView(!props.modalView);

  const [qrCodeText, setQRCodeText] = useState('');

  useEffect(() => {
    setQRCodeText(props.text);
  }, [props.text]);

  const downloadQRCode = () => {
    const qrCodeURL = document
      .getElementById('qr-gen')
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream');
    const aEl = document.createElement('a');
    aEl.href = qrCodeURL;
    aEl.download = `${props.nameToDownload}.png`;
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
    //  versão antiga (funcional)
    // const canvas = document.getElementById("qr-gen");
    // const pngUrl = canvas
    //   .toDataURL("image/png")
    //   .replace("image/png", "image/octet-stream");
    // let downloadLink = document.createElement("a");
    // downloadLink.href = pngUrl;
    // downloadLink.download = `${qrCodeText}.png`;
    // document.body.appendChild(downloadLink);
    // downloadLink.click();
    // document.body.removeChild(downloadLink);
  };

  return (
    <>
      <Dialog open={props.modalView} onClose={close}>
        <div className="text-center p-3">
          <QRCode
            id="qr-gen"
            value={qrCodeText}
            size={250}
            level="H"
            includeMargin
          />

          <div className="text-center">
            <Button
              style={{textTransform: 'none'}}
              size="small"
              onClick={() => close()}
              className="m-2"
              variant="contained"
              color="secondary">
              <ArrowBackIcon />
              {t('word.back')}
            </Button>
            <Button
              style={{textTransform: 'none'}}
              size="small"
              onClick={() => downloadQRCode()}
              className="m-2"
              variant="contained"
              color="secondary">
              <GetAppIcon />
              {t('word.Download')}
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
}
