import React, {Fragment} from 'react';
import {useTranslation} from 'react-i18next';
import {Grid, Card, CardContent} from '@material-ui/core';
import Place from '@material-ui/icons/Place';
import AccessTime from '@material-ui/icons/AccessTime';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import Accordeon from '../Accordion';

export default function Card1(props) {
  const {t} = useTranslation();

  return (
    <>
      <Grid item xs={12} sm={6} md={4}>
        <Card className="mb-4">
          <div style={{textAlign: 'right'}} className="card-badges">
            <span
              style={{marginBottom: '5px', backgroundColor: 'white'}}
              className="h-auto px-1 py-1 badge badge badge-pill">
              &nbsp;&nbsp; {props.card.place} &nbsp;
              <Place fontSize="small" />
            </span>
            <br />
            <span
              style={{marginBottom: '5px', backgroundColor: 'white'}}
              className="h-auto px-1 py-1 badge badge badge-pill">
              &nbsp;&nbsp; {`${props.card.duration}  ${t('word.minutes')}`}{' '}
              &nbsp;
              <AccessTime fontSize="small" />
              &nbsp;
            </span>
            <br />
            <span
              style={{marginBottom: '5px', backgroundColor: 'white'}}
              className="h-auto px-1 py-1 badge badge badge-pill">
              &nbsp;&nbsp;
              {props.card.price <= 0 && (
                <>
                  {t('word.free')} &nbsp;
                  <CheckCircleOutlineIcon fontSize="small" /> &nbsp;
                </>
              )}
              {props.card.price > 0 && (
                <>
                  {`${props.companyConfigs.currencysymbol} ${props.card.price}`}{' '}
                  &nbsp;
                  <MonetizationOnIcon fontSize="small" /> &nbsp;
                </>
              )}
            </span>
          </div>
          <a
            href="#/"
            onClick={(e) => e.preventDefault()}
            className="image-title-overlay"
            title="">
            <div className="image-title-overlay--bottom">
              <h3 className="display-4 font-size-xs m-0 text-white">
                {props.card.activity}
              </h3>
            </div>
            <img
              style={{marginTop: '-3px'}}
              alt="activity"
              className="card-img-top"
              src={
                props.card.photo
                  ? `data:${props.card.mimety};base64,${props.card.photo}`
                  : 'easy-planner-default-no-image.png'
              }
            />
          </a>
          <CardContent className="p-3">
            {props.card.periods &&
              props.card.periods.length !== 0 &&
              props.card.periods.map((i) => (
                <Accordeon
                  companyConfigs={props.companyConfigs}
                  setOp={props.setOp}
                  idactivity={props.card.idactivity}
                  openAlert={props.openAlert}
                  selectedDate={props.selectedDate}
                  setSelectedDate={props.setSelectedDate}
                  getAll={props.getAll}
                  key={i}
                  card={i}
                  act={props.card}
                  idprofessional={props.card.professional}
                />
              ))}
            {(!props.card.periods || props.card.periods.length === 0) &&
              t('message.no-period')}
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}
