import React, {Fragment} from 'react';

import PropTypes from 'prop-types';

const PresentationLayout = (props) => {
  const {children} = props;

  return <>{children}</>;
};

PresentationLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PresentationLayout;
