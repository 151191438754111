import React, {Fragment} from 'react';

import CardContainerForm from '../../components/CardContainerForm';
import Form from '../../components/forms/Profile';

export default function RegularTables1(props) {
  return (
    <>
      <CardContainerForm>
        <Form pms={props.pms} openAlert={props.openAlert} />
      </CardContainerForm>
    </>
  );
}
