/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, {Fragment, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {FaCalendarAlt} from 'react-icons/fa';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import {useHistory} from 'react-router-dom';
import {Paper, Box, Typography, Button, Fab} from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import {MuiPickersUtilsProvider, DatePicker} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import 'moment/locale/pt-br';
import 'moment/locale/es';
import '../../assets/base.scss';

function TabPanel(props) {
  const {children, value, index, ...other} = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}>
      {value === index && <Box p={4}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function PageTitle(props) {
  const {t, i18n} = useTranslation();
  const {
    getAll,
    pageTitleStyle,
    pageTitleBackground,
    pageTitleDescription,
    selectedDate,
    setSelectedDate,
    printer,
    act,
  } = props;

  // const [ disable, setDisable ] = useState(false)
  const [modal1, setModal1] = useState(false);
  const [open, setOpen] = useState(false);
  const [maxDate, setMaxDate] = useState(new Date());
  const history = useHistory();

  useEffect(() => {
    setMaxDate(
      new Date(
        `${maxDate.getFullYear() + 2}-${
          maxDate.getMonth() + 1
        }-${maxDate.getDate()} 01:00:00`,
      ),
    );

    // eslint-disable-next-line
  }, []);

  const toggle1 = () => setModal1(!modal1);

  const handleDateChange = async (date) => {
    setSelectedDate(date);

    history.push(`/home?date=${moment(date).format(t('format.date'))}`);
    await getAll(new Date(date));

    toggle1();
  };

  moment.locale(i18n.language);

  return (
    <>
      <Paper
        square
        elevation={2}
        className={clsx('app-page-title', pageTitleStyle, pageTitleBackground)}>
        <div>
          <Box className="app-page-title--first">
            <div className="app-page-title--heading">
              <h1>{props.titleHeading}</h1>
              {pageTitleDescription && (
                <div className="app-page-title--description">
                  {props.titleDescription}
                </div>
              )}
            </div>
          </Box>
        </div>
        <div className="d-flex align-items-center">
          <Button
            variant="contained"
            color="default"
            onClick={() => setOpen(!open)}
            style={{maxWidth: '160px'}}
            startIcon={<FaCalendarAlt />}>
            <MuiPickersUtilsProvider
              libInstance={moment}
              locale={i18n.language}
              utils={MomentUtils}>
              <DatePicker
                // label={t('word.date1')}
                className="m-1"
                variant="inline"
                disableToolbar
                value={selectedDate}
                maxDate={maxDate}
                onChange={handleDateChange}
                format="L"
                autoOk
                invalidDateMessage={t('message.invalid-date')}
              />
            </MuiPickersUtilsProvider>
          </Button>

          {/* <Dialog
            open={modal1}
            onClose={toggle1}
            aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{t('word.my-schedule')}</DialogTitle>
            <form
              onSubmit={handleSubmit}
            > */}
          {/* <DialogContent>
                <DialogContentText>
                  {t('message.schedule-info')}
                </DialogContentText>
                <Grid item xs={12} sm={12} style={{marginRight: '20px', marginTop: '-2.2px'}} >

            </Grid>
              </DialogContent> */}
          {/* <DialogActions>
                <Button style={{textTransform: 'none'}} disabled={disable} onClick={toggle1} className="m-1" variant="contained" color="secondary">
                  <ReplayIcon/>
                  {t('word.cancel')}
                </Button>
                <Button style={{textTransform: 'none'}} disabled={disable} type='submit' className="m-1" color="secondary" variant="contained">
                  <CheckIcon />
                  {t('word.search')}
                </Button>
              </DialogActions> */}
          {/* </form> */}
          {/* </Dialog> */}
          <div className="ml-auto font-size-xs" style={{paddingLeft: '10px'}}>
            <Fab
              size="small"
              disabled={act.length < 1}
              act
              aria-label="add"
              color="secondary"
              onClick={printer}>
              <PrintIcon />
            </Fab>
          </div>
        </div>
      </Paper>
    </>
  );
}

export default PageTitle;
