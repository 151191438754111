import React, {Fragment, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import Enum from 'helpers/enum';
import CardContainer from '../../CardContainer';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tablerow: {
    cursor: 'pointer',
    transition: 'all ease 0.2s',
    '&:hover': {
      backgroundColor: '#CCC',
    },
  },
}));

export default function RegularTables1(props) {
  const classes = useStyles();
  const {t} = useTranslation();
  const history = useHistory();
  const {list} = props;

  useEffect(() => {}, []);

  return (
    <>
      <CardContainer>
        <div className={classes.root}>
          <TableContainer className="mb-4" component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{t('word.name')}</TableCell>
                  {/* <TableCell align="left">{t('word.name')}</TableCell> */}
                  <TableCell align="left">{t('word.document')}</TableCell>
                  <TableCell align="left">{t('word.email')}</TableCell>
                  {Enum.arrPms.includes(props.pms) && (
                    <>
                      <TableCell align="left">{t('word.N-account')}</TableCell>
                      <TableCell align="left">{t('word.uhpms')}</TableCell>
                    </>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {list &&
                  list.map((i) => (
                    <TableRow
                      key={i.idcustomer}
                      className={classes.tablerow}
                      onClick={() => history.push(`/customer/${i.idcustomer}`)}>
                      {' '}
                      <TableCell component="th" scope="row">
                        {i.name}
                      </TableCell>
                      <TableCell align="left">{i.document}</TableCell>
                      <TableCell align="left">{i.email}</TableCell>
                      {Enum.arrPms.includes(props.pms) && (
                        <>
                          <TableCell align="left">{i.idaccountpms}</TableCell>
                          <TableCell align="left">{i.uhpms}</TableCell>
                        </>
                      )}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </CardContainer>
    </>
  );
}
