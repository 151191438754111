import React, {Fragment} from 'react';
import {useTranslation} from 'react-i18next';

import clsx from 'clsx';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';

import {IconButton, Box} from '@material-ui/core';

const HeaderLogo = (props) => {
  const {sidebarToggle, sidebarHover} = props;
  const {t} = useTranslation();
  return (
    <>
      <div
        className={clsx('app-header-logo', {
          'app-header-logo-close': sidebarToggle,
          'app-header-logo-open': sidebarHover,
        })}>
        <Box className="header-logo-wrapper" title={t('word.app-name')}>
          <Link to="/home" className="header-logo-wrapper-link">
            <IconButton
              style={{backgroundColor: 'white'}}
              size="medium"
              className="header-logo-wrapper-btn">
              <img
                className="app-header-logo-img"
                alt={t('word.app-name')}
                src="fav.ico"
              />
            </IconButton>
          </Link>
          <Box className="header-logo-text">{t('word.app-name')}</Box>
        </Box>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarHover: state.ThemeOptions.sidebarHover,
});

export default connect(mapStateToProps)(HeaderLogo);
