/* eslint-disable no-restricted-syntax */
import React, {Fragment, useEffect, useState} from 'react';
import {
  getUsersActive,
  addProfessional,
  editPeriod,
  getOneProfessional,
  deleteProfessional,
} from 'helpers/api';
import {
  Dialog,
  DialogTitle,
  Grid,
  Button,
  Card,
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  makeStyles,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
} from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import CheckIcon from '@material-ui/icons/Check';
import ReplayIcon from '@material-ui/icons/Replay';
import {useTranslation} from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Delete from '../../ButtonDelete';
import Error from '../../Error';
import ModalView from '../ModalView';

const useStyles = makeStyles(() => ({
  tablerow: {
    cursor: 'pointer',
    transition: 'all ease 0.2s',
    '&:hover': {
      backgroundColor: '#CCC',
    },
  },
  clicktablerow: {
    cursor: 'pointer',
    transition: 'all ease 0.2s',
    backgroundColor: '#CCC',
  },
}));

export default function ModalPeriod(props) {
  const {modalOpen, setModalOpen} = props;
  const {setEdit, edit} = props;
  const {t} = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  const [result, setResult] = useState([]);
  const [fresult, setfResult] = useState([]);
  const [disable, setDisable] = useState(false);
  const [clickId, setClickId] = useState();
  const [name, setName] = useState('');
  const [occupation, setOcuppation] = useState('');
  const [idUser, setIdUser] = useState();
  const [idprofessional, setIdProfessional] = useState();
  const [op, setOp] = useState(false);

  const [modalView, setModalView] = useState(false);

  useEffect(() => {
    setResult([]);
    setfResult([]);
    if (props.id && modalOpen === true) {
      const getOne = async (id) => {
        const res = await getOneProfessional(id);
        if (res.errors) {
          for (const i of res.errors) {
            props.openAlert(
              t(`message.${i.code}`, {param: t(`word.${i.field}`)}),
              i.color,
            );
          }
          history.replace('/activity');
        } else {
          setName(res['user.name']);
          setOcuppation(res['user.occupation.occupation']);
          setIdProfessional(res.idprofessional);
          setIdUser(res.iduser);
          setEdit(true);
          setOp(true);
          setModalOpen(true);
        }
      };
      getOne(props.id);
    } else if (
      modalOpen === true &&
      props.id === null &&
      props.idactivity !== null
    ) {
      const getAllUserActive = async () => {
        const res = await getUsersActive(props.idactivity);
        if (res.errors) {
          for (const i of res.errors) {
            props.openAlert(
              t(`message.${i.code}`, {param: t(`word.${i.field}`)}),
              i.color,
            );
          }
        } else {
          if (
            res.length <= 0 &&
            modalOpen === true &&
            props.id === null &&
            props.idactivity !== null
          ) {
            setModalOpen(false);
            setModalView(true);
          }
          setResult(res);
          setfResult(res);
          setOp(true);
        }
      };
      getAllUserActive();
    }
    // eslint-disable-next-line
  }, [props.idactivity, props.id, props.modalOpen]);

  const SubmitPro = async (e) => {
    e.preventDefault();
    setDisable(true);

    let res;
    if (props.id) {
      res = await editPeriod(props.id);
      setResult(res);
    } else {
      res = await addProfessional(props.idactivity, clickId);
      setResult(res);
    }
    if (res && !res.errors) {
      if (props.id) {
        props.openAlert(
          t('message.edit-record', {param: t('word.professional')}),
          'success',
        );
      } else {
        props.openAlert(
          t('message.insert-record', {param: t('word.professional')}),
          'success',
        );
      }
      setModalOpen(false);
      setDisable(false);
      props.setObserver(!props.observer);
    } else {
      setDisable(false);
    }
  };

  const delProfessional = async (id) => {
    const res = await deleteProfessional(id);
    setResult(res);
    if (res && !res.errors) {
      props.openAlert(
        t('message.delete-record', {param: t('word.professional')}),
        'success',
      );
      setModalOpen(false);
      props.setObserver(!props.observer);
      setModalOpen(false);
      setEdit(false);
      setClickId();
    }
  };
  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    let results = [];
    results = result.filter(
      (row) => row.name.toLowerCase().search(value) !== -1,
    );
    setfResult(results);
  };

  const Close = () => {
    setOp(false);
    setClickId();
    setEdit(false);
    setModalOpen(false);
  };

  return (
    <>
      <ModalView
        modalView={modalView}
        setModalView={setModalView}
        message1={t('message.record-full-select', {
          param: t('word.professional'),
        })}
        messageButton={t('word.back')}
      />

      <Dialog
        open={modalOpen && op}
        onClose={() => Close()}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {t('word.professionals')}
        </DialogTitle>
        <PerfectScrollbar>
          <Card className="p-4 mb-4">
            {result.errors && <Error result={result} />}
            <Grid container direction="row" spacing={12}>
              <Grid container sm={12}>
                <form onSubmit={SubmitPro}>
                  <TableContainer className="mb-4" component={Paper}>
                    <Table aria-label="simple table">
                      <TableHead>
                        <Grid item>
                          {modalOpen === true &&
                            props.id === null &&
                            props.idactivity !== null && (
                              <FormControl className="w-100 m-2">
                                <InputLabel htmlFor="input-with-icon-adornment">
                                  {t('word.search')}
                                </InputLabel>
                                <Input
                                  onChange={(event) => handleSearch(event)}
                                  // style={{maxWidth: '500px'}}
                                  startAdornment={
                                    <InputAdornment position="start">
                                      <SearchIcon />
                                    </InputAdornment>
                                  }
                                />
                              </FormControl>
                            )}
                        </Grid>
                        <TableRow>
                          <TableCell>{t('word.name')}</TableCell>
                          <TableCell align="right">
                            {t('word.occupation')}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {edit === true && (
                          <TableRow key={idUser}>
                            <TableCell component="th" scope="row">
                              {name}
                            </TableCell>
                            <TableCell align="left">{occupation}</TableCell>
                          </TableRow>
                        )}
                        {/* {result.length <= 0 &&  fresult.length <= 0 && modalOpen === true && props.id === null && props.idactivity !== null &&
                          <span>{t('word.nobody')}</span>
                        } */}
                        {result &&
                          edit !== true &&
                          fresult &&
                          fresult.map((i) => (
                            <TableRow
                              key={i.iduser}
                              className={
                                clickId === i.iduser
                                  ? classes.clicktablerow
                                  : classes.tablerow
                              }
                              onClick={() => setClickId(i.iduser)}>
                              <TableCell component="th" scope="row">
                                {i.name}
                              </TableCell>
                              <TableCell align="left">
                                {i['occupation.occupation']}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </form>
              </Grid>
            </Grid>
          </Card>
        </PerfectScrollbar>
        <div
          style={{float: 'right', paddingBottom: '20px'}}
          className="text-center">
          <Button
            style={{textTransform: 'none'}}
            disabled={disable}
            onClick={() => Close()}
            className="m-2"
            variant="contained"
            color="secondary">
            <ReplayIcon />
            {t('word.cancel')}
          </Button>
          {edit === true && (
            <Delete
              func={delProfessional}
              id={idprofessional}
              disabled={disable}
            />
          )}
          {modalOpen === true &&
            props.id === null &&
            props.idactivity !== null && (
              <Button
                style={{textTransform: 'none'}}
                disabled={disable}
                onClick={SubmitPro}
                className="m-2"
                color="secondary"
                variant="contained">
                <CheckIcon />
                {t('word.add')}
              </Button>
            )}
        </div>
      </Dialog>
    </>
  );
}
