/* eslint-disable no-restricted-syntax */
import React, {Fragment, useState, useEffect} from 'react';
import {CustomerFilter} from 'helpers/api';
import {useDispatch} from 'react-redux';
import clsx from 'clsx';
import {useTranslation} from 'react-i18next';
import {FaEllipsisV, FaFilter} from 'react-icons/fa';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {Grid, Fab, Typography, TextField} from '@material-ui/core';
import moment from 'moment';
import 'moment/locale/pt-br';
import 'moment/locale/es';
import Enum from 'helpers/enum';
import {Page} from 'store/reducers/Page/pageAction';
import ClientFilter from '../../components/List/CustomerList';

function LivePreviewExample(props) {
  const {t, i18n} = useTranslation();
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const [name, setName] = useState('');
  const [document, setDocument] = useState('');
  const [nro, setNro] = useState('');
  const [disable, setDisable] = useState(false);

  moment.locale(i18n.language);

  const getAll = async () => {
    setDisable(true);
    const body = {
      nro,
      document,
      name,
    };

    const res = await CustomerFilter(body);
    if (res && !res.errors) {
      setList(res);
    } else if (res.errors) {
      setList([]);
      for (const i of res.errors) {
        props.openAlert(
          t(`message.${i.code}`, {param: t(`word.${i.field}`)}),
          i.color,
        );
      }
    }
    setDisable(false);
  };

  useEffect(() => {
    dispatch(Page(t('word.customer')));
    getAll();
    // eslint-disable-next-line
  }, []);

  const getByFilter = () => {
    getAll();
  };

  const [isSidebarMenuOpen, setIsSidebarMenuOpen] = useState(false);

  const toggleSidebarMenu = () => setIsSidebarMenuOpen(!isSidebarMenuOpen);

  return (
    <>
      <div className="app-inner-content-layout app-inner-content-layout-fixed">
        <div className="d-flex d-lg-none p-4 order-0 justify-content-between align-items-center">
          <Fab onClick={toggleSidebarMenu} color="primary" size="small">
            <FaEllipsisV className="font-size-sm" />
          </Fab>
        </div>
        <div
          className={clsx(
            'app-inner-content-layout--sidebar app-inner-content-layout--sidebar__lg bg-secondary border-right',
            {'layout-sidebar-open': isSidebarMenuOpen},
          )}>
          <PerfectScrollbar>
            <div className="px-4 pt-4 pb-3">
              <Typography color="primary" component="div" className="d-flex">
                <div
                  className="ml-auto font-size-xs"
                  style={{paddingLeft: '80px'}}>
                  <Fab
                    size="small"
                    aria-label="add"
                    color="secondary"
                    onClick={() => getByFilter()}>
                    <FaFilter />
                  </Fab>
                </div>
              </Typography>
              <Grid item direction="column">
                <div className="p-4">
                  <Typography
                    color="primary"
                    component="div"
                    className="d-flex pb-3 align-items-center">
                    <div className="text-first font-weight-bold">
                      {t('word.name')}
                    </div>
                    <div className="ml-auto font-size-xs" />
                  </Typography>
                  <Grid container spacing={4} className="font-size-xs">
                    <Grid item xs={12}>
                      <TextField
                        id="outlined-helperText"
                        label={t('word.name')}
                        disabled={disable}
                        variant="outlined"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        inputProps={{maxLength: 128}}
                      />
                    </Grid>
                  </Grid>
                </div>
                <div className="divider mt-2" />
                <div className="p-4">
                  <Typography
                    color="primary"
                    component="div"
                    className="d-flex pb-3 align-items-center">
                    <div className="text-first font-weight-bold">
                      {t('word.document')}
                    </div>
                    <div className="ml-auto font-size-xs" />
                  </Typography>
                  <Grid container spacing={4} className="font-size-xs">
                    <Grid item xs={12}>
                      <TextField
                        id="outlined-helperText"
                        label={t('word.document')}
                        disabled={disable}
                        variant="outlined"
                        value={document}
                        onChange={(e) => setDocument(e.target.value)}
                        inputProps={{maxLength: 128}}
                      />
                    </Grid>
                  </Grid>
                </div>
                {Enum.arrPms.includes(props.pms) && (
                  <>
                    <div className="divider mt-2" />
                    <div className="p-4">
                      <Typography
                        color="primary"
                        component="div"
                        className="d-flex pb-3 align-items-center">
                        <div className="text-first font-weight-bold">
                          {t('word.N-account')}
                        </div>
                        <div className="ml-auto font-size-xs" />
                      </Typography>
                      <Grid container spacing={4} className="font-size-xs">
                        <Grid item xs={12}>
                          <TextField
                            id="outlined-helperText"
                            label={t('word.N-account')}
                            disabled={disable}
                            variant="outlined"
                            value={nro}
                            type="number"
                            onChange={(e) => setNro(e.target.value)}
                            inputProps={{maxLength: 128}}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </>
                )}
              </Grid>
            </div>
          </PerfectScrollbar>
        </div>
        <div className="app-inner-content-layout--main bg-white p-0 card-box">
          <PerfectScrollbar>
            <div className="p-4">
              <ClientFilter
                pms={props.pms}
                setList={setList}
                list={list}
                openAlert={props.openAlert}
                setOp={props.setOp}
              />
            </div>
          </PerfectScrollbar>
        </div>
        {/* <div
          onClick={toggleSidebarMenu}
          className={clsx('sidebar-inner-layout-overlay', {
            active: isSidebarMenuOpen,
          })}
        /> */}
      </div>
    </>
  );
}

export default React.memo(LivePreviewExample);
