import React, {Fragment, useState, useEffect} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {editPassword} from 'helpers/api';
import {Grid, Button, Card, TextField} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ReplayIcon from '@material-ui/icons/Replay';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Page} from 'store/reducers/Page/pageAction';
import {doLogin} from '../../helpers/auth';
import Error from '../../components/Error';

export default function Formulario(props) {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const history = useHistory();

  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [result, setResult] = useState({});
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    dispatch(Page(t('word.user')));
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisabled(true);
    setResult('');

    const res = await editPassword(password, newPassword, confirmPassword);
    setResult(res);
    if (res && !res.errors) {
      props.openAlert(
        t('message.edit-record-f', {param: t('word.password')}),
        'success',
      );
      history.push('/home');
      doLogin(res.token);
    }
    setDisabled(false);
  };

  return (
    <>
      <Card className="p-4 mb-4">
        {result.errors && <Error result={result} />}
        <form onSubmit={handleSubmit}>
          <Grid container direction="row" spacing={12}>
            <Grid container sm={12} md={4}>
              <TextField
                type="password"
                className="m-2"
                label={t('word.password')}
                id="standard-size-small"
                size="small"
                required
                disabled={disabled}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                fullWidth
                inputProps={{maxLength: 64}}
              />
            </Grid>
            <Grid container sm={12} md={4}>
              <TextField
                type="password"
                className="m-2"
                label={t('word.new-password')}
                id="standard-size-small"
                required
                disabled={disabled}
                size="small"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                fullWidth
                inputProps={{maxLength: 64}}
              />
            </Grid>
            <Grid container sm={12} md={4}>
              <TextField
                type="password"
                className="m-2"
                label={t('word.confirm-password')}
                id="standard-size-small"
                size="small"
                required
                disabled={disabled}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                fullWidth
                inputProps={{maxLength: 64}}
              />
            </Grid>
          </Grid>
          <div style={{float: 'right'}}>
            <Link to="/home">
              <Button
                style={{textTransform: 'none'}}
                disabled={disabled}
                className="m-2"
                variant="contained"
                color="secondary">
                <ReplayIcon />
                {t('word.cancel')}
              </Button>
            </Link>
            <Button
              style={{textTransform: 'none'}}
              disabled={disabled}
              type="submit"
              className="m-2"
              color="secondary"
              variant="contained">
              <CheckIcon />
              {t('word.save')}
            </Button>
          </div>
        </form>
      </Card>
    </>
  );
}
