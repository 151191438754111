import React, {Fragment} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Sidebar, Header} from '../../layout-components';

const LeftSidebar = (props) => {
  const {children, sidebarToggle, sidebarFixed, contentBackground} = props;

  return (
    <>
      <div className={clsx('app-wrapper', contentBackground)}>
        <Header
          pms={props.pms}
          configs={props.configs}
          openAlert={props.openAlert}
        />
        <div
          className={clsx('app-main', {
            'app-main-sidebar-static': !sidebarFixed,
          })}>
          <Sidebar
            bar={props.bar}
            companyConfigs={props.companyConfigs}
            pms={props.pms}
            configs={props.configs}
          />
          <div
            className={clsx('app-content', {
              'app-content-sidebar-collapsed': sidebarToggle,
              'app-content-sidebar-fixed': sidebarFixed,
            })}>
            <div className="app-content--inner">
              <div className="app-content--inner__wrapper">{children}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

LeftSidebar.propTypes = {
  children: PropTypes.node.isRequired,
};

const mapStateToProps = (state) => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  sidebarFixed: state.ThemeOptions.sidebarFixed,

  headerFixed: state.ThemeOptions.headerFixed,
  headerSearchHover: state.ThemeOptions.headerSearchHover,
  headerDrawerToggle: state.ThemeOptions.headerDrawerToggle,

  contentBackground: state.ThemeOptions.contentBackground,
});

export default connect(mapStateToProps)(LeftSidebar);
