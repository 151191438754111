/* eslint-disable no-restricted-syntax */
import React, {Fragment, useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useLocation, useHistory} from 'react-router-dom';
import {Grid} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {getFilteredSchedulePdf, getHome} from 'helpers/api';
import {getIdUser} from 'helpers/auth';
import moment from 'moment';
import Enum from 'helpers/enum';
import {Page} from 'store/reducers/Page/pageAction';
import Card from '../../components/Card';
import {PageTitle} from '../../layout-components';

export default function Home(props) {
  const {t} = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const useQueryString = () => new URLSearchParams(useLocation().search);

  const query = useQueryString();

  const [act, setAct] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const name = useSelector((state) => state.user.name);
  const iduser = getIdUser();

  const getAll = async (date) => {
    props.setOp(true);
    const res = await getHome(date, date.getDay());
    if (res && !res.errors) {
      setAct(res);
    } else if (res.errors) {
      for (const i of res.errors) {
        props.openAlert(
          t(`message.${i.code}`, {param: t(`word.${i.field}`)}),
          i.color,
        );
      }
    }
    props.setOp(false);
  };

  useEffect(() => {
    console.log(props.companyConfigs);

    dispatch(Page(`${t('word.hello')}, ${name}`));
    if (query.get('date') !== null) {
      let d = query.get('date').split('/').reverse().join('-');
      d = moment(d).format('YYYY-MM-DD');
      if (moment(d).isValid() === true) {
        setSelectedDate(new Date(`${d} 00:00:00`));
        getAll(new Date(`${d} 00:00:00`));
        history.push(`/home?date=${moment(d).format(t('format.date'))}`);
      } else {
        getAll(new Date());
        history.push(
          `/home?date=${moment(selectedDate).format(t('format.date'))}`,
        );
      }
    } else {
      getAll(new Date());
      history.push(
        `/home?date=${moment(selectedDate).format(t('format.date'))}`,
      );
    }

    // eslint-disable-next-line
  }, []);

  const pdf = async (
    first,
    last,
    actID,
    actName,
    usersID,
    usersName,
    status,
    status2,
    typeFilter,
  ) => {
    const res = await getFilteredSchedulePdf(
      first,
      last,
      actID,
      actName,
      usersID,
      usersName,
      status,
      status2,
      typeFilter,
    );
    if (res && !res.errors) {
      const pdfWindow = window.open('');
      pdfWindow.document.write(
        `<iframe width='100%' height='100%' src='data:application/pdf;base64, ${encodeURI(
          res,
        )}'></iframe>`,
      );
    } else {
      for (const i of res.errors) {
        props.openAlert(
          t(`message.${i.code}`, {param: t(`word.${i.field}`)}),
          i.color,
        );
      }
    }
    props.setOp(false);
  };

  const printer = () => {
    props.setOp(true);
    const d = new Date(selectedDate);
    const date = moment(d).format('L');
    let formatDate;
    if (t('format.date') === 'DD/MM/YYYY') {
      formatDate = date.split('/').reverse().join('-');
      formatDate = moment(formatDate).format('YYYY-MM-DD');
    } else {
      formatDate = moment(date).format('YYYY-MM-DD');
    }
    pdf(
      formatDate,
      formatDate,
      [],
      [],
      [iduser],
      [name],
      [
        Enum.schedule_status.scheduled,
        Enum.schedule_status.canceled,
        Enum.schedule_status.inprogress,
        Enum.schedule_status.finished,
      ],
      ['Agendado', 'Cancelado', 'Iniciado', 'Finalizado'],
      0,
    );
  };

  return (
    <>
      {act && act.length > 0 && (
        <PageTitle
          getAll={getAll}
          titleHeading={t('word.my-schedule')}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          printer={printer}
          openAlert={props.openAlert}
          act={act}
        />
      )}
      <Grid container direction="row" spacing={3}>
        {act &&
          act.map((i) => (
            <Card
              companyConfigs={props.companyConfigs}
              setOp={props.setOp}
              openAlert={props.openAlert}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              getAll={getAll}
              key={i}
              card={i}
            />
          ))}
      </Grid>
    </>
  );
}
