import React, {Fragment} from 'react';
import {useParams} from 'react-router';

import CardContainerForm from '../../components/CardContainerForm';
import Form from '../../components/forms/Profile';

export default function RegularTables1(props) {
  const {id} = useParams();

  return (
    <>
      <CardContainerForm>
        <Form pms={props.pms} id={id} openAlert={props.openAlert} />
      </CardContainerForm>
    </>
  );
}
