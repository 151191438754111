import React, {Fragment} from 'react';
import {Dialog, Button, Grid, CardContent} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import Place from '@material-ui/icons/Place';
import AccessTime from '@material-ui/icons/AccessTime';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const FormatImage = (props) => {
  if (props.photo.photo) {
    return (
      <img
        style={{marginTop: '-3px'}}
        className="card-img-top"
        src={props.photo.photo}
        alt="imagem"
      />
    );
  }
  if (props.photo) {
    return (
      <img
        style={{marginTop: '-3px'}}
        className="card-img-top"
        src={`data:${props.type};base64,${props.photo}`}
        alt="imagem"
      />
    );
  }
  return (
    <img
      style={{marginTop: '-3px'}}
      alt="activity"
      className="card-img-top"
      src="easy-planner-default-no-image.png"
    />
  );
};

export default function ModalView(props) {
  const close = () => props.setModalView(!props.modalView);
  const {t} = useTranslation();
  const {data} = props;
  const currency = useSelector((state) => state.symbol.symbol);

  return (
    <>
      <Dialog open={props.modalView} onClose={close}>
        <div className="p-4">
          <PerfectScrollbar>
            <Grid
              container
              // spacing={4}
              justify="center"
              style={{paddingTop: '10px'}}>
              <Grid item xs={10}>
                <div
                  className="image-title-overlay"
                  style={{cursor: 'pointer'}}
                  // onClick={() => history.push(`/schedule/activity/${data.idactivity}`)}
                >
                  <>
                    <div
                      style={{textAlign: 'right', color: props.prim}}
                      className="card-badges">
                      <span
                        style={{marginBottom: '5px', backgroundColor: 'white'}}
                        className="h-auto px-1 py-1 badge badge badge-pill">
                        &nbsp;&nbsp; {data.place} &nbsp;
                        <Place fontSize="small" />
                      </span>
                      <br />
                      <span
                        style={{marginBottom: '5px', backgroundColor: 'white'}}
                        className="h-auto px-1 py-1 badge badge badge-pill">
                        &nbsp;&nbsp;{' '}
                        {data.duration
                          ? `${data.duration}  ${t('word.minutes')}`
                          : ''}{' '}
                        &nbsp;
                        <AccessTime fontSize="small" />
                        &nbsp;
                      </span>
                      <br />
                      <span
                        style={{marginBottom: '5px', backgroundColor: 'white'}}
                        className="h-auto px-1 py-1 badge badge badge-pill">
                        &nbsp;&nbsp;
                        {(data.price <= 0 || !data.price) && (
                          <>
                            {t('word.free')} &nbsp;
                            <CheckCircleOutlineIcon fontSize="small" /> &nbsp;
                          </>
                        )}
                        {data.price > 0 && (
                          <>
                            {`${currency} ${data.price}`} &nbsp;
                            <MonetizationOnIcon fontSize="small" /> &nbsp;
                          </>
                        )}
                      </span>
                    </div>
                    <div className="image-title-overlay--bottom">
                      <h3 className="display-4 font-size-xs m-0 text-white">
                        {data.activity}
                      </h3>
                    </div>
                    <FormatImage photo={data.photo} />
                  </>
                </div>
                <CardContent className="p-3">
                  <p
                    className="card-text descript"
                    style={{whiteSpace: 'pre-line', textAlign: 'justify'}}>
                    {data.description}
                  </p>
                  <div style={{float: 'right'}}>
                    <Button
                      color="primary"
                      style={{
                        backgroundColor: props.prim,
                        margin: '10px',
                        marginTop: '-5px',
                        marginRight: '-4px',
                        textTransform: 'none',
                      }}
                      variant="contained">
                      {t('word.app-schedule')}
                    </Button>
                  </div>
                </CardContent>
              </Grid>
            </Grid>
          </PerfectScrollbar>

          <div className="text-center pt-2">
            <Button
              style={{textTransform: 'none'}}
              onClick={() => close()}
              className="m-2"
              variant="contained"
              color="secondary">
              <ArrowBackIcon />
              {t('word.back')}
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
}
