import React, {Fragment, useEffect, useState} from 'react';
import {
  Dialog,
  DialogTitle,
  Card,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  Button,
} from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import {makeStyles} from '@material-ui/core/styles';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ModalView from '../ModalView';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tablerow: {
    transition: 'all ease 0.2s',
    '&:hover': {
      backgroundColor: '#CCC',
    },
  },
  clicktablerow: {
    transition: 'all ease 0.2s',
    backgroundColor: '#CCC',
  },
  hov: {
    cursor: 'pointer',
  },
}));

export default function ModalPeriod(props) {
  const classes = useStyles();
  const {t} = useTranslation();
  const {modalHis, setModalHis, setHist, hist} = props;

  const [id, setId] = useState();
  const [msg, setMsg] = useState();
  const [modalMsg, setModalMsg] = useState(false);

  const handleClose = () => {
    setModalHis(false);
    setHist();
  };

  useEffect(() => {
    // eslint-disable-next-line no-restricted-syntax
    for (const i of hist) {
      i.date = moment(i.datetime).format(t('format.date'));
      i.time = moment(i.datetime).format('LT');
      i.pms = i.returnpms === 0 && i.integrated === true ? 1 : 0;
    }

    // eslint-disable-next-line
  }, []);

  const RemoveSet = async () => {
    setMsg('');
    setId();
  };

  useEffect(() => {
    if (modalMsg === false && msg !== '') {
      RemoveSet();
    }
    // eslint-disable-next-line
  }, [modalMsg]);

  const handleSet = async (k, m) => {
    setId(k);
    setMsg(m);
    setModalMsg(true);
  };

  return (
    <>
      {modalMsg === true && (
        <ModalView
          modalView={modalMsg}
          icon={false}
          setModalView={setModalMsg}
          message1={msg}
          messageButton={t('word.back')}
        />
      )}
      <Dialog
        open={modalHis}
        onClose={() => handleClose()}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {t('word.pms-historic')}
        </DialogTitle>
        <PerfectScrollbar>
          <Card className="p-4 mb-4">
            <div className={classes.root}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>{t('word.date')}</TableCell>
                    <TableCell align="left">{t('word.Time')}</TableCell>
                    <TableCell align="left">{t('word.Send-by')}</TableCell>
                    <TableCell align="left">
                      {t('word.integration-status')}
                    </TableCell>
                    <TableCell align="left">{t('word.Message')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {hist &&
                    hist.map((i, k) => (
                      <TableRow
                        key={`${i['user.name']}-${i['customer.name']}`}
                        className={
                          id === k ? classes.clicktablerow : classes.tablerow
                        }>
                        <TableCell component="th" scope="row">
                          {i.date}
                        </TableCell>
                        <TableCell align="left">{i.time}</TableCell>
                        <TableCell align="left">
                          {i['user.iduser']
                            ? i['user.name']
                            : i['customer.name']}
                        </TableCell>
                        <TableCell align="left">
                          <Tooltip
                            arrow
                            title={t(`word.schedule-pms.${i.pms}`)}>
                            <span
                              className={`badge-circle mt-2 mr-2 align-self-start badge badge-${t(
                                `word.schedule-status-color.${
                                  i.pms === 0 ? 1 : 3
                                }`,
                              )}`}>
                              .
                            </span>
                          </Tooltip>
                        </TableCell>
                        <TableCell align="left">
                          {i.messagepms ? (
                            <MoreHorizIcon
                              className={
                                id === k ? classes.clicktablerow : classes.hov
                              }
                              onClick={() => handleSet(k, i.messagepms)}
                            />
                          ) : (
                            ''
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </div>
          </Card>
        </PerfectScrollbar>
        <div style={{paddingBottom: '20px'}} className="text-center">
          <Button
            style={{height: '40px'}}
            onClick={() => handleClose()}
            variant="contained"
            color="primary"
            className="mx-1">
            <span className="btn-wrapper--label">{t('word.back')}</span>
          </Button>
        </div>
      </Dialog>
    </>
  );
}
