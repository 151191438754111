/* eslint-disable no-restricted-syntax */
/* eslint-disable react/prop-types */
import React, {Fragment, useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {FaPlus} from 'react-icons/fa';
import {makeStyles} from '@material-ui/core/styles';
import {
  Fab,
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
} from '@material-ui/core';

import SearchIcon from '@material-ui/icons/Search';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Page} from 'store/reducers/Page/pageAction';

import CardContainer from '../../components/CardContainer';
import {getTypeActivities} from '../../helpers/api';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tablerow: {
    cursor: 'pointer',
    transition: 'all ease 0.2s',
    '&:hover': {
      backgroundColor: '#CCC',
    },
  },
}));

export default function RegularTables1(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const {t} = useTranslation();

  const [typeactivity, setTypeactivity] = useState([]);
  const [filteredTypeActivity, setFilteredTypeActivity] =
    useState(typeactivity);

  useEffect(() => {
    dispatch(Page(t('word.type-activities')));
    const getAll = async () => {
      const res = await getTypeActivities();
      if (res && !res.errors) {
        setTypeactivity(res);
        setFilteredTypeActivity(res);
      } else if (res.errors) {
        for (const i of res.errors) {
          props.openAlert(
            t(`message.${i.code}`, {param: t(`word.${i.field}`)}),
            i.color,
          );
        }
      }
    };
    getAll();
    // eslint-disable-next-line
  }, []);

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    let result = [];
    result = typeactivity.filter(
      (row) => row.typeactivity.toLowerCase().search(value) !== -1,
    );
    setFilteredTypeActivity(result);
  };

  const classes = useStyles();

  return (
    <>
      <CardContainer>
        <div className={classes.root}>
          <div className="card-header">
            <FormControl className="w-100 m-2">
              <InputLabel htmlFor="input-with-icon-adornment">
                {t('word.search')}
              </InputLabel>
              <Input
                onChange={(event) => handleSearch(event)}
                style={{maxWidth: '500px'}}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </FormControl>
            <div className="card-header--actions">
              <Link to="/typeactivity/new">
                <Fab size="small" color="secondary">
                  <span className="btn-wrapper--icon">
                    <FaPlus />
                  </span>
                </Fab>
              </Link>
            </div>
          </div>
          <TableContainer className="mb-4" component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{t('word.type-activity')}</TableCell>
                  <TableCell align="right">{t('word.status')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredTypeActivity &&
                  filteredTypeActivity.map((i) => (
                    <TableRow
                      key={i.idtypeactivity}
                      className={classes.tablerow}
                      onClick={() =>
                        history.push(`typeactivity/edit/${i.idtypeactivity}`)
                      }>
                      <TableCell component="th" scope="row">
                        {i.typeactivity}
                      </TableCell>
                      <TableCell align="right">
                        {i.active ? t('word.active') : t('word.inactive')}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </CardContainer>
    </>
  );
}
