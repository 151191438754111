/* eslint-disable no-restricted-syntax */
import React, {Fragment, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
} from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import Enum from 'helpers/enum';
import CardContainer from '../../CardContainer';
import {getAllScheduleCustomer} from '../../../helpers/api';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tablerow: {
    // cursor: 'pointer',
    transition: 'all ease 0.2s',
    '&:hover': {
      backgroundColor: '#CCC',
    },
  },
}));

export default function RegularTables1(props) {
  const classes = useStyles();
  const {t} = useTranslation();
  const history = useHistory();
  const [result, setResult] = useState([]);

  const getAll = async () => {
    const res = await getAllScheduleCustomer(props.id);
    if (res && !res.errors) {
      setResult(res);
    } else if (res.errors) {
      for (const i of res.errors) {
        props.openAlert(
          t(`message.${i.code}`, {param: t(`word.${i.field}`)}),
          i.color,
        );
      }
      history.replace('/customer');
    }
  };

  useEffect(() => {
    getAll();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <CardContainer>
        <div className={classes.root}>
          <TableContainer className="mb-4" component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{t('word.activity')}</TableCell>
                  <TableCell align="left">{t('word.date')}</TableCell>
                  <TableCell align="left">{t('word.participant')}</TableCell>
                  <TableCell align="left">{t('word.professional')}</TableCell>
                  <TableCell align="left">{t('word.status')}</TableCell>
                  {Enum.arrPms.includes(props.pms) && (
                    <>
                      <TableCell align="left">
                        {t('word.integration-status')}
                      </TableCell>
                      <TableCell align="left">
                        {t('word.type-integration')}
                      </TableCell>
                    </>
                  )}
                  <TableCell align="right">{t('word.value')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {result &&
                  result.map((i) => (
                    <TableRow className={classes.tablerow} key={i.idschedule}>
                      <TableCell component="th" scope="row">
                        {i['activity.activity']}
                      </TableCell>
                      <TableCell align="left">
                        {`${moment(i.date).format(t('format.date'))}, ${moment(
                          new Date(`${i.date} ${i.starttime}`),
                        ).format('LT')} - ${moment(
                          `${i.date} ${i.endtime}`,
                        ).format('LT')}`}
                      </TableCell>
                      <TableCell align="left">{i['person.name']}</TableCell>
                      <TableCell align="left">
                        {i['professional.user.name']}
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip
                          arrow
                          title={t(`word.schedule-status.${i.status}`)}>
                          <span
                            className={`badge-circle mt-2 mr-2 align-self-start badge badge-${t(
                              `word.schedule-status-color.${i.status}`,
                            )}`}>
                            .
                          </span>
                        </Tooltip>
                      </TableCell>
                      {Enum.arrPms.includes(props.pms) && (
                        <>
                          <TableCell align="center">
                            <>
                              {(i.status === Enum.schedule_status.finished ||
                                i.status === Enum.schedule_status.canceled) && (
                                <>
                                  <Tooltip
                                    arrow
                                    title={t(`word.schedule-pms.${i.pms}`)}>
                                    <span
                                      className={`badge-circle mt-2 mr-2 align-self-start badge badge-${t(
                                        `word.schedule-pms-color.${i.pms}`,
                                      )}`}>
                                      .
                                    </span>
                                  </Tooltip>
                                </>
                              )}
                            </>
                          </TableCell>
                          <TableCell align="left">
                            {i.integratedpms === true &&
                              i.integrationpms != null &&
                              t(
                                `word.statusintegrationpms.${i.integrationpms}`,
                              )}
                          </TableCell>
                        </>
                      )}
                      <TableCell align="right">
                        {i.integrationpms ===
                          Enum.schedule_integrationpms.finishedwithvalue ||
                        (i.integratedpms === false &&
                          i.status === Enum.schedule_status.finished)
                          ? props.companyConfigs.currencysymbol + i.price
                          : props.companyConfigs.currencysymbol +
                            i.penaltyvalue}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </CardContainer>
    </>
  );
}
