/* eslint-disable no-restricted-syntax */
import React, {Fragment, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {
  Grid,
  Button,
  Card,
  TextField,
  Switch,
  FormControlLabel,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ReplayIcon from '@material-ui/icons/Replay';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Page} from 'store/reducers/Page/pageAction';
import Delete from '../../ButtonDelete/index';
import {
  getTypeActivity,
  editTypeActivity,
  deleteTypeActivity,
  addTypeActivity,
} from '../../../helpers/api';
import Error from '../../Error';

export default function Formulario(props) {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const history = useHistory();

  const [typeactivity, setTypeactivity] = useState('');
  const [status, setStatus] = useState(true);
  const [result, setResult] = useState({});
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    dispatch(Page(t('word.type-activity')));
    if (props.id) {
      const getOne = async (id) => {
        const res = await getTypeActivity(id);
        if (res.errors) {
          for (const i of res.errors) {
            props.openAlert(
              t(`message.${i.code}`, {param: t(`word.${i.field}`)}),
              i.color,
            );
          }
          history.replace('/typeactivity');
        } else {
          setTypeactivity(res.typeactivity);
          setStatus(res.active);
        }
      };
      getOne(props.id);
    }
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisable(true);

    let res;

    if (props.id) {
      res = await editTypeActivity(props.id, typeactivity, status);
      setResult(res);
    } else {
      res = await addTypeActivity(typeactivity);
      setResult(res);
    }

    if (res && !res.errors) {
      if (props.id) {
        props.openAlert(
          t('message.edit-record', {param: t('word.type-activity')}),
          'success',
        );
      } else {
        props.openAlert(
          t('message.insert-record', {param: t('word.type-activity')}),
          'success',
        );
      }
      history.replace('/typeactivity');
    } else {
      setDisable(false);
    }
  };

  const delTypeactivity = async (id) => {
    const res = await deleteTypeActivity(id);
    setResult(res);
    if (res && !res.errors) {
      props.openAlert(
        t('message.delete-record', {param: t('word.type-activity')}),
        'success',
      );
      history.replace('/typeactivity');
    }
  };

  return (
    <>
      <Card className="p-4 mb-4">
        {result.errors && <Error result={result} />}
        <form onSubmit={handleSubmit}>
          <Grid container direction="row" spacing={12}>
            <Grid xs={12} sm={12}>
              {props.id && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={status}
                      onChange={() => setStatus(!status)}
                      value={status}
                      disabled={disable}
                      color="secondary"
                      fullWidth
                    />
                  }
                  label={status ? t('word.active') : t('word.inactive')}
                />
              )}
            </Grid>
            <Grid container xs={12} sm={6}>
              <TextField
                className="m-2"
                label={t('word.type-activity')}
                id="standard-size-small"
                size="small"
                required
                disabled={disable}
                value={typeactivity}
                onChange={(e) => setTypeactivity(e.target.value)}
                fullWidth
                inputProps={{maxLength: 256}}
              />
            </Grid>
          </Grid>
          <div style={{float: 'right'}}>
            <Button
              style={{textTransform: 'none'}}
              onClick={() => history.replace('/typeactivity')}
              disabled={disable}
              className="m-2"
              variant="contained"
              color="secondary">
              <ReplayIcon />
              {t('word.cancel')}
            </Button>
            <Delete func={delTypeactivity} id={props.id} disabled={disable} />
            <Button
              style={{textTransform: 'none'}}
              disabled={disable}
              type="submit"
              className="m-2"
              color="secondary"
              variant="contained">
              <CheckIcon />
              {t('word.save')}
            </Button>
          </div>
        </form>
      </Card>
    </>
  );
}
