/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
import React, {Fragment, useEffect, useState, useRef} from 'react';
import {useHistory} from 'react-router-dom';
import {
  addActivity,
  editActivity,
  deleteActivity,
  getActivity,
  getTypeActivities,
  getPlaceOfSale,
} from 'helpers/api';
import {
  Grid,
  Button,
  Card,
  TextField,
  FormControlLabel,
  Switch,
  Checkbox,
  MenuItem,
  IconButton,
  AppBar,
  Box,
  Typography,
  Tabs,
  Tab,
} from '@material-ui/core';

import {makeStyles} from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import ReplayIcon from '@material-ui/icons/Replay';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {base64toBlob} from 'utils/base64ToBlob';
import CropIcon from '@material-ui/icons/Crop';
import Enum from 'helpers/enum';
import VisibilityIcon from '@material-ui/icons/Visibility';
import {FaQrcode} from 'react-icons/fa';
import {Page} from 'store/reducers/Page/pageAction';
import ModalViewActivity from '../../modals/ModalActivity';
import ModalEdit from '../../modals/ModalEditImage';
import Professional from '../Professional';
import Period from '../Period';
import Delete from '../../ButtonDelete/index';
import Error from '../../Error';
import ModalQrCode from '../../modals/ModalQrCode';

const useStyles = makeStyles(() => ({
  error: {
    margin: '10px 0px',
    backgroundColor: '#F2DEDE',
    color: '#A94442',
    border: '1px solid #EBCCD1',
    padding: '10px',
  },
  root: {
    flexGrow: 1,
    backgroundColor: '#FFF',
  },
}));
function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const FormatImage = (props) => {
  if (props.photo.photo) {
    return (
      <img
        style={{height: '100px', width: '150px'}}
        className="img-fluid"
        src={props.photo.photo}
        alt="imagem"
      />
    );
  }
  return (
    <img
      style={{height: '100px', width: '150px'}}
      className="img-fluid"
      src={`data:${props.type};base64,${props.photo}`}
      alt="imagem"
    />
  );
};

export default function Formulario(props) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const dispatch = useDispatch();
  const {t} = useTranslation();

  const status = [
    {
      value: false,
      label: t('word.no-integrate'),
    },
    {
      value: true,
      label: t('word.integrate'),
    },
  ];

  const history = useHistory();
  const fileField = useRef();
  const {id, idcompany} = props;
  const classes = useStyles();
  const [activity, setActivity] = useState('');
  const [active, setActive] = useState(true);
  const [duration, setDuration] = useState();
  const [simultaneous, setSimultaneous] = useState();
  const [description, setDescription] = useState('');
  const [rules, setRules] = useState('');
  const [idtypeadctivity, setIdtypeadctivity] = useState('');
  const [idplaceofsale, setIdPlaceOfSale] = useState('');
  const [typeactivity, setTypeadctivity] = useState([]);
  const [placeOfSale, setPlaceOfSale] = useState([]);
  const [deleteImage, setDeleteimage] = useState(false);
  const [disableDuration, setDisableDuration] = useState(false);
  const [photo, setPhoto] = useState('');
  const [result, setResult] = useState({});
  const [disable, setDisable] = useState(false);
  const [display, setDisplay] = useState('none');
  const [place, setPlace] = useState('');
  const [price, setPrice] = useState();
  const [editImage, setEditImage] = useState(false);
  const [integratedpms, setIntegratedPms] = useState(
    !!Enum.arrPms.includes(props.pms),
  );
  const [idactivitypms, setIdactivitypms] = useState();
  const [OpenCrop, setOpenCrop] = useState(false);
  const [modalQrCode, setModalQrCode] = useState(false);
  const [mimetype, setMimetype] = useState('');
  const [act, setAct] = useState({});
  const [canceltimeout, setCanceltimeout] = useState(0);
  const [cancelpolicy, setCancelpolicy] = useState(0);
  const [cancelvalue, setCancelvalue] = useState(0);
  const [noshowpolicy, setNoshowpolicy] = useState(0);
  const [noshowvalue, setNoshowvalue] = useState(0);
  const [modalViewActivity, setModalViewActivity] = useState(false);

  const formatCrop = {
    w: 4,
    h: 3,
    shape: 'rect',
  };

  useEffect(() => {
    dispatch(Page(t('word.activity')));
    if (id) {
      props.setOp(true);
      const getOne = async (idActivity) => {
        const res = await getActivity(idActivity);
        if (res.errors) {
          for (const i of res.errors) {
            props.openAlert(
              t(`message.${i.code}`, {param: t(`word.${i.field}`)}),
              i.color,
            );
          }
          history.replace('/activity');
        } else {
          setDisplay('inline');
          setActivity(res.activity);
          setActive(res.active);
          setDuration(res.duration);
          setSimultaneous(res.simultaneous);
          setDescription(res.description);
          setRules(res.rules);
          setIdtypeadctivity(res.idtypeactivity);
          setPlace(res.place);
          setIdPlaceOfSale(res.idplaceofsale);
          setDisableDuration(res.editDuration);
          setIntegratedPms(res.integratedpms);
          setPrice(res.price);
          setIdactivitypms(res.idactivitypms);
          setCanceltimeout(res.canceltimeout);
          setCancelpolicy(res.cancelpolicy);
          setCancelvalue(res.cancelvalue);
          setNoshowpolicy(res.noshowpolicy);
          setNoshowvalue(res.noshowvalue);
          setMimetype(res.mimetype);
          setPhoto('');
          if (res.photo) {
            setPhoto({
              photo: URL.createObjectURL(
                base64toBlob(
                  res.photo,
                  res.mimetype ? res.mimetype : 'image/jpeg',
                ),
              ),
            });
          }
          setAct(res);
        }
        props.setOp(false);
      };
      getOne(id);
    }

    const Types = async () => {
      const res = await getTypeActivities();
      if (res && !res.errors) {
        setTypeadctivity(res);
      } else if (res.errors) {
        for (const i of res.errors) {
          props.openAlert(
            t(`message.${i.code}`, {param: t(`word.${i.field}`)}),
            i.color,
          );
        }
        history.replace('/activity');
      }
    };

    const Place = async () => {
      const res = await getPlaceOfSale();
      if (res && !res.errors) {
        setPlaceOfSale(res);
      } else if (res.errors) {
        for (const i of res.errors) {
          props.openAlert(
            t(`message.${i.code}`, {param: t(`word.${i.field}`)}),
            i.color,
          );
        }
        history.replace('/activity');
      }
    };

    Place();
    Types();
    // eslint-disable-next-line
  }, []);

  const handleView = async () => {
    setAct({
      activity,
      description,
      rules,
      photo,
      mimetype,
      price,
      duration,
      place,
    });
    setModalViewActivity(true);
  };

  const SubmitAct = async (e) => {
    e.preventDefault();
    props.setOp(true);
    setDisable(true);

    let res;

    const fData = new FormData();

    fData.append('activity', activity);
    fData.append('active', active);
    fData.append('duration', duration);
    fData.append('simultaneous', simultaneous);
    fData.append('description', description);
    fData.append('rules', rules);
    fData.append('deleteImage', deleteImage);
    fData.append('idtypeactivity', idtypeadctivity);
    fData.append('place', place);
    fData.append('idplaceofsale', idplaceofsale);
    fData.append('price', price !== '' ? price : 0);
    fData.append('idactivitypms', idactivitypms);
    fData.append('editImage', editImage);
    fData.append('photo', photo);
    fData.append('integratedpms', integratedpms);
    fData.append('canceltimeout', canceltimeout);
    fData.append('cancelpolicy', cancelpolicy);
    fData.append('cancelvalue', cancelvalue);
    fData.append('noshowpolicy', noshowpolicy);
    fData.append('noshowvalue', noshowvalue);

    if (props.id) {
      res = await editActivity(props.id, fData);
      setResult(res);
    } else {
      res = await addActivity(fData);
      setResult(res);
    }
    props.setOp(false);
    if (res && !res.errors) {
      if (props.id) {
        props.openAlert(
          t('message.edit-record-f', {param: t('word.activity')}),
          'success',
        );
        history.replace('/activity');
      } else {
        props.openAlert(
          t('message.insert-record-f', {param: t('word.activity')}),
          'success',
        );
        history.replace(`edit/${res}`);
      }
    } else {
      setDisable(false);
    }
  };

  const delActivity = async (idActivity) => {
    props.setOp(true);
    const res = await deleteActivity(idActivity);
    setResult(res);
    props.setOp(false);
    if (res && !res.errors) {
      props.openAlert(
        t('message.delete-record-f', {param: t('word.activity')}),
        'success',
      );
      history.replace('/activity');
    }
  };

  const HandlePhoto = (e) => {
    const arr = e.target.files[0];
    setEditImage(true);
    arr.photo = URL.createObjectURL(e.target.files[0]);
    setPhoto(arr);
  };

  return (
    <>
      <Card className="p-4 mb-4">
        {result.errors && <Error result={result} />}
        {OpenCrop && (
          <ModalEdit
            formatCrop={formatCrop}
            setEditImage={setEditImage}
            setOpenCrop={setOpenCrop}
            OpenCrop={OpenCrop}
            photoURL={photo.photo}
            setPhotoURL={setPhoto}
          />
        )}
        {modalViewActivity && (
          <ModalViewActivity
            prim={props.primary}
            modalView={modalViewActivity}
            setModalView={setModalViewActivity}
            data={act}
          />
        )}
        {modalQrCode && id && idcompany && (
          <ModalQrCode
            text={`${process.env.REACT_APP_URL_BASE}${idcompany}/schedule/activity/${id}`}
            nameToDownload={`QR_Code_activity_${id}`}
            modalView={modalQrCode}
            setModalView={setModalQrCode}
          />
        )}

        <form onSubmit={SubmitAct}>
          <Grid container direction="row" spacing={12}>
            <Grid xs={12} sm={12}>
              {props.id && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={active}
                      onChange={() => setActive(!active)}
                      value={active}
                      disabled={disable}
                      color="secondary"
                      fullWidth
                    />
                  }
                  label={active ? t('word.active') : t('word.inactive')}
                />
              )}
            </Grid>
            <Grid container xs={12} sm={8}>
              <TextField
                className="m-2"
                label={t('word.activity')}
                id="standard-size-small"
                inputVariant="outlined"
                size="small"
                required
                disabled={disable}
                value={activity}
                onChange={(e) => setActivity(e.target.value)}
                fullWidth
                inputProps={{maxLength: 50}}
              />
            </Grid>
            <Grid container xs={12} sm={4}>
              <TextField
                className="m-2"
                label={`${t('word.duration')} (${t('word.minutes')})`}
                id="standard-size-small"
                size="small"
                required
                disabled={disableDuration}
                value={parseInt(duration)}
                onChange={(e) => setDuration(e.target.value)}
                inputProps={{min: 1}}
                type="number"
                fullWidth
              />
            </Grid>
            <Grid container xs={12} sm={8}>
              <TextField
                className="m-2"
                label={t('word.place')}
                id="standard-size-small"
                size="small"
                required
                disabled={disable}
                value={place}
                onChange={(e) => setPlace(e.target.value)}
                fullWidth
                inputProps={{maxLength: 50}}
              />
            </Grid>
            <Grid container xs={12} sm={4}>
              <TextField
                className="m-2"
                label={t('word.simultaneous')}
                id="standard-size-small"
                size="small"
                required
                disabled={disable}
                value={parseInt(simultaneous)}
                onChange={(e) => setSimultaneous(e.target.value)}
                type="number"
                inputProps={{min: 0}}
                fullWidth
              />
            </Grid>
            <Grid container xs={12} sm={6}>
              <TextField
                className="m-2"
                id="profile"
                select
                label={t('word.typeactivity')}
                required
                disabled={disable}
                value={idtypeadctivity}
                onChange={(e) => setIdtypeadctivity(e.target.value)}
                fullWidth>
                {typeactivity.map((a) => (
                  <MenuItem key={a.idtypeactivity} value={a.idtypeactivity}>
                    {a.typeactivity}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid container xs={12} sm={6}>
              <TextField
                className="m-2"
                id="profile"
                select
                label={t('word.placeofsale')}
                required
                disabled={disable}
                value={idplaceofsale}
                onChange={(e) => setIdPlaceOfSale(e.target.value)}
                fullWidth>
                {placeOfSale.map((a) => (
                  <MenuItem key={a.idplaceofsale} value={a.idplaceofsale}>
                    {a.placeofsale}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid container xs={12} sm={4}>
              <TextField
                className="m-2"
                label={`${t('word.price')} ${
                  props.companyConfigs.currencysymbol
                }`}
                id="standard-size-small"
                size="small"
                required
                disabled={disable}
                value={parseFloat(price)}
                onChange={(e) => setPrice(e.target.value)}
                inputProps={{min: 0}}
                type="number"
                fullWidth
              />
            </Grid>
            {Enum.arrPms.includes(props.pms) && (
              <>
                <Grid container xs={12} sm={4}>
                  <TextField
                    className="m-2"
                    id="standard-size-small"
                    size="small"
                    select
                    label={t('word.integratedpms')}
                    required
                    disabled={disable}
                    value={integratedpms}
                    onChange={(e) => setIntegratedPms(e.target.value)}
                    fullWidth>
                    {status.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid container xs={12} sm={4}>
                  <TextField
                    className="m-2"
                    label={t('word.idactivitypms')}
                    id="standard-size-small"
                    size="small"
                    required
                    disabled={disable || integratedpms === false}
                    value={parseInt(idactivitypms)}
                    onChange={(e) => setIdactivitypms(e.target.value)}
                    inputProps={{min: 0}}
                    type="number"
                    fullWidth
                  />
                </Grid>
              </>
            )}
            <Grid container xs={12} sm={12}>
              <TextField
                className="m-2"
                label={t('word.description')}
                multiline
                rowsMax={4}
                disabled={disable}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                inputProps={{maxLength: 500}}
                fullWidth
                rows={5}
              />
            </Grid>
            <Grid container xs={12} sm={12}>
              <TextField
                className="m-2"
                label={t('word.rules')}
                multiline
                rowsMax={4}
                disabled={disable}
                value={rules}
                onChange={(e) => setRules(e.target.value)}
                inputProps={{maxLength: 1000}}
                fullWidth
                rows={5}
              />
            </Grid>
          </Grid>
          <div className="heading-3 my-2">{t('word.policy-cancel')}</div>
          <Grid container direction="row" spacing={12}>
            <Grid container xs={12} sm={4}>
              <TextField
                className="m-2"
                id="profile"
                select
                size="small"
                label={t('word.cancelpolicy')}
                required
                disabled={disable}
                value={parseInt(cancelpolicy)}
                onChange={(e) => setCancelpolicy(e.target.value)}
                fullWidth>
                {Enum.arrCancellation.map((a) => (
                  <MenuItem key={a.value} value={a.value}>
                    {t(`${a.label}`)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid container xs={12} sm={4}>
              <TextField
                className="m-2"
                label={`${t('word.valuecancel')} ${
                  cancelpolicy === 1
                    ? ' % '
                    : cancelpolicy === 2
                    ? props.companyConfigs.currencysymbol
                    : ''
                }`}
                id="standard-size-small"
                size="small"
                required
                disabled={
                  disable || Enum.arrDisableCancellation.includes(cancelpolicy)
                }
                value={parseInt(cancelvalue)}
                onChange={(e) => setCancelvalue(e.target.value)}
                type="number"
                inputProps={{min: 0}}
                fullWidth
              />
            </Grid>
            <Grid container xs={12} sm={4}>
              <TextField
                className="m-2"
                label={`${t('word.canceltimeout')} (${t('word.Hours')})`}
                id="standard-size-small"
                size="small"
                required
                disabled={
                  disable || Enum.arrDisableCancellation.includes(cancelpolicy)
                }
                value={parseInt(canceltimeout)}
                onChange={(e) => setCanceltimeout(e.target.value)}
                type="number"
                inputProps={{min: 0}}
                fullWidth
              />
            </Grid>
            <Grid container xs={12} sm={4}>
              <TextField
                className="m-2"
                label={t('word.noshowpolicy')}
                id="standard-size-small"
                size="small"
                required
                select
                disabled={disable}
                value={parseInt(noshowpolicy)}
                onChange={(e) => setNoshowpolicy(e.target.value)}
                fullWidth>
                {Enum.arrCancellation.map((a) => (
                  <MenuItem key={a.value} value={a.value}>
                    {t(`${a.label}`)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid container xs={12} sm={4}>
              <TextField
                className="m-2"
                label={`${t('word.valuecancel')} ${
                  noshowpolicy === 1
                    ? ' % '
                    : noshowpolicy === 2
                    ? props.companyConfigs.currencysymbol
                    : ''
                }`}
                id="standard-size-small"
                size="small"
                required
                disabled={
                  disable || Enum.arrDisableCancellation.includes(noshowpolicy)
                }
                value={parseInt(noshowvalue)}
                onChange={(e) => setNoshowvalue(e.target.value)}
                type="number"
                inputProps={{min: 0}}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container direction="column">
            <Grid container direction="row">
              {photo && (
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-block p-0 avatar-icon-wrapper rounded m-0 border-3 border-first">
                    <div className="rounded border-3 border-white overflow-hidden">
                      <FormatImage photo={photo} />
                    </div>
                  </div>
                </div>
              )}
              {props.id && photo && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={deleteImage}
                      onChange={() => setDeleteimage(!deleteImage)}
                    />
                  }
                  label={t('message.delete-image')}
                />
              )}
            </Grid>
            <input
              accept="image/*"
              style={{display: 'none'}}
              id="raised-button-file"
              type="file"
              ref={fileField}
              disabled={disable || deleteImage}
              onChange={(e) => HandlePhoto(e)}
            />
            <label htmlFor="raised-button-file">
              {!photo.photo && (
                <Button
                  variant="outlined"
                  component="span"
                  disabled={disable || deleteImage}>
                  {t('word.add-image')}
                </Button>
              )}
              {photo.photo && (
                <IconButton>
                  <CropIcon
                    color="primary"
                    arial-label="Crop"
                    onClick={() => setOpenCrop(true)}
                  />
                </IconButton>
              )}
            </label>
          </Grid>
          <div style={{float: 'left'}}>
            <Button
              onClick={() => handleView()}
              style={{textTransform: 'none'}}
              disabled={disable}
              className="m-2"
              variant="contained"
              color="secondary">
              <VisibilityIcon />
              <span style={{paddingLeft: '2px'}}>{t('word.Preview')}</span>
            </Button>
            {id && idcompany && (
              <Button
                onClick={() => setModalQrCode(true)}
                style={{textTransform: 'none'}}
                disabled={disable}
                className="m-2"
                variant="contained"
                color="secondary">
                <FaQrcode className="font-size-xxl" />
                <span style={{paddingLeft: '4px'}}>{t('word.QrCode')}</span>
              </Button>
            )}
          </div>
          <div style={{float: 'right'}}>
            <Button
              style={{textTransform: 'none'}}
              disabled={disable}
              onClick={() => history.replace('/activity')}
              className="m-2"
              variant="contained"
              color="secondary">
              <ReplayIcon />
              {t('word.cancel')}
            </Button>
            <Delete func={delActivity} id={props.id} disabled={disable} />
            <Button
              style={{textTransform: 'none'}}
              disabled={disable}
              onClick={SubmitAct}
              className="m-2"
              color="secondary"
              variant="contained">
              <CheckIcon />
              {t('word.save')}
            </Button>
          </div>
        </form>
      </Card>

      <div className={classes.root} style={{display: `${display}`}}>
        <AppBar
          position="static"
          style={{borderRadius: '5px 5px 0px 0px', backgroundColor: '#FFF'}}>
          <Tabs
            textColor="secondary"
            value={value}
            onChange={handleChange}
            centered>
            <Tab label={t('word.periods')} />
            <Tab label={t('word.professionals')} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0} style={{backgroundColor: '#FFF'}}>
          <Period openAlert={props.openAlert} id={props.id} />
        </TabPanel>
        <TabPanel value={value} index={1} style={{backgroundColor: '#FFF'}}>
          <Professional
            openAlert={props.openAlert}
            id={props.id}
            idactivity={id}
          />
        </TabPanel>
      </div>
    </>
  );
}
