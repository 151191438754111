/* eslint-disable no-restricted-syntax */
import React, {Fragment, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {FaPlus} from 'react-icons/fa';
import {makeStyles} from '@material-ui/core/styles';
import {
  Fab,
  Button,
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import ModalProfessional from 'components/modals/Professionals';
import ReplayIcon from '@material-ui/icons/Replay';
import {useTranslation} from 'react-i18next';
import CardContainer from '../../CardContainer';
import {getProfessionalActivity} from '../../../helpers/api';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tablerow: {
    cursor: 'pointer',
    transition: 'all ease 0.2s',
    '&:hover': {
      backgroundColor: '#CCC',
    },
  },
}));

export default function Professional(props) {
  const history = useHistory();
  const {t} = useTranslation();
  const classes = useStyles();
  const [professionals, setProfessionals] = useState([]);
  const [fprofessionals, setfProfessionals] = useState([]);
  const [edit, setEdit] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [id, setId] = useState();
  const [observer, setObserver] = useState(true);

  useEffect(() => {
    if (modalOpen === false) {
      const getAll = async () => {
        const res = await getProfessionalActivity(props.id);
        if (res && !res.errors) {
          setProfessionals(res);
          setfProfessionals(res);
        } else if (res.errors) {
          for (const i of res.errors) {
            props.openAlert(
              t(`message.${i.code}`, {param: t(`word.${i.field}`)}),
              i.color,
            );
          }
          history.replace('/activity');
        }
      };
      getAll();
    }
    // eslint-disable-next-line
  }, [modalOpen]);

  const handleEdit = (idEdit) => {
    setId(id);
    setEdit(idEdit);
    setModalOpen(true);
  };

  const handleAdd = () => {
    setId(null);
    setModalOpen(true);
  };

  return (
    <>
      <CardContainer>
        <ModalProfessional
          openAlert={props.openAlert}
          setEdit={setEdit}
          edit={edit}
          setObserver={setObserver}
          observer={observer}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          id={id}
          idactivity={props.idactivity}
        />
        <div className="card-header">
          <div className="card-header--actions" style={{flex: '1'}}>
            <Fab
              size="small"
              color="secondary"
              style={{float: 'right'}}
              onClick={() => handleAdd()}>
              <span className="btn-wrapper--icon">
                <FaPlus />
              </span>
            </Fab>
          </div>
        </div>
        <div>
          <TableContainer className="mb-4" component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{t('word.name')}</TableCell>
                  <TableCell align="left">{t('word.occupation')}</TableCell>
                  <TableCell align="left">{t('word.status')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {professionals &&
                  fprofessionals &&
                  fprofessionals.map((i) => (
                    <TableRow
                      key={i.idprofessional}
                      className={classes.tablerow}
                      onClick={() => handleEdit(i.idprofessional)}>
                      <TableCell component="th" scope="row">
                        {i['user.name']}
                      </TableCell>
                      <TableCell align="left">
                        {i['user.occupation.occupation']}
                      </TableCell>
                      <TableCell align="left">
                        {i['user.active']
                          ? t('word.active')
                          : t('word.inactive')}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <div style={{float: 'right'}}>
              <Button
                style={{textTransform: 'none'}}
                onClick={() => history.replace('/activity')}
                className="m-2"
                variant="contained"
                color="secondary">
                <ReplayIcon />
                {t('word.back')}
              </Button>
            </div>
          </TableContainer>
        </div>
      </CardContainer>
    </>
  );
}
