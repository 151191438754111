/* eslint-disable no-restricted-syntax */
/* eslint-disable react/prop-types */
import React, {Fragment, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import {
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import CardContainer from '../../CardContainer';
import {getPersonCustomer} from '../../../helpers/api';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tablerow: {
    // cursor: 'pointer',
    transition: 'all ease 0.2s',
    '&:hover': {
      backgroundColor: '#CCC',
    },
  },
}));

export default function Periods(props) {
  const history = useHistory();
  const {t} = useTranslation();
  const classes = useStyles();
  const [result, setResult] = useState([]);

  useEffect(() => {
    const getAll = async () => {
      const res = await getPersonCustomer(props.id);
      if (res.errors) {
        for (const i of res.errors) {
          props.openAlert(
            t(`message.${i.code}`, {param: t(`word.${i.field}`)}),
            i.color,
          );
        }
        history.replace('/customer');
      } else {
        setResult(res);
      }
    };
    getAll();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <CardContainer>
        <div className={classes.root}>
          {/* <div className="card-header">
            <div className="card-header--actions" style={{flex: "1"}}>
              <Fab size="small" color="secondary" style={{float: "right"}} onClick={() => handleAdd()}>
                <span className="btn-wrapper--icon">
                  <FontAwesomeIcon icon={['fas', 'plus']} />
                </span>
              </Fab>
            </div>
          </div> */}
          <TableContainer className="mb-4" component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{t('word.name')}</TableCell>
                  <TableCell>{t('word.email')}</TableCell>
                  <TableCell>{t(`word.main`)}</TableCell>
                  <TableCell>{t(`word.status`)}</TableCell>
                  <TableCell>{t(`word.created`)}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {result &&
                  result.map((i) => (
                    <TableRow key={i.idperson} className={classes.tablerow}>
                      <TableCell component="th" scope="row">
                        {i.name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {i.email}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {i.main ? t('word.yes') : t('word.no')}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Tooltip
                          arrow
                          title={
                            i.active ? t('word.active') : t('word.inactive')
                          }>
                          <span
                            className={`badge-circle mt-2 mr-2 align-self-start badge badge-${t(
                              `word.active-color.${i.active ? 1 : 0}`,
                            )}`}>
                            .
                          </span>
                        </Tooltip>
                        {i.active ? t('word.active') : t('word.inactive')}
                      </TableCell>
                      <TableCell>
                        {i.created
                          ? `${moment(i.created).format(
                              t('format.date'),
                            )}, ${moment(i.created).format('LT')}`
                          : ''}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </CardContainer>
    </>
  );
}
