import React, {Fragment} from 'react';

import {Link, NavLink as RouterLink} from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Hidden,
  Drawer,
  IconButton,
  AppBar,
  Paper,
  Box,
  Button,
  Tooltip,
} from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {setSidebarToggleMobile as setSidebarToggleMobileAction} from 'store/reducers/ThemeOptions';

import SidebarHeader from '../SidebarHeader';
import SidebarUserbox from '../SidebarUserbox';
import SidebarMenu from '../SidebarMenu';
import navItems from '../Sidebar/navItems';

const SidebarCollapsed = (props) => {
  const {t} = useTranslation();
  const {setSidebarToggleMobile, sidebarToggleMobile} = props;

  const closeDrawer = () => setSidebarToggleMobile(!sidebarToggleMobile);

  const sidebarMenuContent = (
    <div>
      {navItems.map((list) => (
        <SidebarMenu
          pms={props.pms}
          configs={props.configs}
          component="div"
          key={list.label}
          pages={list.content}
          title={t(list.label)}
        />
      ))}
    </div>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          open={sidebarToggleMobile}
          onClose={closeDrawer}
          variant="temporary"
          elevation={4}
          className="app-sidebar-wrapper-lg">
          <SidebarHeader />
          <PerfectScrollbar>
            <SidebarUserbox companyConfigs={props.companyConfigs} />
            {sidebarMenuContent}
          </PerfectScrollbar>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Paper elevation={7} square className="app-sidebar-collapsed-wrapper">
          <AppBar color="secondary" position="relative" elevation={0}>
            <div className="sidebar-collapsed-logo">
              <Box className="header-logo-wrapper" title={t('word.app-name')}>
                <Link to="/home" className="header-logo-wrapper-link">
                  <IconButton
                    color="primary"
                    style={{backgroundColor: 'white'}}
                    size="medium"
                    className="header-logo-wrapper-btn">
                    <img
                      className="app-sidebar-logo"
                      alt={t('word.app-name')}
                      src="fav.ico"
                    />
                  </IconButton>
                </Link>
              </Box>
            </div>
          </AppBar>
          <div className="app-sidebar--content">
            <PerfectScrollbar>
              {/* <ul className="sidebar-menu-collapsed">
                <li>
                  <Tooltip arrow placement="right" title="Dashboard">
                    <Button
                      className="app-sidebar-btn-wrapper"
                      activeClassName="active"
                      component={RouterLink}
                      to="/home">
                      <span>
                        <Settings className="app-sidebar-btn-icon" />
                      </span>
                    </Button>
                  </Tooltip>
                </li>
              </ul> */}
              <div className="text-center pt-4 mb-2">
                <Tooltip
                  arrow
                  placement="right"
                  title={t('word.menu-bar.home')}>
                  <Button
                    variant="contained"
                    color="secondary"
                    className="text-white text-center font-size-lg d-40"
                    component={RouterLink}
                    to="/home">
                    {/* <FontAwesomeIcon icon={['fas', 'arrow-left']} /> */}
                    <HomeIcon />
                  </Button>
                </Tooltip>
              </div>
            </PerfectScrollbar>
          </div>
        </Paper>
      </Hidden>
    </>
  );
};

const mapStateToProps = (state) => ({
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) =>
    dispatch(setSidebarToggleMobileAction(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarCollapsed);
