/* eslint-disable no-restricted-syntax */
import React, {Fragment, useEffect, useState, useRef} from 'react';
import {useHistory} from 'react-router-dom';
import {
  addUser,
  editUser,
  getUser,
  deleteUser,
  getOccupations,
  getProfiles,
} from 'helpers/api';
import {
  Grid,
  Button,
  Card,
  TextField,
  Switch,
  Checkbox,
  FormControlLabel,
  MenuItem,
  IconButton,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ReplayIcon from '@material-ui/icons/Replay';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import CropIcon from '@material-ui/icons/Crop';
import {base64toBlob} from 'utils/base64ToBlob';
import {Page} from 'store/reducers/Page/pageAction';
import Delete from '../../ButtonDelete/index';
import Error from '../../Error';
import ModalEdit from '../../modals/ModalEditImage';
import Enum from '../../../helpers/enum';

const languages = [
  {
    value: 'pt-BR',
    label: 'Português',
  },
  {
    value: 'es-ES',
    label: 'Espanhol',
  },
  {
    value: 'en-US',
    label: 'Inglês',
  },
];

const FormatImage = (props) => {
  if (props.photo.photo) {
    return (
      <img
        style={{height: '80px', width: '80px'}}
        className="img-fluid"
        src={props.photo.photo}
        alt="imagem"
      />
    );
  }
  return (
    <img
      style={{height: '80px', width: '100px'}}
      className="img-fluid"
      src={`${props.photo}`}
      alt="imagem"
    />
  );
};

export default function Formulario(props) {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const history = useHistory();
  const fileField = useRef();

  const {setOp} = props;

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [language, setLanguage] = useState('pt-BR');
  const [status, setStatus] = useState(true);
  const [occupations, setOccupations] = useState([]);
  const [occupation, setOccupation] = useState('');
  const [profiles, setProfiles] = useState([]);
  const [profile, setProfile] = useState('');
  const [iduserpms, setIdUserPms] = useState();
  const [result, setResult] = useState({});
  const [disable, setDisable] = useState(false);
  const [photo, setPhoto] = useState('');
  const [deleteImage, setDeleteimage] = useState(false);
  const [editImage, setEditImage] = useState(false);
  const [OpenCrop, setOpenCrop] = useState(false);

  useEffect(() => {
    dispatch(Page(t('word.user')));
    if (props.id) {
      const getOne = async (id) => {
        setOp(true);
        const res = await getUser(id);
        if (res.errors) {
          for (const i of res.errors) {
            props.openAlert(
              t(`message.${i.code}`, {param: t(`word.${i.field}`)}),
              i.color,
            );
            history.replace('/user');
          }
        } else {
          setName(res.name);
          setEmail(res.email);
          setLanguage(res.language);
          setStatus(res.active);
          setProfile(res['profile.idprofile']);
          setOccupation(res['occupation.idoccupation']);
          setIdUserPms(res.iduserpms);
          setPhoto('');
          if (res.photo && res.mimetype) {
            setPhoto({
              photo: URL.createObjectURL(
                base64toBlob(
                  res.photo,
                  res.mimetype ? res.mimetype : 'image/jpeg',
                ),
              ),
            });
          }
        }
        setOp(false);
      };
      getOne(props.id);
    }

    const Profiles = async () => {
      const res = await getProfiles();
      if (res && !res.errors) {
        setProfiles(res);
      } else if (res.errors) {
        for (const i of res.errors) {
          props.openAlert(
            t(`message.${i.code}`, {param: t(`word.${i.field}`)}),
            i.color,
          );
        }
        history.replace('/user');
      }
    };
    Profiles();

    const Occupations = async () => {
      const res = await getOccupations();
      if (res && !res.errors) {
        setOccupations(res);
      } else if (res.errors) {
        for (const i of res.errors) {
          props.openAlert(
            t(`message.${i.code}`, {param: t(`word.${i.field}`)}),
            i.color,
          );
        }
        history.replace('/user');
      }
    };
    Occupations();
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisable(true);
    setOp(true);

    let res;
    const fData = new FormData();

    fData.append('name', name);
    fData.append('email', email);
    fData.append('active', status);
    fData.append('language', language);
    fData.append('profile', profile);
    fData.append('deleteImage', deleteImage);
    fData.append('occupation', occupation);
    fData.append('iduserpms', iduserpms);
    fData.append('photo', photo);
    fData.append('editImage', editImage);

    if (props.id) {
      res = await editUser(props.id, fData);
      setResult(res);
    } else {
      res = await addUser(fData);
      setResult(res);
    }

    setOp(false);

    if (res && !res.errors) {
      if (props.id) {
        props.openAlert(
          t('message.edit-record', {param: t('word.user')}),
          'success',
        );
      } else {
        props.openAlert(
          t('message.insert-record', {param: t('word.user')}),
          'success',
        );
      }
      history.replace('/user');
    } else {
      setDisable(false);
    }
  };

  const delUser = async (id) => {
    setOp(true);
    const res = await deleteUser(id);
    setResult(res);
    setOp(false);
    if (res && !res.errors) {
      props.openAlert(
        t('message.delete-record', {param: t('word.user')}),
        'success',
      );
      history.replace('/user');
    }
  };

  const HandlePhoto = (e) => {
    const arr = e.target.files[0];
    setEditImage(true);
    arr.photo = URL.createObjectURL(e.target.files[0]);
    setPhoto(arr);
  };

  const formatCrop = {
    w: 4,
    h: 4,
    shape: 'round',
  };

  return (
    <>
      {OpenCrop && (
        <ModalEdit
          formatCrop={formatCrop}
          setEditImage={setEditImage}
          setOpenCrop={setOpenCrop}
          OpenCrop={OpenCrop}
          photoURL={photo.photo}
          setPhotoURL={setPhoto}
        />
      )}
      <Card className="p-4 mb-4">
        {result.errors && <Error result={result} />}
        <form onSubmit={handleSubmit}>
          <Grid container direction="row" spacing={12}>
            <Grid container xs={12} sm={12}>
              {props.id && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={status}
                      onChange={() => setStatus(!status)}
                      value={status}
                      disabled={disable}
                      color="secondary"
                      fullWidth
                    />
                  }
                  label={status ? t('word.active') : t('word.inactive')}
                />
              )}
            </Grid>
            <Grid container xs={12} sm={12} md={5} lg={5}>
              <TextField
                className="m-2"
                label={t('word.name')}
                id="standard-size-small"
                size="small"
                required
                disabled={disable}
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
                inputProps={{maxLength: 128}}
              />
            </Grid>
            <Grid container xs={12} sm={12} md={5} lg={5}>
              <TextField
                type="email"
                className="m-2"
                label={t('word.email')}
                id="standard-size-small"
                required
                disabled={disable}
                size="small"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                inputProps={{maxLength: 128}}
              />
            </Grid>
            <Grid container xs={12} sm={12} md={2} lg={2}>
              <TextField
                className="m-2"
                id="standard-select-currency"
                select
                label={t('word.language')}
                required
                disabled={disable}
                value={language}
                onChange={(e) => setLanguage(e.target.value)}
                fullWidth>
                {languages.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid container xs={12} sm={12} md={5} lg={5}>
              <TextField
                className="m-2"
                id="profile"
                select
                label={t('word.profile')}
                required
                disabled={disable}
                value={profile}
                onChange={(e) => setProfile(e.target.value)}
                fullWidth>
                {profiles.map((option) => (
                  <MenuItem key={option.value} value={option.idprofile}>
                    {option.profile}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid container xs={12} sm={12} md={5} lg={5}>
              <TextField
                className="m-2"
                id="occupation"
                select
                required
                label={t('word.occupation')}
                disabled={disable}
                value={occupation}
                onChange={(e) => setOccupation(e.target.value)}
                fullWidth>
                {occupations.map((option) => (
                  <MenuItem key={option.value} value={option.idoccupation}>
                    {option.occupation}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {Enum.arrPms.includes(props.pms) && (
              <Grid container xs={12} sm={12} md={2} lg={2}>
                <TextField
                  className="m-2"
                  label={t('word.iduserpms2')}
                  id="standard-size-small"
                  size="small"
                  type="number"
                  required
                  disabled={disable}
                  value={parseInt(iduserpms)}
                  inputProps={{min: 0}}
                  onChange={(e) => setIdUserPms(e.target.value)}
                  fullWidth
                />
              </Grid>
            )}
          </Grid>
          <Grid container direction="column">
            <Grid container direction="row">
              {photo && (
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-block p-0 avatar-icon-wrapper rounded-circle m-0 border-3 border-first">
                    <div className="rounded-circle border-3 border-white overflow-hidden">
                      <FormatImage photo={photo} />
                    </div>
                  </div>
                </div>
              )}
              {props.id && photo && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={deleteImage}
                      onChange={() => setDeleteimage(!deleteImage)}
                    />
                  }
                  label={t('message.delete-image')}
                />
              )}
            </Grid>
            <input
              accept="image/*"
              style={{display: 'none'}}
              id="raised-button-file"
              type="file"
              ref={fileField}
              disabled={disable || deleteImage}
              onChange={(e) => HandlePhoto(e)}
            />
            <label htmlFor="raised-button-file">
              {!photo.photo && (
                <Button
                  variant="outlined"
                  component="span"
                  disabled={disable || deleteImage}>
                  {t('word.add-image')}
                </Button>
              )}
              {photo.photo && (
                <IconButton>
                  <CropIcon
                    color="primary"
                    arial-label="Crop"
                    onClick={() => setOpenCrop(true)}
                  />
                </IconButton>
              )}
            </label>
          </Grid>
          <div style={{float: 'right'}}>
            <Button
              style={{textTransform: 'none'}}
              disabled={disable}
              onClick={() => history.replace('/user')}
              className="m-2"
              variant="contained"
              color="secondary">
              <ReplayIcon />
              {t('word.cancel')}
            </Button>

            <Delete func={delUser} id={props.id} disabled={disable} />

            <Button
              style={{textTransform: 'none'}}
              disabled={disable}
              type="submit"
              className="m-2"
              color="secondary"
              variant="contained">
              <CheckIcon />
              {t('word.save')}
            </Button>
          </div>
        </form>
      </Card>
    </>
  );
}
