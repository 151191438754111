import React, {Fragment, useEffect, useState} from 'react';
import {getCompany, addCompany, editCompany} from 'helpers/apiDsl';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
  Button,
  Card,
  Switch,
  FormControlLabel,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ReplayIcon from '@material-ui/icons/Replay';
import {useTranslation} from 'react-i18next';
import Error from '../../Error';

export default function ModalCompany(props) {
  const {modalOpenCompany, setModalOpenCompany} = props;
  const {t} = useTranslation();
  const [idcompany, setIdCompany] = useState();
  const [company, setCompany] = useState('');
  const [corporatename, setCorporatename] = useState('');
  const [status, setStatus] = useState(true);
  const [result, setResult] = useState({});
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    if (props.id) {
      const getOne = async (id) => {
        const res = await getCompany(id);
        if (res.errors) {
          props.openAlert('Empresa não encontrada', 'error');
          window.location.href = `/desbravador`;
        } else {
          setIdCompany(res.idcompany);
          setCompany(res.company);
          setCorporatename(res.corporatename);
          setStatus(res.active);
        }
      };
      getOne(props.id);
    } else {
      setResult({});
      setIdCompany();
      setCompany('');
      setCorporatename('');
      setStatus('');
    }
    // eslint-disable-next-line
  }, [props.id]);

  const closeModal = () => {
    setModalOpenCompany(false);
    setResult({});
  };

  const handleSubmit = async (e) => {
    props.setOp(true);
    setResult({});
    e.preventDefault();
    setDisable(true);

    let res;

    if (props.id) {
      res = await editCompany(props.id, company, corporatename, status);
      setResult(res);
    } else {
      res = await addCompany(idcompany, company, corporatename);
      setResult(res);
    }
    if (res && !res.errors) {
      if (props.id) {
        props.openAlert('Empresa editada com sucesso', 'success');
      } else {
        props.openAlert('Empresa cadastrada com sucesso', 'success');
      }
    }
    if (res && !res.errors) {
      setModalOpenCompany(false);
      setDisable(false);
      props.getAllCompanies();
    } else {
      setDisable(false);
    }
    props.setOp(false);
  };

  return (
    <>
      <Dialog
        open={modalOpenCompany}
        onClose={closeModal}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{t('word.company')}</DialogTitle>
        <DialogContent>
          <Card className="p-4 mb-4">
            {result.errors && <Error result={result} />}
            <form onSubmit={handleSubmit}>
              <Grid container direction="row">
                {!props.id && (
                  <Grid item sm={4}>
                    <TextField
                      className="m-2"
                      label={t('word.idcompany')}
                      required
                      type="number"
                      disabled={disable}
                      value={parseInt(idcompany)}
                      onChange={(e) => setIdCompany(e.target.value)}
                    />
                  </Grid>
                )}
                <Grid item sm={4}>
                  <TextField
                    className="m-2"
                    label={t('word.company')}
                    required
                    disabled={disable}
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                    inputProps={{maxLength: 64}}
                  />
                </Grid>
                <Grid item sm={4}>
                  <TextField
                    className="m-2"
                    label={t('word.corporatename')}
                    required
                    disabled={disable}
                    value={corporatename}
                    onChange={(e) => setCorporatename(e.target.value)}
                    inputProps={{maxLength: 256}}
                  />
                </Grid>
                <Grid item sm={4}>
                  {props.id && (
                    <FormControlLabel
                      control={
                        <Switch
                          checked={status}
                          onChange={() => setStatus(!status)}
                          value={status}
                          disabled={disable}
                          color="secondary"
                        />
                      }
                      label={status ? t('word.active') : t('word.inactive')}
                    />
                  )}
                </Grid>
              </Grid>
              <div style={{float: 'right'}}>
                <Button
                  style={{textTransform: 'none'}}
                  disabled={disable}
                  onClick={closeModal}
                  className="m-2"
                  variant="contained"
                  color="secondary">
                  <ReplayIcon />
                  {t('word.cancel')}
                </Button>

                <Button
                  style={{textTransform: 'none'}}
                  disabled={disable}
                  type="submit"
                  className="m-2"
                  color="secondary"
                  variant="contained">
                  <CheckIcon />
                  {t('word.save')}
                </Button>
              </div>
            </form>
          </Card>
        </DialogContent>
      </Dialog>
    </>
  );
}
