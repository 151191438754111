/* eslint-disable no-restricted-syntax */
import React, {Fragment, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
  Fab,
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  Menu,
  List,
  Typography,
  ListItem,
} from '@material-ui/core';
import UpdateIcon from '@material-ui/icons/Update';
import Enum from 'helpers/enum';
import {useTranslation} from 'react-i18next';
import HistoryIcon from '@material-ui/icons/History';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ModalErroPms from '../../modals/ModalErrorPms';
import CardContainer from '../../CardContainer';
import ModalHistoricPms from '../../modals/HistoricPms';
import {
  schedulePmsIntegration,
  schedulePmsHistoric,
} from '../../../helpers/api';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tablerow: {
    // cursor: 'pointer',
    // transition: 'all ease 0.2s',
    // '&:hover': {
    //   backgroundColor: '#CCC'
    // }
  },
  // clicktablerow: {
  //   cursor: 'pointer',
  //   transition: 'all ease 0.2s',
  //   backgroundColor: '#CCC'
  // }
}));

export default function RegularTables1(props) {
  const classes = useStyles();
  const {t} = useTranslation();
  const {list, setList} = props;
  const [hist, setHist] = useState([]);
  const [pms, setPms] = useState();
  const [idsch, setIdSch] = useState();
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const [modalHis, setModalHis] = useState(false);
  const [modalPms, setModalPms] = useState(false);
  const [pmsError, setPmsError] = useState([]);

  useEffect(() => {}, []);

  const handleClickMenu = (event) => {
    setAnchorElMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
    setIdSch();
  };

  const handleHistoric = async () => {
    props.setOp(true);
    const res = await schedulePmsHistoric(idsch);
    if (res && !res.errors) {
      setHist(res);
      setModalHis(true);
    } else {
      // eslint-disable-next-line no-restricted-syntax
      for (const i of res.errors) {
        props.openAlert(
          t(`message.${i.code}`, {param: t(`word.${i.field}`)}),
          i.color,
        );
      }
      setModalHis(false);
    }
    handleCloseMenu();
    props.setOp(false);
  };

  const handleIntegrate = async () => {
    props.setOp(true);
    const idschedule = [idsch];
    const res = await schedulePmsIntegration(idschedule);
    if (res[0] !== undefined) {
      if (res[0].error.error === true) {
        setModalPms(true);
        setPmsError(res);
      }
    } else if (res && !res.errors) {
      const arr = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const i of list) {
        if (i.idschedule === idsch) {
          i.pms = 1;
        }
        arr.push(i);
      }
      setList(arr);
    } else {
      for (const i of res.errors) {
        props.openAlert(
          t(`message.${i.code}`, {param: t(`word.${i.field}`)}),
          i.color,
        );
      }
    }
    setHist([]);
    setModalHis(false);
    handleCloseMenu();
    props.setOp(false);
  };

  const handleSch = async (e) => {
    setIdSch(e.idschedule);
    setPms(e.pms);
  };

  return (
    <>
      {modalHis === true && (
        <ModalHistoricPms
          modalHis={modalHis}
          setModalHis={setModalHis}
          setHist={setHist}
          hist={hist}
          openAlert={props.openAlert}
          setOp={props.setOp}
        />
      )}
      {modalPms === true && (
        <ModalErroPms
          modalPms={modalPms}
          setModalPms={setModalPms}
          pms={pmsError}
        />
      )}
      <CardContainer>
        <div className={classes.root}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{t('word.activity')}</TableCell>
                  <TableCell align="left">{t('word.date')}</TableCell>
                  <TableCell align="right">{t('word.naccount')}</TableCell>
                  <TableCell align="left">{t('word.professional')}</TableCell>
                  <TableCell align="left">
                    {t('word.integration-status')}
                  </TableCell>
                  <TableCell align="left">
                    {t('word.type-integration')}
                  </TableCell>
                  <TableCell align="right">{t('word.value')}</TableCell>
                  <TableCell align="left">{t('word.options')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list &&
                  list.map((i) => (
                    <TableRow
                      key={i.idschedule}
                      className={
                        idsch === i.idschedule
                          ? classes.clicktablerow
                          : classes.tablerow
                      }
                      onClick={() => handleSch(i)}>
                      <TableCell component="th" scope="row">
                        {i['activity.activity']}
                      </TableCell>

                      <TableCell align="left">{i.date}</TableCell>
                      <TableCell align="right">
                        {i['person.customer.idaccountpms']}
                      </TableCell>
                      <TableCell align="left">
                        {i['professional.user.name']}
                      </TableCell>
                      <TableCell align="left">
                        <Tooltip arrow title={t(`word.schedule-pms.${i.pms}`)}>
                          <span
                            className={`badge-circle mt-2 mr-2 align-self-start badge badge-${t(
                              `word.schedule-status-color.${
                                i.pms === 0 ? 1 : 3
                              }`,
                            )}`}>
                            .
                          </span>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="left">
                        {i.integratedpms === true &&
                          i.integrationpms != null &&
                          t(`word.statusintegrationpms.${i.integrationpms}`)}
                      </TableCell>
                      <TableCell align="right">
                        {i.integrationpms ===
                          Enum.schedule_integrationpms.finishedwithvalue ||
                        i.integrationpms === null
                          ? props.companyConfigs.currencysymbol + i.price
                          : props.companyConfigs.currencysymbol +
                            i.penaltyvalue}
                      </TableCell>
                      <TableCell align="left">
                        <div className="ml-auto font-size-xs">
                          <Fab
                            size="small"
                            disabled={list.length < 1}
                            aria-label="add"
                            color="secondary"
                            onClick={handleClickMenu}>
                            <MoreHorizIcon />
                          </Fab>
                        </div>
                        <div>
                          <Menu
                            anchorEl={anchorElMenu}
                            keepMounted
                            open={Boolean(anchorElMenu)}
                            onClose={handleCloseMenu}
                            classes={{list: 'p-0'}}
                            getContentAnchorEl={null}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'center',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'center',
                            }}>
                            <div className="overflow-hidden dropdown-menu-xl">
                              <List className="nav-danger nav-pills flex-column p-3">
                                <Typography
                                  className="text-black py-2 px-3 font-weight-bold"
                                  component="div">
                                  {t('word.options')}
                                </Typography>
                                <ListItem
                                  onClick={() => handleHistoric()}
                                  button
                                  className="rounded-sm">
                                  <div className="nav-link-icon opacity-6">
                                    <HistoryIcon />
                                  </div>
                                  <span>{t('word.historic-pms')}</span>
                                </ListItem>
                                {pms === 0 && (
                                  <ListItem
                                    onClick={() => handleIntegrate()}
                                    button
                                    className="rounded-sm">
                                    <div className="nav-link-icon opacity-6">
                                      <UpdateIcon />
                                    </div>
                                    <span>{t('word.integrate')}</span>
                                  </ListItem>
                                )}
                              </List>
                            </div>
                          </Menu>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </CardContainer>
    </>
  );
}
