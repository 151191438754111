/* eslint-disable no-restricted-syntax */
import React, {Fragment, useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {FaPlus} from 'react-icons/fa';
import {makeStyles} from '@material-ui/core/styles';
import {
  Fab,
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Page} from 'store/reducers/Page/pageAction';
import CardContainer from '../../components/CardContainer';
import {getActivities} from '../../helpers/api';
import Enum from '../../helpers/enum';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tablerow: {
    cursor: 'pointer',
    transition: 'all ease 0.2s',
    '&:hover': {
      backgroundColor: '#CCC',
    },
  },
}));

const FormatImage = (props) => {
  if (props.photo && props.type) {
    return (
      <img
        style={{width: '80px'}}
        className="img-fluid"
        src={`data:${props.type};base64,${props.photo}`}
        alt="imagem"
      />
    );
  }
  return (
    <img
      style={{width: '80px'}}
      className="img-fluid"
      src="/easy-planner-default-no-image.png"
      alt="imagem"
    />
  );
};

export default function RegularTables1(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const {t} = useTranslation();
  const [activity, setActivity] = useState([]);
  const [fActivity, setFactivity] = useState([]);

  useEffect(() => {
    props.setOp(true);
    dispatch(Page(t('word.activities')));
    const getAll = async () => {
      const res = await getActivities();
      if (res && !res.errors) {
        setActivity(res);
        setFactivity(res);
      } else if (res.errors) {
        for (const i of res.errors) {
          props.openAlert(
            t(`message.${i.code}`, {param: t(`word.${i.field}`)}),
            i.color,
          );
        }
      }
      props.setOp(false);
    };
    getAll();
    // eslint-disable-next-line
  }, [props.pms]);

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    let result = [];
    result = activity.filter(
      (row) => row.activity.toLowerCase().search(value) !== -1,
    );
    setFactivity(result);
  };

  const classes = useStyles();

  return (
    <>
      <CardContainer>
        <div className={classes.root}>
          <div className="card-header">
            <FormControl className="w-100 m-2">
              <InputLabel htmlFor="input-with-icon-adornment">
                {t('word.search')}
              </InputLabel>
              <Input
                onChange={(event) => handleSearch(event)}
                style={{maxWidth: '500px'}}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </FormControl>
            <div className="card-header--actions">
              <Link to="/activity/new">
                <Fab size="small" color="secondary">
                  <span className="btn-wrapper--icon">
                    <FaPlus />
                  </span>
                </Fab>
              </Link>
            </div>
          </div>
          <TableContainer className="mb-4" component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{t('word.image')}</TableCell>
                  <TableCell>{t('word.title')}</TableCell>
                  <TableCell>{t('word.price')}</TableCell>
                  <TableCell>{t('word.duration')}</TableCell>
                  <TableCell style={{maxWidth: '80px'}}>
                    {t('word.simultaneous')}
                  </TableCell>
                  {Enum.arrPms.includes(props.pms) && (
                    <TableCell style={{maxWidth: '80px'}}>
                      {t('word.idactivitypms')}
                    </TableCell>
                  )}
                  <TableCell>{t('word.status')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fActivity &&
                  fActivity.map((i) => (
                    <TableRow
                      key={i.idactivity}
                      className={classes.tablerow}
                      onClick={() =>
                        history.push(`activity/edit/${i.idactivity}`)
                      }>
                      <TableCell component="th" scope="row">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-block p-0 avatar-icon-wrapper rounded m-0 border-3 border-first">
                            <div className="rounded border-3 border-white overflow-hidden">
                              <FormatImage photo={i.photo} type={i.mimetype} />
                            </div>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {i.activity}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {`${props.companyConfigs.currencysymbol} ${i.price}`}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {`${i.duration} ${t('word.minutes')}`}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {i.simultaneous}
                      </TableCell>
                      {Enum.arrPms.includes(props.pms) && (
                        <TableCell component="th" scope="row">
                          {i.integratedpms === true
                            ? i.idactivitypms
                            : t('word.no-integrate')}
                        </TableCell>
                      )}
                      <TableCell component="th" scope="row">
                        {i.active ? t('word.active') : t('word.inactive')}
                      </TableCell>
                      {/* <TableCell component='th' scope='row'>
                      {i.duration+' '+t('word.minutes')}
                    </TableCell>
                    <TableCell component='th' scope='row'>
                      {i.simultaneous}
                    </TableCell>
                    <TableCell component='th' scope='row'>
                      <FormatImage photo={i.photo} type={i.mimetype} />
                    </TableCell> */}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </CardContainer>
    </>
  );
}
