/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-restricted-syntax */
import React, {useEffect, useState} from 'react';
import {matchPath} from 'react-router-dom';
import PropTypes from 'prop-types';

import {List, Typography} from '@material-ui/core';

import useRouter from 'utils/useRouter';
import {useTranslation} from 'react-i18next';
import SidebarMenuListItem from './SidebarMenuListItem';
import Enum from '../../helpers/enum';
import SidebarFooter from '../SidebarFooter';

const reduceChildRoutes = (props) => {
  const {router, items, page, depth} = props;
  if (page.content) {
    const open = matchPath(router.location.pathname, {
      path: page.to,
      exact: false,
    });

    items.push(
      <SidebarMenuListItem
        depth={depth}
        icon={page.icon}
        key={page.label}
        label={page.badge}
        open={Boolean(open)}
        title={page.label}>
        <div className="sidebar-menu-children py-2">
          <SidebarMenuList
            depth={depth + 1}
            pages={page.content}
            router={router}
          />
        </div>
      </SidebarMenuListItem>,
    );
  } else {
    items.push(
      <SidebarMenuListItem
        depth={depth}
        href={page.to}
        icon={page.icon}
        key={page.label}
        label={page.badge}
        title={page.label}
      />,
    );
  }

  return items;
};

const SidebarMenuList = (props) => {
  const {pages, ...rest} = props;
  return (
    <List className="p-0">
      {pages.reduce(
        (items, page) => reduceChildRoutes({items, page, ...rest}),
        [],
      )}
    </List>
  );
};

SidebarMenuList.propTypes = {
  // eslint-disable-next-line react/require-default-props
  depth: PropTypes.number,
  // eslint-disable-next-line react/require-default-props
  pages: PropTypes.array,
};

const SidebarMenu = (props) => {
  const [menuList, setMenuList] = useState([]);
  const {t} = useTranslation();
  const {title, pages, className, component: Component, ...rest} = props;

  const handleMenuFooter = async (pms) => {
    const newPages = [];
    let newContent = [];
    const newArrPages = [];

    for (const i of pages) {
      if (i.pms === 'true' || i.pms === true) {
        if (Enum.arrPms.includes(pms)) {
          newPages.push({
            ...i,
            label: `${t(i.label)}`,
          });
        }
      } else if (i.pms === undefined) {
        newPages.push({
          ...i,
          label: `${t(i.label)}`,
        });
      }
    }

    // eslint-disable-next-line guard-for-in
    for (const i in newPages) {
      newContent = [];
      for (const j of newPages[i].content) {
        if (props.configs[j.key] && (j.pms === 'true' || j.pms === true)) {
          if (Enum.arrPms.includes(pms)) {
            newContent.push({
              ...j,
              label: `${t(j.label)}`,
            });
          }
        } else if (props.configs[j.key] && j.pms === undefined) {
          newContent.push({
            ...j,
            label: `${t(j.label)}`,
          });
        }
      }

      newPages[i].content = newContent;
    }

    for (const i in newPages) {
      if (newPages[i].content.length > 0) {
        newArrPages.push({...newPages[i]});
      }
    }
    return newArrPages;
  };

  useEffect(() => {
    const get = async () => {
      const res = await handleMenuFooter(props.pms);
      if (res) {
        setMenuList(res);
      }
    };
    get();
    // eslint-disable-next-line
  }, [props.pms]);

  const router = useRouter();
  return (
    <>
      <SidebarFooter pms={props.pms} pages={menuList} configs={props.configs} />
      <Component {...rest} className={className}>
        {title && (
          <Typography className="app-sidebar-heading">{t(title)}</Typography>
        )}
        <SidebarMenuList depth={0} pages={menuList} router={router} t={t()} />
      </Component>
    </>
  );
};

SidebarMenu.propTypes = {
  className: PropTypes.string,
  component: PropTypes.any,
  pages: PropTypes.array.isRequired,
  title: PropTypes.string,
};

SidebarMenu.defaultProps = {
  component: 'nav',
};

export default SidebarMenu;
