/* eslint-disable no-restricted-syntax */
import React, {Fragment, useEffect, useState} from 'react';
import {addPeriod, editPeriod, getPeriod, deletePeriod} from 'helpers/api';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
  Button,
  Card,
  MenuItem,
} from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import CheckIcon from '@material-ui/icons/Check';
import ReplayIcon from '@material-ui/icons/Replay';
import {useTranslation} from 'react-i18next';
import {mask} from 'remask';
import Delete from '../../ButtonDelete';
import Error from '../../Error';

const weekDays = [0, 1, 2, 3, 4, 5, 6];

export default function ModalPeriod(props) {
  const {modalOpen, setModalOpen} = props;
  const {t} = useTranslation();
  const [result, setResult] = useState({});
  const [day, setDay] = useState('');
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');
  const [disable, setDisable] = useState(false);
  const history = useHistory();

  const handleClose = (status) => {
    setModalOpen(status);
    setResult('');
  };

  useEffect(() => {
    if (props.id) {
      const getOne = async (id) => {
        const res = await getPeriod(id);
        if (res.errors) {
          for (const i of res.errors) {
            props.openAlert(
              t(`message.${i.code}`, {param: t(`word.${i.field}`)}),
              i.color,
            );
          }
          history.replace('/activity');
        } else {
          setDay(res.day);
          setStart(Array.from(res.starttime).splice(0, 5).join(''));
          setEnd(Array.from(res.endtime).splice(0, 5).join(''));
        }
      };
      getOne(props.id);
    }
    // eslint-disable-next-line
  }, [props.idactivity, props.id]);

  const submitPer = async (e) => {
    e.preventDefault();
    setDisable(true);

    let res;

    if (props.id) {
      res = await editPeriod(props.id, props.idactivity, day, start, end);
      setResult(res);
    } else {
      res = await addPeriod(props.idactivity, day, start, end);
      setResult(res);
    }
    if (res && !res.errors) {
      if (props.id) {
        props.openAlert(
          t('message.edit-record', {param: t('word.period')}),
          'success',
        );
      } else {
        props.openAlert(
          t('message.insert-record', {param: t('word.period')}),
          'success',
        );
      }
      setModalOpen(false);
      setDisable(false);
      setResult('');
      props.setObserver(!props.observer);
    } else {
      setDisable(false);
    }
  };

  const delPeriod = async (id) => {
    const res = await deletePeriod(id, props.idactivity);
    setResult(res);
    if (res && !res.errors) {
      props.openAlert(
        t('message.delete-record', {param: t('word.period')}),
        'success',
      );
      setModalOpen(false);
      props.setObserver(!props.observer);
    }
  };

  const handlePeriodFirst = (e) => {
    e.preventDefault();

    if (start.length > 4) {
      return;
    }
    setStart(e.target.value);
  };

  const writeFirst = (e) => {
    if (start.length > 4) {
      if (e.keyCode === 8) {
        setStart(start.slice(0, -1));
      }
    }
  };

  const handlePeriodSecond = (e) => {
    e.preventDefault();

    if (end.length > 4) {
      return;
    }

    setEnd(e.target.value);
  };

  const writeSecond = (e) => {
    if (end.length > 4) {
      if (e.keyCode === 8) {
        setEnd(end.slice(0, -1));
      }
    }
  };

  return (
    <>
      <Dialog
        open={modalOpen}
        onClose={() => handleClose(false)}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{t('word.period')}</DialogTitle>
        <DialogContent>
          <Card className="p-4 mb-4">
            {result.errors && <Error result={result} />}
            <form onSubmit={submitPer}>
              <Grid container direction="row" spacing={12}>
                <Grid container sm={4}>
                  <TextField
                    className="m-2"
                    select
                    label={t('word.days')}
                    required
                    disabled={disable}
                    value={day}
                    onChange={(e) => setDay(e.target.value)}
                    fullWidth>
                    {weekDays.map((a) => (
                      <MenuItem key={a} value={a}>
                        {t(`word.week.${a}`)}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid container sm={4}>
                  <TextField
                    className="m-2"
                    label={t('word.starttime')}
                    placeholder="HH:MM"
                    required
                    disabled={disable}
                    value={mask(start, '99:99')}
                    onKeyUp={writeFirst}
                    onChange={handlePeriodFirst}
                    fullWidth
                  />
                </Grid>
                <Grid container sm={4}>
                  <TextField
                    className="m-2"
                    label={t('word.endtime')}
                    placeholder="HH:MM"
                    required
                    disabled={disable}
                    value={mask(end, '99:99')}
                    onKeyUp={writeSecond}
                    onChange={handlePeriodSecond}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <div style={{float: 'right'}}>
                <Button
                  style={{textTransform: 'none'}}
                  disabled={disable}
                  onClick={() => handleClose(false)}
                  className="m-2"
                  variant="contained"
                  color="secondary">
                  <ReplayIcon />
                  {t('word.cancel')}
                </Button>

                <Delete func={delPeriod} id={props.id} disabled={disable} />

                <Button
                  style={{textTransform: 'none'}}
                  disabled={disable}
                  onClick={submitPer}
                  className="m-2"
                  color="secondary"
                  variant="contained">
                  <CheckIcon />
                  {t('word.save')}
                </Button>
              </div>
            </form>
          </Card>
        </DialogContent>
      </Dialog>
    </>
  );
}
