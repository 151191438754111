import React from 'react';
import {Dialog, Button} from '@material-ui/core';
import {FaExclamation} from 'react-icons/fa';

export default function ModalView(props) {
  const close = () => props.setModalView(!props.modalView);

  return (
    <>
      <Dialog open={props.modalView} onClose={close}>
        <div className="text-center p-5">
          {props.icon !== false && (
            <div className="avatar-icon-wraper rounded-circle m-0">
              <div
                style={{fontSize: '40px'}}
                className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-warning text-warning m-0 d-130">
                <span>
                  <FaExclamation className="font-size-auto mx-auto" />
                </span>
              </div>
            </div>
          )}

          {props.message1 && (
            <h4 className="font-weight-bold mt-4">{props.message1}</h4>
          )}
          {props.message2 && (
            <p className="mb-0 font-size-lg text-muted text-black-50">
              {props.message2}
            </p>
          )}
          {props.messageButton && (
            <div className="pt-4">
              <Button
                style={{height: '40px'}}
                onClick={close}
                variant="contained"
                color="primary"
                className="mx-1">
                <span className="btn-wrapper--label">
                  {props.messageButton}
                </span>
              </Button>
            </div>
          )}
        </div>
      </Dialog>
    </>
  );
}
