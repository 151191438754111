import Cookies from 'js-cookie';

export const accepted = () => {
  const cookie = Cookies.get('cookie');
  return !cookie;
};

export const isLogged = () => {
  const token = Cookies.get('token');
  return !!token;
};

export const setCompany = (id) => {
  Cookies.set('company', id);
};

export const getCompany = () => Cookies.get('company');

export const setCookie = () => {
  Cookies.set('cookie', true);
};

export const doLogin = (token) => {
  Cookies.set('token', token);
};

export const doLogout = () => {
  Cookies.remove('token');
  Cookies.remove('iduser');
};

export const getIdUser = () => Cookies.get('iduser');

export const setIdUser = (id) => {
  Cookies.set('iduser', id);
};
