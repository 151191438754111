const INITIAL = {
  occupation: '',
};

export default function (state = INITIAL, action = {}) {
  switch (action.type) {
    case 'OCCUPATION':
      return {...state, occupation: action.payload.occupation};
    default:
      return state;
  }
}
