import React, {Fragment} from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const Page = (props) => {
  const classes = useStyles();

  return (
    <>
      <Backdrop
        style={{zIndex: 9999}}
        className={classes.backdrop}
        open={props.open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default Page;
