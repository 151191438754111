const INITIAL = {
  name: '',
};

export default function (state = INITIAL, action = {}) {
  switch (action.type) {
    case 'NAME':
      return {...state, name: action.payload.name};
    default:
      return state;
  }
}
