import React, {Fragment} from 'react';
import './index.css';
import {useTranslation} from 'react-i18next';

export default function LivePreviewExample() {
  const {t} = useTranslation();

  return (
    <>
      <>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <img
                alt=""
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAWgAAACWCAMAAADE1OI6AAAAilBMVEUAAACampqZmZmZmZmampqampqZmZmampqYmJiampqZmZmYmJiZmZmZmZmZmZmXl5fMzMycnJzLy8uZmZnm5ubMzMzm5ubMzMyZmZmZmZmZmZmZmZmrq6uZmZmZmZnm5ubMzMyZmZmZmZmZmZmZmZmZmZnMzMzLy8vLy8uZmZmYmJjm5uaZmZnMzMw5de9WAAAAK3RSTlMA58AohsXipC+Dm9qomfEh3AYi+fryglUTSLE8GlpT77qSNdF6ac6ATmIlW6YtdAAAAzNJREFUeNrt3WtTozAUgOGD1VatpcviBQpF6cXLevr//96CWGlnJ0PtEiX4Pp/O53cyaTITVAAAAAAAQA/d3Wwa3NxJo9vr1/93fSs99rBp9CCN7l/bcC89xooGAAAAAAAAAAAAgG5L56uLl6gYovnqdL0zoE3BVagFP5FRNcQyeRvCTNCmkWoZdimTahhLXg2e9NN3vakYTBbBVDWU6eUiSEPVIBolQeSrRpbfk6S/d6XSim6/ElqXK7o0L1d0KVP1LL+QetrsexKjXqxokaDcNBIp5MUQS+G02qOtrujHzb5H6buV6nDxvrCHyfvC9hKx7PnXvmfpu5n6kZRG22GiYSpoW5LF1bDYHdC6aH6+HRb1gNYNVavSY9Uq8FKVJd26INxm9VUzKXmqL4K2zWengZSyWV4NcTkAAL5aPLo61kpwuBM9FrfFT4b2x0fxVAeCT4Q+k6OcEprQXURoA0I7itAGhHYUoQ3shY7zySHmfQpt8XWEOfRSD+KnPQpt8XWEOfRQQ79RqHreo9BWX0eYQ19NG+V7obv/RsXM/usIc+iRNLrYC+3Cq6uu+GxoVnSXQ4PQRoR2FKEb2A2dZoF8CLKU0HZC/xnquu480xNC2whddg6Tnc56QWgboadF52y384Q92kbooOgcy9ZENefH0EroyN/pLDPNOXXY2jqmUgsWHO84R7uE0AaEdpQxdDCfEtpq6Po+SGiroev7IKFthq7vg+zRNkPXnfkxtBm67sypw2boujPHO/uhi84552j7oVPVnAuL2A8tL2tuhnu4grvkwNCDAaG/IPT5WHU5ILTt0MFYC8uA0JZDJ/pmQWjLoWN9kxDacujI08KQrcN2aIk9VS/hx9B6aIniOOJ4x4XFBYRu6buPDoQ+9huzZi79/egWQlv6atKMFe3kijZjj3YRoQ0I7ShCGxDaUYQ2ILSjCG1AaEcR2oDQjiK0AaEdRWgDQjuK0AaEdhShDQjtKEIbENpRhDYgtKMIbUDofznxjufnhP6Ol2mryw9jHb5Pvi4vG52ojvamigv/2vAbVnQSasvCpFsvCjsiOvNadhZ1640sftaKBgAAAAAAAAD011+/GsivmZ5EPAAAAABJRU5ErkJggg=="
                id="error-img-top"
                className="center-block img-responsive"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <h1 id="error-title">404</h1>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <h2 id="error-description">{t('message.page-not-found')}</h2>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <p id="error-back">
                <img
                  alt=""
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAANCAMAAABFNRROAAAAP1BMVEUAAABCjs5Cjs5Cj89Bjs8+itNGmcxCj85Cjs9Djs5Cjs5Cjs5Bjs5Cj89DjcxCjc1Cjs1DkM9Bjs5Gi9FCjs7tfR0OAAAAFHRSTlMA7KePOg4IzJlj99/eZCKAbDW/IYgMb6sAAABoSURBVAjXXY5JCkMxDENlJ3bm30n3P2vjUEqpwAshLD1sZR19aEbIE0nZl8IlSnN4k7CZ8oI9gEuYoWywcgPQqBh0Kyy13p0TXVAZekL6T2Y7U67P36JG5/XtPHvLYbH3z3I4Z5+H8w3dvAVCJpPa0QAAAABJRU5ErkJggg=="
                  id="error-back-img"
                  className="center-block img-responsive"
                />
                {/* eslint-disable-next-line */}
                <a href="javascript: history.go(-1)" id="error-back-text">
                  {t('message.return-page')}
                </a>
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div id="error-bottom">
                <img
                  alt=""
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIYAAAAPCAMAAAAWGEezAAAAllBMVEUAAAAPhsMKg8QKg8UJg8YJg8ULgsYKhMUKg8UIg8ULg8UNhccAi8YAisQKg8YKg8UKg8UHgsUJg8UKg8UJg8UJg8ULhMUKgsQKg8UJg8UIg8ILgsMKg8YKg8UKg8YJgcYKg8UKg8UKg8UKg8UKhMUJg8UKg8UKgsUKhMYKg8UJg8YKg8ULg8UJhMYKhMUKg8UKg8YKg8VkeFq5AAAAMXRSTlMAELr6MolDmZA8dxMJBsv26yPx56BZK66nbh0M4NRoG8WBdLNN2pRIGM+/ZF9Sfautp4KNYwAAA0VJREFUOMvFlWmPozAMhg3hKC1HOYce0Ju202PW///P7etAYGe00n7cR6qUBBI/doxK/59g1XpkCSH9hV1wdvoHoaW5bejfRJY1jQD9wS3wKtJYPRGB5+G5ItZknw9Z+uCBeOPsGSQfAZZnPHC/Ejnck8QvAos8X1CquKKegi9Ed8aLAWvy2iONt9fTlWziHnXB7OGcoWGId5NGs0gzVnGcY+xvoGFQDjRGxOPAfCCq2SfNWvGDrsx7owH6ZyvsvscZ8ymExsicvNXKg8Ya9avaJccRNOZDsQqOb9ib+rwVjUIvVzEfoKH6yS+JtVmilBYWE2ShC5dHJGlfobElvOC4SkQhVSwQ6ZFwJxo3C6y/eElCrwGQ/hMaK+r5xFbNNcDZJtcDBtAgjSNHHPl+5zeRzS6BRcZHRExqKFbQ0LzgiANPpGlZraER9lHhM2kILp9MNfQsmZ2rPkNo1KkQNNxOGk++U5Tz68XLDUJnsu0t4xPPdoo9sgcNVKe1FDvUs+V21PBYhd81zpyb3sAG65OFbH8MpxYd6s++cFJIvOWGqMEAoeZE4bK/tTX5KEdgNFz2cWs0NrEPjcIHdcYf9F0D+UwaMndP91y3q1x4LDSKbUQx1BFduCQqOQ+RxTJEGyaLvl1TKUdBPXOuPdMEkpQ9tWizo5/V2JremNiUyA8bXdJUCXvIaw5yrEFAuUDJtju3sHJFoMbalj8pHQPPKlN9gmYNjTfOuPApoh8aBX/9oRHSmIk9aaDRWqfP6yld0PDANoLSpZRe9NkQ0NgNZZTwmQRpp9XQG1dO1j80SsZ7k8ap2JCm449JA3EfoiHEKsVFuJoLKoFSZLgONOdhLpzYNsWAMeyazZBXcjMtanMxapSedz7azDVBo/OEdcCcv6uI1nPFJc758oRrx+pmNIKaYn6TppRytMwqpZn5MG8JylF55btBCExzjq8RpR2joY1GNX4/bPBD0ehZVuWWgZJ1SWfkSEaDrCs+nKlKUu2CFtl4Fx3K8WKQPXRIOTHBz6VRgwp0kMbWFMeUwMEeKMKwLC5Z0ug/hNYsdwiR2l+m2PGRBl4xFp92RaXd0cDOtndU291zcN0c95naFo4MbTsy71QEfgOKQ4VhDMTorwAAAABJRU5ErkJggg=="
                  id="error-img-bottom"
                  className="center-block img-responsive"
                />
                {/* <a alt='' href="http://error.desbravador.com.br/" target="_self" id="error-link-bottom">error.desbravador.com.br</a> */}
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
}
