import Cookies from 'js-cookie';
import {doLogout} from './authDsl';

const BASE = process.env.REACT_APP_API_BASE;

const fetchPostDsl = async (rota, body) => {
  const token = Cookies.get('tokendsl');

  const res = await fetch(BASE + rota, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      token: `${token}`,
    },
    body: JSON.stringify(body),
  });

  const json = await res.json();

  if (json.notAllowed) {
    doLogout();
    window.location.href = `/desbravador`;
    return null;
  }

  return json;
};

const fetchGetDsl = async (rota) => {
  const token = Cookies.get('tokendsl');

  const res = await fetch(BASE + rota, {
    headers: {
      token: `${token}`,
    },
  });

  const json = await res.json();

  if (json.notAllowed) {
    doLogout();
    window.location.href = `/desbravador`;
    return null;
  }

  return json;
};

export const LoginDsl = async (email, password) => {
  const json = await fetchPostDsl('userdsl/login', {
    email,
    password,
  });
  return json;
};

export const verifyLoginDsl = async () => {
  const json = await fetchGetDsl('userdsl/verify');
  return json;
};

export const getUsersDsl = async () => {
  const json = await fetchGetDsl('userdsl');
  return json;
};

export const getUserDsl = async (iduserdsl) => {
  const json = await fetchGetDsl(`userdsl/${iduserdsl}`);
  return json;
};

export const addUserDsl = async (name, email, password) => {
  const json = await fetchPostDsl('userdsl/new', {
    name,
    email,
    password,
  });
  return json;
};

export const editUserDsl = async (id, name, email, active) => {
  const json = await fetchPostDsl(`userdsl/edit/${id}`, {
    name,
    email,
    active,
  });
  return json;
};

export const deleteUserDsl = async (iduserdsl) => {
  const json = await fetchGetDsl(`userdsl/delete/${iduserdsl}`);
  return json;
};

export const getCompany = async (idcompany) => {
  const json = await fetchGetDsl(`company/${idcompany}`);
  return json;
};

export const getCompanies = async () => {
  const json = await fetchGetDsl('company');
  return json;
};

export const addCompany = async (idcompany, company, corporatename) => {
  const json = await fetchPostDsl('company/new', {
    idcompany,
    company,
    corporatename,
  });
  return json;
};

export const editCompany = async (
  idcompany,
  company,
  corporatename,
  active,
) => {
  const json = await fetchPostDsl(`company/edit/${idcompany}`, {
    company,
    corporatename,
    active,
  });
  return json;
};
