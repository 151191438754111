import React, {Fragment} from 'react';
import {useSelector} from 'react-redux';
import {
  // Box,
  Button,
} from '@material-ui/core';

const HeaderMenu = (props) => {
  const result = useSelector((state) => state.page.name);

  return (
    <>
      <div
        style={{paddingLeft: props.isCollapsedLayout === true ? '60px' : '0px'}}
        className="app-header-menu">
        <Button
          size="medium"
          color="inherit"
          // onClick={handleClick}
          style={{cursor: 'default'}}
          className="btn-inverse font-size-xs mx-2">
          {result}
        </Button>
      </div>
    </>
  );
};

export default HeaderMenu;
