import React, {Fragment} from 'react';
import Cookies from 'js-cookie';
import {useHistory} from 'react-router-dom';
import {FaAngleDown} from 'react-icons/fa';
import {
  Avatar,
  Box,
  Badge,
  Menu,
  Button,
  List,
  ListItem,
  Divider,
} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {useSelector, useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Page} from 'store/reducers/Page/pageAction';
import {Logout} from '../../helpers/api';
import {doLogout} from '../../helpers/auth';

const imageDefault = 'easy-planner-default-person.png';

const StyledBadge = withStyles({
  badge: {
    backgroundColor: 'var(--success)',
    color: 'var(--success)',
    boxShadow: '0 0 0 2px #fff',
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
})(Badge);

export default function HeaderUserbox() {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const name = useSelector((state) => state.user.name);
  const occupation = useSelector((state) => state.occupation.occupation);
  const img = useSelector((state) => state.img.img);
  const type = useSelector((state) => state.type);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleLogout = async () => {
    dispatch(Page(''));
    await Logout();
    doLogout();

    const company = Cookies.get('company');
    window.location.href = `/company/${company}`;
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        color="inherit"
        onClick={handleClick}
        className="text-capitalize px-3 text-left btn-inverse d-flex align-items-center">
        <Box>
          <StyledBadge
            overlap="circular"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            variant="dot">
            <Avatar
              sizes="44"
              alt="Dustin Watson"
              src={img ? `data:${type};base64,${img}` : imageDefault}
            />
          </StyledBadge>
        </Box>
        <div className="d-none d-xl-block pl-3">
          <div className="font-weight-bold pt-2 line-height-1">{name}</div>
        </div>
        <span className="pl-1 pl-xl-3">
          <FaAngleDown className="opacity-5" />
        </span>
      </Button>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        onClose={handleClose}
        className="ml-2">
        <div className="dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
          <List className="text-left bg-transparent d-flex align-items-center flex-column pt-0">
            <Box>
              <StyledBadge
                overlap="circular"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                variant="dot">
                <Avatar
                  sizes="44"
                  alt="Dustin Watson"
                  src={img ? `data:${type};base64,${img}` : imageDefault}
                />
              </StyledBadge>
            </Box>
            <div style={{paddingTop: '5px'}}>
              <span className="font-weight-bold text-center pt-2 line-height-1 text-center">
                {name}
              </span>
            </div>
            <div>
              <span className="text-black-50 text-center">{occupation}</span>
            </div>
            <Divider className="w-100 mt-2" />
            <ListItem
              button
              onClick={() => history.push(`/user/change/password`)}>
              {t('word.change-my-password')}
            </ListItem>
            <ListItem button onClick={handleLogout}>
              {t('word.logout')}
            </ListItem>
          </List>
        </div>
      </Menu>
    </>
  );
}
