/* eslint-disable no-restricted-syntax */
import React, {Fragment, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {
  addOccupation,
  editOccupation,
  getOccupation,
  deleteOccupation,
} from 'helpers/api';
import {Grid, Button, Card, TextField} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ReplayIcon from '@material-ui/icons/Replay';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Page} from 'store/reducers/Page/pageAction';
import Delete from '../../ButtonDelete/index';
import Error from '../../Error';

export default function Formulario(props) {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const history = useHistory();
  const [occupation, setOccupation] = useState('');
  const [result, setResult] = useState({});
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    dispatch(Page(t('word.occupation')));
    if (props.id) {
      const getOne = async (id) => {
        const res = await getOccupation(id);
        if (res.errors) {
          for (const i of res.errors) {
            props.openAlert(
              t(`message.${i.code}`, {param: t(`word.${i.field}`)}),
              i.color,
            );
          }
          history.replace('/occupation');
        } else {
          setOccupation(res.occupation);
        }
      };
      getOne(props.id);
    }
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisable(true);

    let res;

    if (props.id) {
      res = await editOccupation(props.id, occupation);
      setResult(res);
    } else {
      res = await addOccupation(occupation);
      setResult(res);
    }

    if (res && !res.errors) {
      if (props.id) {
        props.openAlert(
          t('message.edit-record-f', {param: t('word.occupation')}),
          'success',
        );
      } else {
        props.openAlert(
          t('message.insert-record-f', {param: t('word.occupation')}),
          'success',
        );
      }
      history.replace('/occupation');
    } else {
      setDisable(false);
    }
  };

  const delOccupation = async (id) => {
    const res = await deleteOccupation(id);
    setResult(res);
    if (res && !res.errors) {
      props.openAlert(
        t('message.delete-record-f', {param: t('word.occupation')}),
        'success',
      );
      history.replace('/occupation');
    }
  };

  return (
    <>
      <Card className="p-4 mb-4">
        {result.errors && <Error result={result} />}
        <form onSubmit={handleSubmit}>
          <Grid container direction="row" spacing={12}>
            <Grid container xs={12} sm={6}>
              <TextField
                className="m-2"
                label={t('word.name')}
                id="standard-size-small"
                size="small"
                required
                disabled={disable}
                value={occupation}
                onChange={(e) => setOccupation(e.target.value)}
                fullWidth
                inputProps={{maxLength: 128}}
              />
            </Grid>
          </Grid>
          <div style={{float: 'right'}}>
            <Button
              style={{textTransform: 'none'}}
              disabled={disable}
              onClick={() => history.replace('/occupation')}
              className="m-2"
              variant="contained"
              color="secondary">
              <ReplayIcon />
              {t('word.cancel')}
            </Button>

            <Delete func={delOccupation} id={props.id} disabled={disable} />

            <Button
              style={{textTransform: 'none'}}
              disabled={disable}
              type="submit"
              className="m-2"
              color="secondary"
              variant="contained">
              <CheckIcon />
              {t('word.save')}
            </Button>
          </div>
        </form>
      </Card>
    </>
  );
}
