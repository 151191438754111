const Enum = {
  schedule_status: {
    scheduled: 0,
    canceled: 1,
    inprogress: 2,
    finished: 3,
  },
  active: 1,
  inactive: 0,
  schedule_pms: {
    notintegrated: 0,
    integrated: 1,
  },
  arrPms: [1, 2],
  arrCancellation: [
    {
      value: 0,
      label: 'word.no-charge',
    },
    {
      value: 1,
      label: 'word.charge-percentage',
    },
    {
      value: 2,
      label: 'word.charge-value',
    },
  ],
  arrDisableCancellation: [0],
  arrPmsSchedule: [0, 1, 2],
  schedule_integrationpms: {
    finishedwithvalue: 0,
    canceledwithvalue: 1,
    noshowwithvalue: 2,
    finishednovalue: 3,
    cancelednovalue: 4,
    noshownovalue: 5,
    cancelnopolicy: 6,
    noshownopolicy: 7,
  },
  arrHaveCancelPolicy: [1, 2],
  activity_noshowpolicy: {
    nocharge: 0,
    chargepercentage: 1,
    chargevalue: 2,
  },
  activity_cancelpolicy: {
    nocharge: 0,
    chargepercentage: 1,
    chargevalue: 2,
  },
  arrHaveNoShowPolicy: [1, 2],
};

export default Enum;
