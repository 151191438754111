import React, {Fragment} from 'react';
import {useParams} from 'react-router';
import CardContainerForm from '../../components/CardContainerForm';
import Form from '../../components/forms/Activity';

export default function RegularTables1(props) {
  const {id} = useParams();

  return (
    <>
      <CardContainerForm>
        <Form
          idcompany={props.idcompany}
          companyConfigs={props.companyConfigs}
          primary={props.primary}
          setOp={props.setOp}
          openAlert={props.openAlert}
          id={id}
          pms={props.pms}
        />
      </CardContainerForm>
    </>
  );
}
