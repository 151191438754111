import React, {Fragment} from 'react';

import Calendar from '../../components/Calendar';

export default function ApplicationsCalendar(props) {
  return (
    <>
      <Calendar
        pms={props.pms}
        companyConfigs={props.companyConfigs}
        openAlert={props.openAlert}
        setOp={props.setOp}
      />
    </>
  );
}
