import React, {Fragment} from 'react';
import {useTranslation} from 'react-i18next';

import clsx from 'clsx';
import {Link} from 'react-router-dom';

import {IconButton, Box, Tooltip} from '@material-ui/core';

import {connect} from 'react-redux';

import MenuOpenRoundedIcon from '@material-ui/icons/MenuOpenRounded';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import {setSidebarToggleMobile as setSidebarToggleMobileAction} from 'store/reducers/ThemeOptions';

const SidebarHeader = (props) => {
  const {t} = useTranslation();
  const {
    sidebarToggleMobile,
    setSidebarToggleMobile,
    setSidebarToggle,
    sidebarToggle,
    sidebarHover,
  } = props;

  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };
  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };

  return (
    <>
      <div
        className={clsx('app-sidebar-header', {
          'app-sidebar-header-close': sidebarToggle && !sidebarHover,
        })}>
        <Box className="header-logo-wrapper" title={t('word.app-name')}>
          <Link to="/home" className="header-logo-wrapper-link">
            <IconButton
              style={{backgroundColor: 'white'}}
              size="medium"
              className="header-logo-wrapper-btn">
              <img
                className="app-sidebar-logo"
                alt={t('word.app-name')}
                src="fav.ico"
              />
            </IconButton>
          </Link>
          <Box className="header-logo-text">{t('word.app-name')}</Box>
        </Box>
        <Box
          className={clsx('app-sidebar-header-btn', {
            'app-sidebar-header-btn-close': sidebarToggle && !sidebarHover,
          })}>
          <Tooltip title={t('word.app-name')} placement="right">
            <IconButton color="inherit" onClick={toggleSidebar} size="medium">
              {sidebarToggle ? <MenuRoundedIcon /> : <MenuOpenRoundedIcon />}
            </IconButton>
          </Tooltip>
        </Box>
        <Box className="app-sidebar-header-btn-mobile">
          <Tooltip title={t('word.app-name')} placement="right">
            <IconButton
              color="inherit"
              onClick={toggleSidebarMobile}
              size="medium">
              {sidebarToggleMobile ? (
                <MenuOpenRoundedIcon />
              ) : (
                <MenuRoundedIcon />
              )}
            </IconButton>
          </Tooltip>
        </Box>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarHover: state.ThemeOptions.sidebarHover,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) =>
    dispatch(setSidebarToggleMobileAction(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarHeader);
