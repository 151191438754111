const INITIAL = {
  symbol: '',
};

export default function (state = INITIAL, action = {}) {
  switch (action.type) {
    case 'CURRENCY_SYMBOL':
      return {...state, symbol: action.payload.symbol};
    default:
      return state;
  }
}
