import React, {Fragment} from 'react';

import CardContainerForm from '../../components/CardContainerForm';
import Form from '../../components/forms/User';

export default function RegularTables1(props) {
  return (
    <>
      <CardContainerForm>
        <Form openAlert={props.openAlert} setOp={props.setOp} pms={props.pms} />
      </CardContainerForm>
    </>
  );
}
