import React, {Fragment} from 'react';
import clsx from 'clsx';
import {Link} from 'react-router-dom';
import {
  Hidden,
  IconButton,
  AppBar,
  Box,
  Tooltip,
  Button,
} from '@material-ui/core';
import {connect, useSelector} from 'react-redux';
import MenuOpenRoundedIcon from '@material-ui/icons/MenuOpenRounded';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import {useTranslation} from 'react-i18next';
import {
  setSidebarToggle as setSidebarToggleAction,
  setSidebarToggleMobile as setSidebarToggleMobileAction,
} from 'store/reducers/ThemeOptions';
import HeaderLogo from '../HeaderLogo';
import HeaderDots from '../HeaderDots';
import HeaderUserbox from '../HeaderUserbox';
import HeaderMenu from '../HeaderMenu';

const Header = (props) => {
  const {t} = useTranslation();
  const {
    headerShadow,
    headerFixed,
    sidebarToggleMobile,
    setSidebarToggleMobile,
    setSidebarToggle,
    sidebarToggle,
  } = props;

  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };

  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };

  const result = useSelector((state) => state.page.name);
  return (
    <>
      <AppBar
        color="secondary"
        className={clsx('app-header', {
          'app-header-collapsed-sidebar': props.isCollapsedLayout,
        })}
        position={headerFixed ? 'fixed' : 'absolute'}
        elevation={headerShadow ? 11 : 3}>
        {!props.isCollapsedLayout && <HeaderLogo />}
        <Box className="app-header-toolbar">
          <Hidden lgUp>
            <Box className="app-logo-wrapper" title={t('word.app-name')}>
              <Link to="/home" className="app-logo-link">
                <IconButton
                  style={{backgroundColor: 'white'}}
                  color="primary"
                  size="medium"
                  className="app-logo-btn">
                  <img
                    className="app-logo-img"
                    alt={t('word.app-name')}
                    src="fav.ico"
                  />
                </IconButton>
              </Link>
              {/* <Box className="header-logo-text">{result}</Box> */}
              <div className="header-logo-text">
                <Button
                  size="medium"
                  color="inherit"
                  // onClick={handleClick}
                  style={{cursor: 'default'}}
                  className="btn-inverse font-size-xs mx-2">
                  {result}
                </Button>
              </div>
            </Box>
          </Hidden>
          <Hidden mdDown>
            <Box className="d-flex align-items-center">
              {!props.isCollapsedLayout && (
                <Box
                  className={clsx('btn-toggle-collapse', {
                    'btn-toggle-collapse-closed': sidebarToggle,
                  })}>
                  <Tooltip title={t('word.menu')} placement="right">
                    <IconButton
                      color="inherit"
                      onClick={toggleSidebar}
                      size="medium"
                      className="btn-inverse">
                      {sidebarToggle ? (
                        <MenuRoundedIcon />
                      ) : (
                        <MenuOpenRoundedIcon />
                      )}
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
              <HeaderMenu isCollapsedLayout={props.isCollapsedLayout} />
            </Box>
          </Hidden>
          <Box className="d-flex align-items-center">
            <HeaderDots pms={props.pms} configs={props.configs} />
            <HeaderUserbox />
            <Box className="toggle-sidebar-btn-mobile">
              <Tooltip title={t('word.menu')} placement="right">
                <IconButton
                  color="inherit"
                  onClick={toggleSidebarMobile}
                  size="medium">
                  {sidebarToggleMobile ? (
                    <MenuOpenRoundedIcon />
                  ) : (
                    <MenuRoundedIcon />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Box>
      </AppBar>
    </>
  );
};

const mapStateToProps = (state) => ({
  headerShadow: state.ThemeOptions.headerShadow,
  headerFixed: state.ThemeOptions.headerFixed,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  sidebarToggle: state.ThemeOptions.sidebarToggle,
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggle: (enable) => dispatch(setSidebarToggleAction(enable)),
  setSidebarToggleMobile: (enable) =>
    dispatch(setSidebarToggleMobileAction(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
