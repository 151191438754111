import React, {Fragment, useEffect, useState} from 'react';
import {
  getUserDsl,
  addUserDsl,
  editUserDsl,
  deleteUserDsl,
} from 'helpers/apiDsl';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
  Button,
  Card,
  Switch,
  FormControlLabel,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ReplayIcon from '@material-ui/icons/Replay';
import {useTranslation} from 'react-i18next';
import Delete from '../../ButtonDelete';
import Error from '../../Error';

export default function ModalUserDsl(props) {
  const {modalOpenUserDsl, setModalOpenUserDsl} = props;
  const {t} = useTranslation();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [status, setStatus] = useState(true);
  const [result, setResult] = useState({});
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    if (props.id) {
      const getOne = async (id) => {
        const res = await getUserDsl(id);
        if (res.errors) {
          props.openAlert('Usuário Desbravador não encontrado', 'error');
          window.location.href = `/desbravador`;
        } else {
          setName(res.name);
          setEmail(res.email);
          setStatus(res.active);
        }
      };
      getOne(props.id);
    } else {
      setResult({});
      setName('');
      setEmail('');
      setStatus('');
      setPassword('');
    }
    // eslint-disable-next-line
  }, [props.id]);

  const closeModal = () => {
    setModalOpenUserDsl(false);
    setResult({});
  };

  const handleSubmit = async (e) => {
    props.setOp(true);
    setResult({});
    e.preventDefault();
    setDisable(true);

    let res;

    if (props.id) {
      res = await editUserDsl(props.id, name, email, status);
      setResult(res);
    } else {
      res = await addUserDsl(name, email, password);
      setResult(res);
    }
    if (res && !res.errors) {
      setModalOpenUserDsl(false);
      setDisable(false);
      if (props.id) {
        props.openAlert('Usuário Desbravador editado com sucesso', 'success');
      } else {
        props.openAlert(
          'Usuário Desbravador cadastrado com sucesso',
          'success',
        );
      }
      props.getAllUsersDsl();
    } else {
      setDisable(false);
    }
    props.setOp(false);
  };

  const delUserDsl = async (id) => {
    const res = await deleteUserDsl(id);
    setResult(res);
    if (res && !res.errors) {
      props.openAlert('Usuário Desbravador excluído com sucesso', 'success');
      setModalOpenUserDsl(false);
      props.getAllUsersDsl();
    }
  };

  return (
    <>
      <Dialog
        open={modalOpenUserDsl}
        onClose={closeModal}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{t('word.userdsl')}</DialogTitle>
        <DialogContent>
          <Card className="p-4 mb-4">
            {result.errors && <Error result={result} />}
            <form onSubmit={handleSubmit}>
              <Grid container direction="row">
                <Grid item sm={4}>
                  <TextField
                    className="m-2"
                    label={t('word.name')}
                    required
                    disabled={disable}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    inputProps={{maxLength: 128}}
                  />
                </Grid>
                <Grid item sm={4}>
                  <TextField
                    className="m-2"
                    label={t('word.email')}
                    required
                    disabled={disable}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    inputProps={{maxLength: 128}}
                  />
                </Grid>
                {!props.id && (
                  <Grid item sm={4}>
                    <TextField
                      className="m-2"
                      type="password"
                      label={t('word.password')}
                      required
                      disabled={disable}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      inputProps={{maxLength: 64}}
                    />
                  </Grid>
                )}
                {props.id && (
                  <Grid item sm={4}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={status}
                          onChange={() => setStatus(!status)}
                          value={status}
                          disabled={disable}
                          color="secondary"
                        />
                      }
                      label={status ? t('word.active') : t('word.inactive')}
                    />
                  </Grid>
                )}
              </Grid>
              <div style={{float: 'right'}}>
                <Button
                  style={{textTransform: 'none'}}
                  disabled={disable}
                  onClick={closeModal}
                  className="m-2"
                  variant="contained"
                  color="secondary">
                  <ReplayIcon />
                  {t('word.cancel')}
                </Button>

                <Delete func={delUserDsl} id={props.id} disabled={disable} />

                <Button
                  style={{textTransform: 'none'}}
                  disabled={disable}
                  type="submit"
                  className="m-2"
                  color="secondary"
                  variant="contained">
                  <CheckIcon />
                  {t('word.save')}
                </Button>
              </div>
            </form>
          </Card>
        </DialogContent>
      </Dialog>
    </>
  );
}
