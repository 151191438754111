import React, {Fragment, useState, useEffect} from 'react';
import {
  Grid,
  Container,
  Input,
  InputLabel,
  InputAdornment,
  Button,
  FormControl,
} from '@material-ui/core';
import MailOutlineTwoToneIcon from '@material-ui/icons/MailOutlineTwoTone';
import LockTwoToneIcon from '@material-ui/icons/LockTwoTone';
import {Login, verifyLogin} from 'helpers/api'; // ping
import {doLogin, setCompany} from 'helpers/auth';
import {useParams, useHistory} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import Cookies from 'js-cookie';
import {Nome} from 'store/reducers/Nome/nomeAction';
import {Occupation} from 'store/reducers/Occupation/occupationAction';
import {imgUser} from 'store/reducers/Img/imgAction';
import Error from '../../components/Error';

export default function PagesLogin({observer, setObserver}) {
  const dispatch = useDispatch();
  const history = useHistory();

  const {t, i18n} = useTranslation();
  const {id} = useParams();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [result, setResult] = useState({});

  useEffect(() => {
    const verify = async () => {
      const token = Cookies.get('token');
      if (token) {
        const res = await verifyLogin();
        if (res.token === token) {
          history.replace('/home');
        }
      }
    };
    verify();
    setCompany(id);
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisabled(true);

    const res = await Login(email, password, id);
    i18n.changeLanguage(res.language);

    setResult(res);

    if (res && !res.errors) {
      Cookies.set('iduser', res.iduser);
      doLogin(res.token);
      dispatch(Nome(res.name));
      dispatch(Occupation(res.occupation));
      dispatch(imgUser(res.photo, res.mimetype));
      setObserver(!observer);
      history.push('/home');
    } else {
      setDisabled(false);
    }
  };

  function getWindowDimensions() {
    const {innerWidth: width, innerHeight: height} = window;
    return {
      width,
      height,
    };
  }

  // const handlePing = async (e) =>{
  //   let res = await ping()
  //   alert(res)
  //   console.log(res)
  // }

  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions(),
    );

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
  }
  const {width} = useWindowDimensions();
  return (
    <>
      <div
        className="app-wrapper min-vh-100 bg-white"
        style={{
          backgroundImage:
            width > 504
              ? 'url("easy-planner-background-login.svg")'
              : 'url("easy-planner-background-login-mobile.svg")',
          backgroundRepeat: 'no-repeat',
        }}>
        <div className="app-main min-vh-100">
          <div className="app-content p-0">
            <div className="app-inner-content-layout--main">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div className="bg-composed-wrapper--content">
                  <Grid container spacing={0} className="min-vh-100">
                    <Grid item xs={12} md={8} lg={7}>
                      {width > 504 && (
                        <img
                          src="easy-planner-login-girl.svg"
                          alt="..."
                          style={{
                            maxWidth: '450px',
                            position: 'absolute',
                            top: '30%',
                            left: '35%',
                          }}
                        />
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={4}
                      lg={3}
                      className="d-flex align-items-center">
                      <Container maxWidth="sm">
                        <Grid style={{textAlign: 'center'}}>
                          <img
                            src="easy-planner-logo.png"
                            alt="..."
                            style={{maxWidth: '250px'}}
                          />
                          <Grid item md={12} className="mb-3 p-0 border-0">
                            {result.errors && <Error result={result} />}
                            <form onSubmit={handleSubmit}>
                              <FormControl style={{width: '300px'}}>
                                <InputLabel htmlFor="input-with-icon-adornment">
                                  {t('word.email')}
                                </InputLabel>
                                <Input
                                  fullWidth
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                  id="input-with-icon-adornment"
                                  startAdornment={
                                    <InputAdornment position="start">
                                      <MailOutlineTwoToneIcon />
                                    </InputAdornment>
                                  }
                                />
                              </FormControl>
                              <FormControl style={{width: '300px'}}>
                                <InputLabel htmlFor="standard-adornment-password">
                                  {t('word.password')}
                                </InputLabel>
                                <Input
                                  id="standard-adornment-password"
                                  fullWidth
                                  value={password}
                                  onChange={(e) => setPassword(e.target.value)}
                                  type="password"
                                  startAdornment={
                                    <InputAdornment position="start">
                                      <LockTwoToneIcon />
                                    </InputAdornment>
                                  }
                                />
                              </FormControl>
                              <div className="text-center">
                                <Button
                                  color="primary"
                                  disabled={disabled}
                                  type="submit"
                                  variant="contained"
                                  style={{width: '300px', height: '35px'}}
                                  size="large"
                                  className="my-2">
                                  {t('word.signin')}
                                </Button>
                              </div>
                            </form>
                            {/* <div className="text-center">
                                <Button
                                  color="primary"
                                  disabled={disabled}
                                  type='submit'
                                  variant="contained"
                                  onClick={(e) => handlePing(e)}
                                  style={{width: '300px', height: '35px'}}
                                  size="large"
                                  className="my-2">
                                  {t('PING!')}
                                </Button>
                              </div> */}
                          </Grid>
                          <img
                            src="desbravador-logo.svg"
                            alt="..."
                            style={{maxWidth: '150px'}}
                          />
                        </Grid>
                      </Container>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
