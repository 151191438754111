import React, {Fragment} from 'react';
import CardContainerForm from '../../components/CardContainerForm';
import Form from '../../components/forms/Activity';

export default function RegularTables1(props) {
  return (
    <>
      <CardContainerForm>
        <Form
          setOp={props.setOp}
          primary={props.primary}
          companyConfigs={props.companyConfigs}
          openAlert={props.openAlert}
          pms={props.pms}
        />
      </CardContainerForm>
    </>
  );
}
