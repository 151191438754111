import React from 'react';

import {Card, CardContent} from '@material-ui/core';

export default function CardContainer({children}) {
  return (
    <Card className="card-box mb-4-spacing overflow-visible">
      <CardContent className="p-3">{children}</CardContent>
    </Card>
  );
}
