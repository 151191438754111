import React, {useState} from 'react';
import {Dialog, Button, Grid} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {useTranslation} from 'react-i18next';
import {FaExclamation} from 'react-icons/fa';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  error: {
    margin: '10px 0px',
    backgroundColor: '#F2DEDE',
    color: '#A94442',
    border: '1px solid #EBCCD1',
    padding: '10px',
  },
}));

export default function ModalView(props) {
  const classes = useStyles();
  const [optDev, setOptDev] = useState(false);
  const {t} = useTranslation();
  const close = () => {
    props.setModalPms(!props.setModalPms);
    setOptDev(false);
  };

  return (
    <>
      <Dialog open={props.modalPms} onClose={close}>
        <div className="text-center p-5">
          <div className="avatar-icon-wraper rounded-circle m-0">
            <div
              style={{fontSize: '40px'}}
              className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-warning text-warning m-0 d-130">
              <span>
                <FaExclamation className="font-size-auto mx-auto" />
              </span>
            </div>
          </div>
          <h4 className="font-weight-bold mt-4">
            {t('message.err-integration-throw-itens')}
          </h4>
          {props.pms &&
            props.pms.length !== 0 &&
            props.pms.map((i) => (
              <p className="mb-0 font-size-lg text-muted text-black-50">
                {i.code ? (
                  <div key={i.code}>
                    {t(`message.${i.code}`, {param: t(`word.${i.field}`)})}
                  </div>
                ) : (
                  <div key={i.mensagem}>{t(`message.${i.mensagem}`)}</div>
                )}
              </p>
            ))}
          {optDev === true && (
            <Grid
              className={classes.error}
              container
              direction="column"
              spacing={12}>
              {optDev === true &&
                props.pms &&
                props.pms.length !== 0 &&
                props.pms.map((i) => (
                  <>{i.details && <div key={i.details}>{i.details}</div>}</>
                ))}
            </Grid>
          )}
          <div
            className="pt-4"
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Button
              style={{height: '40px'}}
              onClick={close}
              variant="contained"
              color="primary"
              className="mx-1">
              <span className="btn-wrapper--label">{t('word.back')}</span>
            </Button>
            {optDev === false && (
              <Button
                style={{textAlign: 'justify', float: 'right'}}
                color="primary"
                size="small"
                onClick={() => setOptDev(true)}>
                {t('word.more-details')}
              </Button>
            )}
            {optDev === true && (
              <Button
                style={{textAlign: 'justify', float: 'right'}}
                color="primary"
                size="small"
                onClick={() => setOptDev(false)}>
                {t('word.less-details')}
              </Button>
            )}
          </div>
          {/* <Button
              size="small"
              variant="outlined"
              color="primary"
              className="mr-3"
              style={{textAlign: 'justify', float: 'right'}}>
              Today
            </Button> */}
        </div>
      </Dialog>
    </>
  );
}
