import React, {Fragment, useState} from 'react';
import {Dialog, Grid, TextField, Button} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {FaCheck, FaPlay, FaTimes} from 'react-icons/fa';
import Enum from 'helpers/enum';
import PerfectScrollbar from 'react-perfect-scrollbar';

export default function ModalScheduleList(props) {
  const {t} = useTranslation();

  const [reason, setReason] = useState('');

  const {status, op, setOp, disable} = props;

  const handleClose = () => {
    if (disable === false) {
      setOp(false);
      setReason('');
    }
  };

  const handleSubmit = () => {
    props.handleSchedule(status, reason);
  };

  return (
    <>
      <Dialog open={op} onClose={handleClose}>
        <PerfectScrollbar>
          <div className="text-center p-5">
            {status === Enum.schedule_status.canceled && (
              <>
                <div className="avatar-icon-wraper rounded-circle m-0">
                  <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-danger text-danger m-0 d-130">
                    <span>
                      <FaTimes className="font-size-auto mx-auto" />
                    </span>
                  </div>
                </div>
                <h4 className="font-weight-bold mt-4">
                  {t('message.schedule-cancel-all')}
                </h4>
              </>
            )}
            {status === Enum.schedule_status.inprogress && (
              <>
                <div className="avatar-icon-wraper rounded-circle m-0">
                  <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-primary text-primary m-0 d-130">
                    <span>
                      <FaPlay className="font-size-auto mx-auto" />
                    </span>
                  </div>
                </div>
                <h4 className="font-weight-bold mt-4">
                  {t('message.schedule-start-all')}
                </h4>
              </>
            )}
            {status === Enum.schedule_status.finished && (
              <>
                <div className="avatar-icon-wraper rounded-circle m-0">
                  <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-success text-success m-0 d-130">
                    <span>
                      <FaCheck className="font-size-auto mx-auto" />
                    </span>
                  </div>
                </div>
                <h4 className="font-weight-bold mt-4">
                  {t('message.schedule-finish-all')}
                </h4>
              </>
            )}
            <p className="mb-0 font-size-lg text-muted text-black-50">
              {props.user.join(', ')}
            </p>
            {status === Enum.schedule_status.canceled && (
              <Grid container align="left">
                <TextField
                  label={t('message.schedule-cancel-reason')}
                  id="standard-size-small"
                  className="mt-4"
                  size="small"
                  required
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  fullWidth
                />
              </Grid>
            )}
          </div>
        </PerfectScrollbar>
        <div style={{paddingBottom: '20px'}} className="text-center">
          <Button
            onClick={handleClose}
            style={{height: '40px'}}
            variant="contained"
            disabled={disable}
            className="mx-1">
            <span className="btn-wrapper--label">{t('word.no')}</span>
          </Button>
          <Button
            type="submit"
            style={{height: '40px'}}
            color="primary"
            variant="contained"
            className="mx-1"
            disabled={disable}
            onClick={() => handleSubmit()}>
            <span className="btn-wrapper--label">{t('word.yes')}</span>
          </Button>
        </div>
      </Dialog>
    </>
  );
}
