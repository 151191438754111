/* eslint-disable no-restricted-syntax */
import React, {Fragment, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {getOnePoS, addPoS, editPoS, deletePoS} from 'helpers/api';
import {Grid, Button, Card, TextField} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ReplayIcon from '@material-ui/icons/Replay';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Page} from 'store/reducers/Page/pageAction';
import Delete from '../../ButtonDelete/index';
import Error from '../../Error';
import Enum from '../../../helpers/enum';

export default function Formulario(props) {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const history = useHistory();

  const [placeofsale, setPoS] = useState('');
  const [idplaceofsalepms, setIdPoS] = useState();
  const [result, setResult] = useState({});
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    dispatch(Page(t('word.placeofsale')));
    if (props.id) {
      const getOne = async (id) => {
        const res = await getOnePoS(id);
        if (res.errors) {
          for (const i of res.errors) {
            props.openAlert(
              t(`message.${i.code}`, {param: t(`word.${i.field}`)}),
              i.color,
            );
          }
          history.replace('/placeofsale');
        } else {
          setPoS(res.placeofsale);
          setIdPoS(res.idplaceofsalepms);
        }
      };
      getOne(props.id);
    }
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisable(true);
    let res;
    if (props.id) {
      res = await editPoS(props.id, placeofsale, idplaceofsalepms);
      setResult(res);
    } else {
      res = await addPoS(placeofsale, idplaceofsalepms);
      setResult(res);
    }

    if (res && !res.errors) {
      if (props.id) {
        props.openAlert(
          t('message.edit-record', {param: t('word.placeofsale')}),
          'success',
        );
      } else {
        props.openAlert(
          t('message.insert-record', {param: t('word.placeofsale')}),
          'success',
        );
      }
      history.replace('/placeofsale');
    } else {
      setDisable(false);
    }
  };

  const delPos = async (id) => {
    const res = await deletePoS(id);
    setResult(res);
    if (res && !res.errors) {
      props.openAlert(
        t('message.delete-record', {param: t('word.placeofsale')}),
        'success',
      );
      history.replace('/placeofsale');
    }
  };

  return (
    <>
      <Card className="p-4 mb-4">
        {result.errors && <Error result={result} />}
        <form onSubmit={handleSubmit}>
          <Grid container spacing={12}>
            <Grid container xs={12} sm={12} md={12} lg={12}>
              <TextField
                label={t('word.placeofsale')}
                id="standard-size-small"
                size="small"
                required
                disabled={disable}
                value={placeofsale}
                onChange={(e) => setPoS(e.target.value)}
                inputProps={{maxLength: 256}}
                fullWidth
              />
            </Grid>
            {Enum.arrPms.includes(props.pms) && (
              <Grid container xs={12} sm={6} md={6} lg={4}>
                <TextField
                  label={t('word.idplaceofsalepms')}
                  id="standard-size-small"
                  size="small"
                  type="number"
                  required
                  disabled={disable}
                  value={parseInt(idplaceofsalepms)}
                  inputProps={{min: 0}}
                  onChange={(e) => setIdPoS(e.target.value)}
                  fullWidth
                />
              </Grid>
            )}
          </Grid>
          <div style={{float: 'right'}}>
            <Button
              style={{textTransform: 'none'}}
              disabled={disable}
              onClick={() => history.replace('/placeofsale')}
              className="m-2"
              variant="contained"
              color="secondary">
              <ReplayIcon />
              {t('word.cancel')}
            </Button>

            <Delete func={delPos} id={props.id} disabled={disable} />

            <Button
              style={{textTransform: 'none'}}
              disabled={disable}
              type="submit"
              className="m-2"
              color="secondary"
              variant="contained">
              <CheckIcon />
              {t('word.save')}
            </Button>
          </div>
        </form>
      </Card>
    </>
  );
}
