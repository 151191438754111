/* eslint-disable no-underscore-dangle */
import React from 'react';
import {useTranslation} from 'react-i18next';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
// import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list';
import esLocale from '@fullcalendar/core/locales/es';
import ptLocale from '@fullcalendar/core/locales/pt-br';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import {Grid, Card, CardContent, Divider} from '@material-ui/core';
import ReactDOMServer from 'react-dom/server';
import moment from 'moment';
import Enum from 'helpers/enum';
import Place from '@material-ui/icons/Place';
import AccessTime from '@material-ui/icons/AccessTime';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

const Tooltip = (starttime, endtime, person, i, t, currencysymbol) => (
  <Grid item style={{alignItems: 'left'}}>
    <Card>
      <div style={{textAlign: 'right'}} className="card-badges">
        <span
          style={{marginBottom: '5px'}}
          className={`h-auto px-3 py-1 badge badge-${t(
            `word.color-material.${i.status}`,
          )} badge-pill`}>
          {t(`word.schedule-status.${i.status}`)}
        </span>
        <br />
        <span
          style={{marginBottom: '5px', backgroundColor: 'white'}}
          className="h-auto px-1 py-1 badge badge badge-pill">
          &nbsp;&nbsp; {i['activity.place']} &nbsp;
          <Place fontSize="small" />
        </span>
        <br />
        <span
          style={{marginBottom: '5px', backgroundColor: 'white'}}
          className="h-auto px-1 py-1 badge badge badge-pill">
          &nbsp;&nbsp; {`${i['activity.duration']}  ${t('word.minutes')}`}{' '}
          &nbsp;
          <AccessTime fontSize="small" />
          &nbsp;
        </span>
        <br />
        <span
          style={{marginBottom: '5px', backgroundColor: 'white'}}
          className="h-auto px-1 py-1 badge badge badge-pill">
          &nbsp;&nbsp;
          {i.price <= 0 && (
            <>
              {t('word.free')} &nbsp;
              <CheckCircleOutlineIcon fontSize="small" /> &nbsp;
            </>
          )}
          {i.price > 0 && (
            <>
              {`${currencysymbol} ${i.price}`} &nbsp;
              <MonetizationOnIcon fontSize="small" /> &nbsp;
            </>
          )}
        </span>
      </div>
      <div className="image-title-overlay">
        <div className="image-title-overlay--bottom">
          <h3 className="display-4 font-weight-bold m-0 text-white">
            {moment(starttime).utc(false).format('HH:mm')} {t('word.time')}{' '}
            {moment(endtime).utc(false).format('HH:mm')}
          </h3>
          <h3 className="display-4 font-size-xs m-0 text-white">
            {i[`activity.activity`]}
          </h3>
        </div>
        {i.status === Enum.schedule_status.scheduled && (
          <img
            style={{marginTop: '-3px'}}
            alt="activity"
            className="card-img-top"
            src={
              i['activity.photo']
                ? `data:${i['activity.mimetype']};base64,${i['activity.photo']}`
                : 'easy-planner-default-no-image.png'
            }
          />
        )}
        {(i.status === Enum.schedule_status.inprogress ||
          i.status === Enum.schedule_status.finished) && (
          <img
            style={{marginTop: '-3px', opacity: 0.5}}
            alt="activity"
            className="card-img-top"
            src={
              i['activity.photo']
                ? `data:${i['activity.mimetype']};base64,${i['activity.photo']}`
                : 'easy-planner-default-no-image.png'
            }
          />
        )}
        {i.status === Enum.schedule_status.canceled && (
          <img
            style={{marginTop: '-3px', filter: 'grayscale(1)', opacity: 0.6}}
            alt="activity"
            className="card-img-top"
            src={
              i['activity.photo']
                ? `data:${i['activity.mimetype']};base64,${i['activity.photo']}`
                : 'easy-planner-default-no-image.png'
            }
          />
        )}
      </div>

      <CardContent className="p-3">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex">
            <div>
              <div className="text-black-50 text-size-xs">
                {i[`professional.user.occupation.occupation`]}
              </div>
              <b>{i[`professional.user.name`]}</b>
            </div>
          </div>
          <div className="font-weight-bold text-danger font-size-lg" />
        </div>
        <Divider className="my-3" />
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex">
            <div>
              <div className="text-black-50 text-size-xs">
                {t('word.participant')}
              </div>
              <b>{person}</b>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  </Grid>
);

const Func = React.memo((props) => {
  const {t, i18n} = useTranslation();
  const handleMouseEnter = (arg) => {
    tippy(arg.el, {
      allowHTML: true,
      content: ReactDOMServer.renderToString(
        Tooltip(
          arg.event._instance.range.start,
          arg.event._instance.range.end,
          arg.event._def.title,
          arg.event.extendedProps,
          t,
          props.companyConfigs.currencysymbol,
        ),
      ),
      appendTo: document.body,
      theme: 'light',
      placement: 'auto',
      onUntrigger(instance) {
        instance.destroy();
      },
    });
  };

  return (
    <FullCalendar
      plugins={[dayGridPlugin, listPlugin]} // timeGridPlugin
      locales={[esLocale, ptLocale]}
      eventTextColor="black"
      initialView="dayGridMonth"
      locale={i18n.language}
      showNonCurrentDates={false}
      events={props.events}
      dayMaxEvents={5}
      eventMouseEnter={(arg) => handleMouseEnter(arg)}
      // eventContent={(arg) => renderEventContent(arg)}
      headerToolbar={{
        left: 'prev,next,today',
        center: 'title',
        right: 'dayGridMonth,listWeek', // ,timeGridPlugin
      }}
    />
  );
});

// function renderEventContent(eventInfo) {
//   return (
// <>
//   <b>{eventInfo.timeText}</b>
//   <i>{eventInfo.event.title}</i>
// </>
//   )
// }

export default Func;
