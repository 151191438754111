import React, {useState} from 'react';
import {FaPlus} from 'react-icons/fa';
import {
  Fab,
  Grid,
  Container,
  Input,
  InputLabel,
  InputAdornment,
  Card,
  CardContent,
  Button,
  FormControl,
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import {makeStyles} from '@material-ui/core/styles';
import MailOutlineTwoToneIcon from '@material-ui/icons/MailOutlineTwoTone';
import LockTwoToneIcon from '@material-ui/icons/LockTwoTone';
import {LoginDsl, getCompanies, getUsersDsl} from 'helpers/apiDsl';
import {doLoginDsl} from 'helpers/authDsl';
import {useTranslation} from 'react-i18next';
import CardContainer from '../../components/CardContainer';
import ModalUserDsl from '../../components/modals/UserDsl';
import ModalCompany from '../../components/modals/Company';
import Error from '../../components/Error';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tablerow: {
    cursor: 'pointer',
    transition: 'all ease 0.2s',
    '&:hover': {
      backgroundColor: '#CCC',
    },
  },
}));

export default function PagesLoginDsl(props) {
  const classes = useStyles();

  const {t} = useTranslation();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [result, setResult] = useState({});
  const [logged, setLogged] = useState(false);
  const [company, setCompany] = useState([]);
  const [filteredCompany, setFilteredCompany] = useState(company);
  const [userDsl, setUserDsl] = useState([]);
  const [filteredUserDsl, setFilteredUserDsl] = useState(userDsl);
  const [modalOpenCompany, setModalOpenCompany] = useState(false);
  const [modalOpenUserDsl, setModalOpenUserDsl] = useState(false);
  const [idCompany, setIdCompany] = useState();
  const [idUserDsl, setIdUserDsl] = useState();

  const getAllCompanies = async () => {
    const json = await getCompanies();
    setCompany(json);
    setFilteredCompany(json);
  };

  const getAllUsersDsl = async () => {
    const json = await getUsersDsl();
    setUserDsl(json);
    setFilteredUserDsl(json);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisabled(true);

    const res = await LoginDsl(email, password);
    setResult(res);

    if (res && !res.errors) {
      doLoginDsl(res.token);
      setLogged(true);
      getAllCompanies();
      getAllUsersDsl();
    } else {
      setDisabled(false);
    }
  };

  const handleSearchCompany = (e) => {
    const value = e.target.value.toLowerCase();
    let resultFilter = [];
    resultFilter = company.filter(
      (row) =>
        row.company.toLowerCase().search(value) !== -1 ||
        row.idcompany.search(value) !== -1 ||
        row.corporatename.search(value) !== -1,
    );
    setFilteredCompany(resultFilter);
  };

  const handleSearchUserDsl = (e) => {
    const value = e.target.value.toLowerCase();
    let resultSearch = [];
    resultSearch = userDsl.filter(
      (row) =>
        row.name.toLowerCase().search(value) !== -1 ||
        row.email.search(value) !== -1,
    );
    setFilteredUserDsl(resultSearch);
  };

  const handleEditCompany = (id) => {
    setIdCompany(id);
    setModalOpenCompany(true);
  };

  const handleEditUserDsl = (id) => {
    setIdUserDsl(id);
    setModalOpenUserDsl(true);
  };

  const handleAddCompany = () => {
    setIdCompany('');
    setModalOpenCompany(true);
  };

  const handleAddUserDsl = () => {
    setIdUserDsl('');
    setModalOpenUserDsl(true);
  };

  return (
    <>
      {!logged && (
        <>
          <div className="app-wrapper min-vh-100">
            <div className="app-main flex-column">
              <div className="app-content p-0">
                <div className="app-content--inner d-flex align-items-center">
                  <div className="flex-grow-1 w-100 d-flex align-items-center">
                    <div className="bg-composed-wrapper--content py-5">
                      <Container>
                        <Grid container spacing={10}>
                          <Grid
                            item
                            xs={12}
                            lg={7}
                            className="d-flex flex-column align-items-center">
                            <span className="w-100 text-left text-md-center pb-4">
                              <h1 className="display-3 text-xl-left text-center mb-3 font-weight-bold">
                                {t('word.login')} (Desbravador)
                              </h1>
                            </span>
                            <Card className="m-0 w-100 p-0 border-0">
                              <CardContent className="p-3">
                                {result.errors && <Error result={result} />}
                                <form className="px-5" onSubmit={handleSubmit}>
                                  <div className="mb-3">
                                    <FormControl className="w-100">
                                      <InputLabel htmlFor="input-with-icon-adornment">
                                        {t('word.email')}
                                      </InputLabel>
                                      <Input
                                        fullWidth
                                        value={email}
                                        onChange={(e) =>
                                          setEmail(e.target.value)
                                        }
                                        id="input-with-icon-adornment"
                                        startAdornment={
                                          <InputAdornment position="start">
                                            <MailOutlineTwoToneIcon />
                                          </InputAdornment>
                                        }
                                      />
                                    </FormControl>
                                  </div>
                                  <div className="mb-3">
                                    <FormControl className="w-100">
                                      <InputLabel htmlFor="standard-adornment-password">
                                        {t('word.password')}
                                      </InputLabel>
                                      <Input
                                        id="standard-adornment-password"
                                        fullWidth
                                        value={password}
                                        onChange={(e) =>
                                          setPassword(e.target.value)
                                        }
                                        type="password"
                                        startAdornment={
                                          <InputAdornment position="start">
                                            <LockTwoToneIcon />
                                          </InputAdornment>
                                        }
                                      />
                                    </FormControl>
                                  </div>
                                  <div className="text-center">
                                    <Button
                                      color="primary"
                                      disabled={disabled}
                                      type="submit"
                                      variant="contained"
                                      size="large"
                                      className="my-2">
                                      {t('word.signin')}
                                    </Button>
                                  </div>
                                </form>
                              </CardContent>
                            </Card>
                          </Grid>
                        </Grid>
                      </Container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {logged && (
        <>
          <Grid container justify="center">
            <Grid xs={11} lg={9}>
              <Grid xs={11} lg={9} align="left">
                <h1>Licenciamento</h1>
              </Grid>
              <ModalCompany
                openAlert={props.openAlert}
                setOp={props.setOp}
                getAllCompanies={getAllCompanies}
                modalOpenCompany={modalOpenCompany}
                id={idCompany}
                setModalOpenCompany={setModalOpenCompany}
              />
              <CardContainer>
                <div className={classes.root}>
                  <div className="card-header">
                    <div>
                      <h3>Empresas</h3>
                    </div>
                    <FormControl className="w-100 m-2">
                      <InputLabel htmlFor="input-with-icon-adornment">
                        Pesquisar
                      </InputLabel>
                      <Input
                        onChange={(event) => handleSearchCompany(event)}
                        startAdornment={
                          <InputAdornment>
                            <SearchIcon />
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                    <div>
                      <Fab
                        onClick={() => handleAddCompany()}
                        size="small"
                        color="secondary">
                        <FaPlus />
                      </Fab>
                    </div>
                  </div>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell width="33%">Série</TableCell>
                          <TableCell width="33%">Razão</TableCell>
                          <TableCell width="34%">Fantasia</TableCell>
                          <TableCell align="right">Status</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filteredCompany &&
                          filteredCompany.map((i) => (
                            <TableRow
                              key={i.idcompany}
                              className={classes.tablerow}
                              onClick={() => handleEditCompany(i.idcompany)}>
                              <TableCell width="33%">{i.idcompany}</TableCell>
                              <TableCell width="33%">{i.company}</TableCell>
                              <TableCell width="34%">
                                {i.corporatename}
                              </TableCell>
                              <TableCell align="right">
                                {i.active ? 'Ativo' : 'Inativo'}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </CardContainer>
            </Grid>

            <Grid xs={11} lg={9}>
              <ModalUserDsl
                openAlert={props.openAlert}
                setOp={props.setOp}
                getAllUsersDsl={getAllUsersDsl}
                modalOpenUserDsl={modalOpenUserDsl}
                id={idUserDsl}
                setModalOpenUserDsl={setModalOpenUserDsl}
              />
              <CardContainer>
                <div className={classes.root}>
                  <div className="card-header">
                    <div>
                      <h3>Usuários</h3>
                    </div>
                    <FormControl className="w-100 m-2">
                      <InputLabel htmlFor="input-with-icon-adornment">
                        Pesquisar
                      </InputLabel>
                      <Input
                        onChange={(event) => handleSearchUserDsl(event)}
                        startAdornment={
                          <InputAdornment>
                            <SearchIcon />
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                    <div>
                      <Fab
                        onClick={() => handleAddUserDsl()}
                        size="small"
                        color="secondary">
                        <FaPlus />
                      </Fab>
                    </div>
                  </div>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell width="50%">Nome</TableCell>
                          <TableCell width="50%">E-mail</TableCell>
                          <TableCell align="right">Status</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filteredUserDsl &&
                          filteredUserDsl.map((i) => (
                            <TableRow
                              key={i.iduserdsl}
                              className={classes.tablerow}
                              onClick={() => handleEditUserDsl(i.iduserdsl)}>
                              <TableCell width="50%">{i.name}</TableCell>
                              <TableCell width="50%">{i.email}</TableCell>
                              <TableCell align="right">
                                {i.active ? 'Ativo' : 'Inativo'}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </CardContainer>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}
