const INITIAL = {
  color: 'success',
};

export default function (state = INITIAL, action = {}) {
  switch (action.type) {
    case 'COLOR_MESSAGE':
      return {...state, color: action.payload.color};
    default:
      return state;
  }
}
