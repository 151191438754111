import React, {Fragment} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Header, SidebarCollapsed} from '../../layout-components';

const CollapsedSidebar = (props) => {
  const {children, contentBackground} = props;

  return (
    <>
      <div className={clsx('app-wrapper vh-100', contentBackground)}>
        <Header
          pms={props.pms}
          configs={props.configs}
          openAlert={props.openAlert}
          isCollapsedLayout
        />
        <div className="app-main">
          <SidebarCollapsed
            bar={props.bar}
            companyConfigs={props.companyConfigs}
            pms={props.pms}
            configs={props.configs}
          />
          <div className={clsx('app-content')}>
            <div className="app-inner-content-layout--main">{children}</div>
          </div>
        </div>
      </div>
    </>
  );
};

CollapsedSidebar.propTypes = {
  children: PropTypes.node.isRequired,
};

const mapStateToProps = (state) => ({
  contentBackground: state.ThemeOptions.contentBackground,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  headerDrawerToggle: state.ThemeOptions.headerDrawerToggle,
  headerSearchHover: state.ThemeOptions.headerSearchHover,
});

export default connect(mapStateToProps)(CollapsedSidebar);
