import {combineReducers} from 'redux';
import ThemeOptions from './ThemeOptions';
import name from './Nome/nomeReducers';
import occupation from './Occupation/occupationReducers';
import namePage from './Page/pageReducers';
import colorMessage from './ColorMessage/colorMessageReducer';
import Img from './Img/imgReducer';
import Symbol from './CurrencySymbol/currencySymbolReducer';

export default combineReducers({
  ThemeOptions,
  user: name,
  page: namePage,
  colorMessage,
  occupation,
  img: Img,
  symbol: Symbol,
});
