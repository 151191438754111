import React, {Fragment} from 'react';
import {Grid} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  error: {
    margin: '10px 0px',
    backgroundColor: '#F2DEDE',
    color: '#A94442',
    border: '1px solid #EBCCD1',
    padding: '10px',
  },
}));

export default function Error(props) {
  const classes = useStyles();
  const {t} = useTranslation();
  const {result} = props;

  return (
    <>
      {result.errors && (
        <Grid
          className={classes.error}
          container
          direction="column"
          spacing={12}>
          {result.errors.map((i) => (
            <div key={i.code}>
              {t(`message.${i.code}`, {param: t(`word.${i.field}`)})}
            </div>
          ))}
        </Grid>
      )}
    </>
  );
}
