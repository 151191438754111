/* eslint-disable no-restricted-syntax */
import React, {Fragment, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import {
  Fab,
  Button,
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
} from '@material-ui/core';
import ModalPeriod from 'components/modals/Period';
import ReplayIcon from '@material-ui/icons/Replay';
import {useTranslation} from 'react-i18next';
import {FaPlus} from 'react-icons/fa';
import CardContainer from '../../CardContainer';
import {getPeriods} from '../../../helpers/api';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tablerow: {
    cursor: 'pointer',
    transition: 'all ease 0.2s',
    '&:hover': {
      backgroundColor: '#CCC',
    },
  },
}));

export default function Periods(props) {
  const history = useHistory();
  const {t} = useTranslation();
  const classes = useStyles();
  const [periods, setPeriods] = useState([]);

  const [modalOpen, setModalOpen] = useState(false);
  const [id, setId] = useState();
  const [observer, setObserver] = useState(true);

  useEffect(() => {
    if (props.id) {
      const getAll = async (idPeriod) => {
        const res = await getPeriods(idPeriod);
        if (res.errors) {
          for (const i of res.errors) {
            props.openAlert(
              t(`message.${i.code}`, {param: t(`word.${i.field}`)}),
              i.color,
            );
          }
          history.replace('/activity');
        } else {
          setPeriods(res);
        }
      };
      getAll(props.id);
    }
    // eslint-disable-next-line
  }, [observer]);

  const handleEdit = (idEdit) => {
    setId(idEdit);
    setModalOpen(true);
  };

  const handleAdd = () => {
    setId(null);
    setModalOpen(true);
  };

  return (
    <>
      <ModalPeriod
        openAlert={props.openAlert}
        setObserver={setObserver}
        observer={observer}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        idactivity={props.id}
        id={id}
      />
      <CardContainer>
        <div className={classes.root}>
          <div className="card-header">
            <div className="card-header--actions" style={{flex: '1'}}>
              <Fab
                size="small"
                color="secondary"
                style={{float: 'right'}}
                onClick={() => handleAdd()}>
                <span className="btn-wrapper--icon">
                  <FaPlus icon={['fas', 'plus']} />
                </span>
              </Fab>
            </div>
          </div>
          <TableContainer className="mb-4" component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{t('word.day')}</TableCell>
                  <TableCell>{t('word.starttime')}</TableCell>
                  <TableCell>{t('word.endtime')}</TableCell>
                  <TableCell>{t(`word.schedule-status.0`)}</TableCell>
                  <TableCell>{t(`word.schedule-status.1`)}</TableCell>
                  <TableCell>{t(`word.schedule-status.2`)}</TableCell>
                  <TableCell>{t(`word.schedule-status.3`)}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {periods &&
                  periods.map((i) => (
                    <TableRow
                      key={i.idperiod}
                      className={classes.tablerow}
                      onClick={() => handleEdit(i.idperiod)}>
                      <TableCell component="th" scope="row">
                        {t(`word.week.${i.day}`)}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {Array.from(i.starttime).splice(0, 5)}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {Array.from(i.endtime).splice(0, 5)}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Tooltip arrow title={t(`word.schedule-status.0`)}>
                          <span className="btn-pill mx-3 badge badge-primary">
                            {i.scheduled}
                          </span>
                        </Tooltip>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Tooltip arrow title={t(`word.schedule-status.1`)}>
                          <span className="btn-pill mx-3 badge badge-danger">
                            {i['called-off']}
                          </span>
                        </Tooltip>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Tooltip arrow title={t(`word.schedule-status.2`)}>
                          <span className="btn-pill mx-3 badge badge-warning">
                            {i['in-progress']}
                          </span>
                        </Tooltip>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Tooltip arrow title={t(`word.schedule-status.3`)}>
                          <span className="btn-pill mx-3 badge badge-success">
                            {i.finished}
                          </span>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <div style={{float: 'right'}}>
              <Button
                style={{textTransform: 'none'}}
                onClick={() => history.replace('/activity')}
                className="m-2"
                variant="contained"
                color="secondary">
                <ReplayIcon />
                {t('word.back')}
              </Button>
            </div>
          </TableContainer>
        </div>
      </CardContainer>
    </>
  );
}
