/* eslint-disable no-restricted-syntax */
import React, {Fragment, useEffect, useState, useRef} from 'react';
import {useHistory} from 'react-router-dom';
import {getConfigs, /* addConfig, */ editConfig} from 'helpers/api';
import {SketchPicker} from 'react-color';
import {
  Grid,
  Button,
  Card,
  TextField,
  FormControlLabel,
  Checkbox,
  Menu,
  MenuItem,
  IconButton,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ReplayIcon from '@material-ui/icons/Replay';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import Cookies from 'js-cookie';
import CropIcon from '@material-ui/icons/Crop';
import {base64toBlob} from 'utils/base64ToBlob';
import {Page} from 'store/reducers/Page/pageAction';
import Error from '../../components/Error';
import ModalEdit from '../../components/modals/ModalEditImage';

const FormatImage = (props) => {
  if (props.photo.photo) {
    return (
      <img
        style={{height: '100px', width: '150px'}}
        className="img-fluid"
        src={props.photo.photo}
        alt="imagem"
      />
    );
  }
  return (
    <img
      style={{height: '100px', width: '150px'}}
      className="img-fluid"
      src={`data:${props.type};base64,${props.photo}`}
      alt="imagem"
    />
  );
};

export default function Formulario(props) {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const history = useHistory();
  const fileField = useRef();

  const software = [
    {
      value: '0',
      label: 'software.0',
    },
    {
      value: '1',
      label: 'software.1',
    },
    {
      value: '2',
      label: 'software.2',
    },
  ];

  const [editmode, setEditmode] = useState(false);
  const [company, setCompany] = useState('');
  const [deleteImage, setDeleteimage] = useState(false);
  const [photo, setPhoto] = useState('');
  const [result, setResult] = useState({});
  const [disable, setDisable] = useState(false);
  const [bar, setBar] = useState('#3d4977');
  const [prim, setPrim] = useState('#3d4977');
  const [sec, setSec] = useState('#5383ff');
  const [soft, setSoftware] = useState(0);
  const [urlpms, setUrlpms] = useState('');
  const [portpms, setPortpms] = useState(0);
  const [userpms, setUserpms] = useState('');
  const [disablepms, setDisablepms] = useState(true);
  const [passwordpms, setPasswordpms] = useState('');
  const [idcompanypms, setIdcompanypms] = useState(0);
  const [currencysymbol, setCurrencysymbol] = useState('');
  const [idwaiterpms, setIdwaiterpms] = useState(0);
  const [idtablepms, setIdtablepms] = useState(0);
  const [iditemcancelpms, setIdItemCancelpms] = useState(0);
  const [iduserpms, setIdUserpms] = useState(0);
  const [idcurrencypms, setIdCurrencypms] = useState(0);
  const [editImage, setEditImage] = useState(false);
  const [OpenCrop, setOpenCrop] = useState(false);

  const [anchorElMenu1, setAnchorElMenu1] = useState(null);
  const handleClickMenu1 = (event) => {
    setAnchorElMenu1(event.currentTarget);
  };
  const handleCloseMenu1 = () => {
    setAnchorElMenu1(null);
  };

  const [anchorElMenu2, setAnchorElMenu2] = useState(null);
  const handleClickMenu2 = (event) => {
    setAnchorElMenu2(event.currentTarget);
  };
  const handleCloseMenu2 = () => {
    setAnchorElMenu2(null);
  };

  const [anchorElMenu3, setAnchorElMenu3] = useState(null);
  const handleClickMenu3 = (event) => {
    setAnchorElMenu3(event.currentTarget);
  };
  const handleCloseMenu3 = () => {
    setAnchorElMenu3(null);
  };

  useEffect(() => {
    dispatch(Page(t('word.configs')));
    const getOne = async () => {
      props.setOp(true);
      const res = await getConfigs();
      if (res && !res.errors) {
        setEditmode(true);
        setCompany(res.company);
        setBar(res.barcolor);
        setPrim(res.primarycolor);
        setSec(res.secondarycolor);
        setSoftware(res.pms);
        setUrlpms(res.urlpms == null ? '' : res.urlpms);
        setPasswordpms(res.passwordpms == null ? '' : res.passwordpms);
        setPortpms(parseInt(res.portpms));
        setUserpms(res.userpms == null ? '' : res.userpms);
        setDisablepms(res.pms === 0);
        setIdcompanypms(parseInt(res.idcompanypms));
        setIdwaiterpms(parseInt(res.idwaiterpms));
        setIdtablepms(parseInt(res.idtablepms));
        setIdItemCancelpms(parseInt(res.iditemcancelpms));
        setIdUserpms(parseInt(res.iduserpms));
        setIdCurrencypms(parseInt(res.idcurrencypms));
        setCurrencysymbol(res.currencysymbol);
        setPhoto('');
        if (res.photo && res.mimetype) {
          setPhoto({
            photo: URL.createObjectURL(
              base64toBlob(
                res.photo,
                res.mimetype ? res.mimetype : 'image/jpeg',
              ),
            ),
          });
        }
      } else if (res.errors) {
        for (const i of res.errors) {
          props.openAlert(
            t(`message.${i.code}`, {param: t(`word.${i.field}`)}),
            i.color,
          );
        }
      }
      props.setOp(false);
    };
    getOne(props.id);
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisable(true);
    props.setOp(true);
    const idcompany = Cookies.get('company');

    let res;

    const fData = new FormData();

    fData.append('name', company);
    fData.append('idcompany', idcompany);
    fData.append('deleteImage', deleteImage);
    fData.append('barcolor', bar);
    fData.append('primarycolor', prim);
    fData.append('secondarycolor', sec);
    fData.append('pms', soft);
    fData.append('urlpms', urlpms);
    fData.append('portpms', portpms);
    fData.append('userpms', userpms);
    fData.append('passwordpms', passwordpms);
    fData.append('idcompanypms', idcompanypms);
    fData.append('currencysymbol', currencysymbol);
    fData.append('idtablepms', idtablepms);
    fData.append('idwaiterpms', idwaiterpms);
    fData.append('iditemcancelpms', iditemcancelpms);
    fData.append('iduserpms', iduserpms);
    fData.append('idcurrencypms', idcurrencypms);
    fData.append('photo', photo);
    fData.append('editImage', editImage);

    if (editmode) {
      res = await editConfig(fData);
      if (res && !res.errors) {
        setResult(res);
        props.openAlert(
          t('message.edit-record-f', {param: t('word.configs')}),
          'success',
        );
        props.setPms(soft);
        window.location.href = '/home';
      } else if (res.errors) {
        setResult(res);
      }
    }
    setDisable(false);
    props.setOp(false);
  };

  const handleSoftware = async (value) => {
    setSoftware(value);
    if (value === '0' || value === 0) {
      setDisablepms(true);
    } else {
      setDisablepms(false);
    }
  };

  const formatCrop = {
    w: 4,
    h: 3,
    shape: 'rect',
  };

  const HandlePhoto = (e) => {
    const arr = e.target.files[0];
    setEditImage(true);
    arr.photo = URL.createObjectURL(e.target.files[0]);
    setPhoto(arr);
  };
  return (
    <>
      <Card className="p-4 mb-4">
        {result.errors && <Error result={result} />}
        {OpenCrop && (
          <ModalEdit
            formatCrop={formatCrop}
            setEditImage={setEditImage}
            setOpenCrop={setOpenCrop}
            OpenCrop={OpenCrop}
            photoURL={photo.photo}
            setPhotoURL={setPhoto}
          />
        )}
        <form onSubmit={handleSubmit}>
          <Grid container direction="row" spacing={12}>
            <Grid container xs={12} sm={8}>
              <TextField
                className="m-2"
                label={t('word.corporatename')}
                id="standard-size-small"
                size="small"
                required
                disabled={disable}
                inputProps={{maxLength: 64}}
                value={company}
                onChange={(e) => setCompany(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid container xs={12} sm={4}>
              <TextField
                className="m-2"
                label={t('word.currencysymbol')}
                id="standard-size-small"
                size="small"
                required
                disabled={disable}
                value={currencysymbol}
                inputProps={{maxLength: 30}}
                onChange={(e) => setCurrencysymbol(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container direction="column">
            <Grid container direction="column">
              <Grid container direction="row">
                {photo && (
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-block p-0 avatar-icon-wrapper rounded m-0 border-3 border-first">
                      <div className="rounded border-3 border-white overflow-hidden">
                        <FormatImage photo={photo} />
                      </div>
                    </div>
                  </div>
                )}
                {photo && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={deleteImage}
                        onChange={() => setDeleteimage(!deleteImage)}
                      />
                    }
                    label={t('message.delete-image')}
                  />
                )}
              </Grid>
              <input
                accept="image/*"
                style={{display: 'none'}}
                id="raised-button-file"
                type="file"
                ref={fileField}
                disabled={disable || deleteImage}
                onChange={(e) => HandlePhoto(e)}
              />
              <label htmlFor="raised-button-file">
                {!photo.photo && (
                  <Button
                    variant="outlined"
                    component="span"
                    disabled={disable || deleteImage}>
                    {t('word.add-logo')}
                  </Button>
                )}
                {photo.photo && (
                  <IconButton>
                    <CropIcon
                      color="primary"
                      arial-label="Crop"
                      onClick={() => setOpenCrop(true)}
                    />
                  </IconButton>
                )}
              </label>
            </Grid>
          </Grid>
          <div className="heading-3 my-2">{t('word.color.3')}</div>
          <Grid container direction="row" spacing={12}>
            <Grid item md={4}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClickMenu1}>
                {t('word.color.0')}
                <div
                  style={{
                    backgroundColor: bar,
                    padding: '10px',
                    marginLeft: '10px',
                    borderRadius: '5px',
                  }}
                />
              </Button>
              <Menu
                anchorEl={anchorElMenu1}
                keepMounted
                open={Boolean(anchorElMenu1)}
                onClose={handleCloseMenu1}
                classes={{list: 'p-0'}}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}>
                <SketchPicker
                  color={bar}
                  onChangeComplete={(e) => setBar(e.hex)}
                />
              </Menu>
            </Grid>
            <Grid item md={4}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClickMenu2}>
                {t('word.color.1')}
                <div
                  style={{
                    backgroundColor: prim,
                    padding: '10px',
                    marginLeft: '10px',
                    borderRadius: '5px',
                  }}
                />
              </Button>
              <Menu
                anchorEl={anchorElMenu2}
                keepMounted
                open={Boolean(anchorElMenu2)}
                onClose={handleCloseMenu2}
                classes={{list: 'p-0'}}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}>
                <SketchPicker
                  color={prim}
                  onChangeComplete={(e) => setPrim(e.hex)}
                />
              </Menu>
            </Grid>
            <Grid item md={4}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClickMenu3}>
                {t('word.color.2')}
                <div
                  style={{
                    backgroundColor: sec,
                    padding: '10px',
                    marginLeft: '10px',
                    borderRadius: '5px',
                  }}
                />
              </Button>
              <Menu
                anchorEl={anchorElMenu3}
                keepMounted
                open={Boolean(anchorElMenu3)}
                onClose={handleCloseMenu3}
                classes={{list: 'p-0'}}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}>
                <SketchPicker
                  color={sec}
                  onChangeComplete={(e) => setSec(e.hex)}
                />
              </Menu>
            </Grid>
          </Grid>
          <div className="heading-3 my-2">{t('word.integration-pms')}</div>
          <Grid container direction="row" spacing={12}>
            <Grid container xs={12} sm={4} style={{marginTop: '-2.3px'}}>
              <TextField
                className="m-2"
                id="standard-select-currency"
                select
                label={t('word.version-pms')}
                required
                disabled={disable}
                value={soft}
                onChange={(e) => handleSoftware(e.target.value)}
                fullWidth>
                {software.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {t(`word.${option.label}`)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid container xs={12} sm={4}>
              <TextField
                className="m-2"
                label={t('word.urlpms')}
                id="standard-size-small"
                size="small"
                required={!disablepms}
                disabled={disable || disablepms}
                value={urlpms}
                onChange={(e) => setUrlpms(e.target.value)}
                fullWidth
                inputProps={{maxLength: 128}}
              />
            </Grid>
            <Grid container xs={12} sm={4}>
              <TextField
                className="m-2"
                label={t('word.portpms')}
                id="standard-size-small"
                size="small"
                required={!disablepms}
                disabled={disable || disablepms}
                value={parseInt(portpms)}
                onChange={(e) => setPortpms(e.target.value)}
                type="number"
                inputProps={{min: 0}}
                fullWidth
              />
            </Grid>
            <Grid container xs={12} sm={3}>
              <TextField
                className="m-2"
                label={t('word.iduserpms')}
                id="standard-size-small"
                size="small"
                required={!disablepms}
                disabled={disable || disablepms}
                value={parseInt(iduserpms)}
                onChange={(e) => setIdUserpms(e.target.value)}
                inputProps={{min: 0}}
                type="number"
                fullWidth
              />
            </Grid>
            <Grid container xs={12} sm={3}>
              <TextField
                className="m-2"
                label={t('word.userpms')}
                id="standard-size-small"
                size="small"
                required={!disablepms}
                disabled={disable || disablepms}
                value={userpms}
                onChange={(e) => setUserpms(e.target.value)}
                fullWidth
                inputProps={{maxLength: 64}}
              />
            </Grid>
            <Grid container xs={12} sm={3}>
              <TextField
                className="m-2"
                label={t('word.passwordpms')}
                id="standard-size-small"
                size="small"
                required={!disablepms}
                disabled={disable || disablepms}
                value={passwordpms}
                type="password"
                onChange={(e) => setPasswordpms(e.target.value)}
                fullWidth
                inputProps={{maxLength: 64}}
              />
            </Grid>
            <Grid container xs={12} sm={3}>
              <TextField
                className="m-2"
                label={t('word.idcompanypms')}
                id="standard-size-small"
                size="small"
                required={!disablepms}
                disabled={disable || disablepms}
                value={parseInt(idcompanypms)}
                onChange={(e) => setIdcompanypms(e.target.value)}
                inputProps={{min: 0}}
                type="number"
                fullWidth
              />
            </Grid>
            <Grid container xs={12} sm={3}>
              <TextField
                className="m-2"
                label={t('word.idwaiterpms')}
                id="standard-size-small"
                size="small"
                required={!disablepms}
                disabled={disable || disablepms}
                value={parseInt(idwaiterpms)}
                onChange={(e) => setIdwaiterpms(e.target.value)}
                inputProps={{min: 0}}
                type="number"
                fullWidth
              />
            </Grid>
            <Grid container xs={12} sm={3}>
              <TextField
                className="m-2"
                label={t('word.idtablepms')}
                id="standard-size-small"
                size="small"
                required={!disablepms}
                disabled={disable || disablepms}
                value={parseInt(idtablepms)}
                onChange={(e) => setIdtablepms(e.target.value)}
                inputProps={{min: 0}}
                type="number"
                fullWidth
              />
            </Grid>
            <Grid container xs={12} sm={3}>
              <TextField
                className="m-2"
                label={t('word.iditemcancelpms')}
                id="standard-size-small"
                size="small"
                required={!disablepms}
                disabled={disable || disablepms}
                value={parseInt(iditemcancelpms)}
                onChange={(e) => setIdItemCancelpms(e.target.value)}
                inputProps={{min: 0}}
                type="number"
                fullWidth
              />
            </Grid>
            <Grid container xs={12} sm={3}>
              <TextField
                className="m-2"
                label={t('word.idcurrencypms')}
                id="standard-size-small"
                size="small"
                required={!disablepms}
                disabled={disable || disablepms}
                value={parseInt(idcurrencypms)}
                onChange={(e) => setIdCurrencypms(e.target.value)}
                inputProps={{min: 0}}
                type="number"
                fullWidth
              />
            </Grid>
          </Grid>
          <div style={{float: 'right'}}>
            <Button
              style={{textTransform: 'none'}}
              disabled={disable}
              onClick={() => history.replace('/home')}
              className="m-2"
              variant="contained"
              color="secondary">
              <ReplayIcon />
              {t('word.cancel')}
            </Button>
            <Button
              style={{textTransform: 'none'}}
              disabled={disable}
              type="submit"
              className="m-2"
              color="secondary"
              variant="contained">
              <CheckIcon />
              {t('word.save')}
            </Button>
          </div>
        </form>
      </Card>
    </>
  );
}
