/* eslint-disable no-restricted-syntax */
/* eslint-disable react/prop-types */
import React, {Fragment, useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {FaPlus} from 'react-icons/fa';
import {makeStyles} from '@material-ui/core/styles';
import {getUsers} from 'helpers/api';
import {
  Fab,
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Page} from 'store/reducers/Page/pageAction';
import CardContainer from '../../components/CardContainer';
import Enum from '../../helpers/enum';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tablerow: {
    cursor: 'pointer',
    transition: 'all ease 0.2s',
    '&:hover': {
      backgroundColor: '#CCC',
    },
  },
}));

export default function RegularTables1(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const history = useHistory();

  const [users, setUsers] = useState([]);
  const [filteredUser, setFilteredUser] = useState(users);

  useEffect(() => {
    dispatch(Page(t('word.users')));
    const getAll = async () => {
      props.setOp(true);
      const res = await getUsers();
      if (res && !res.errors) {
        setUsers(res);
        setFilteredUser(res);
      } else if (res.errors) {
        for (const i of res.errors) {
          props.openAlert(
            t(`message.${i.code}`, {param: t(`word.${i.field}`)}),
            i.color,
          );
        }
      }
      props.setOp(false);
    };
    getAll();
    // eslint-disable-next-line
  }, []);

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    let result = [];
    result = users.filter((row) => row.name.toLowerCase().search(value) !== -1);
    setFilteredUser(result);
  };

  const FormatImage = (p) => {
    if (p.photo && p.type) {
      return (
        <img
          style={{height: '50px', width: '50px'}}
          className="img-fluid"
          src={`data:${p.type};base64,${p.photo}`}
          alt="imagem"
        />
      );
    }
    return (
      <img
        style={{height: '50px', width: '50px'}}
        className="img-fluid"
        src="/easy-planner-default-person.png"
        alt="imagem"
      />
    );
  };

  return (
    <>
      <CardContainer>
        <div className={classes.root}>
          <div className="card-header">
            <FormControl className="w-100 m-2">
              <InputLabel htmlFor="input-with-icon-adornment">
                {t('word.search')}
              </InputLabel>
              <Input
                onChange={(event) => handleSearch(event)}
                style={{maxWidth: '500px'}}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </FormControl>
            <div className="card-header--actions">
              <Link to="/user/new">
                <Fab size="small" color="secondary">
                  <span className="btn-wrapper--icon">
                    <FaPlus icon={['fas', 'plus']} />
                  </span>
                </Fab>
              </Link>
            </div>
          </div>
          <TableContainer className="mb-4" component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{t('word.image')}</TableCell>
                  <TableCell>{t('word.name')}</TableCell>
                  <TableCell>{t('word.email')}</TableCell>
                  {Enum.arrPms.includes(props.pms) && (
                    <TableCell align="right">{t('word.iduserpms2')}</TableCell>
                  )}
                  <TableCell>{t('word.status')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredUser &&
                  filteredUser.map((i) => (
                    <TableRow
                      key={i.iduser}
                      className={classes.tablerow}
                      onClick={() => history.push(`/user/edit/${i.iduser}`)}>
                      <TableCell>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-block p-0 avatar-icon-wrapper rounded-circle m-0 border-3 border-first">
                            <div className="rounded-circle border-3 border-white overflow-hidden">
                              <FormatImage photo={i.photo} type={i.mimetype} />
                            </div>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {i.name}
                      </TableCell>
                      <TableCell align="left">{i.email}</TableCell>
                      {Enum.arrPms.includes(props.pms) && (
                        <TableCell align="right">{i.iduserpms}</TableCell>
                      )}
                      <TableCell align="left">
                        {i.active ? t('word.active') : t('word.inactive')}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </CardContainer>
    </>
  );
}
