import {
  DialogActions,
  Typography,
  Box,
  Slider,
  Button,
} from '@material-ui/core';
import React, {useState} from 'react';
import CancelIcon from '@material-ui/icons/Cancel';
import CropIcon from '@material-ui/icons/Crop';

import Cropper from 'react-easy-crop';
import getCroppedImg from 'utils/cropImage';
import {useTranslation} from 'react-i18next';

const zoomPercent = (value) => `${Math.round(value * 100)}%`;

export default function Home({
  photoURL,
  setOpenCrop,
  setPhotoURL,
  setEditImage,
  formatCrop,
}) {
  const [crop, setCrop] = useState({x: 0, y: 0});
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const {t} = useTranslation();

  const cropComplete = (croppedArea, croppedAreaPixelsParam) => {
    setCroppedAreaPixels(croppedAreaPixelsParam);
  };

  const cropImage = async () => {
    try {
      const {file} = await getCroppedImg(photoURL, croppedAreaPixels, rotation);
      setPhotoURL(file);
      setOpenCrop(false);
      setEditImage(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div
        style={{
          position: 'relative',
          width: '400px',
          height: '300px',
          background: '#CCC',
        }}>
        <Cropper
          image={photoURL}
          crop={crop}
          zoom={zoom}
          rotation={rotation}
          aspect={formatCrop.w / formatCrop.h}
          cropShape={formatCrop.shape}
          onZoomChange={setZoom}
          onRotationChange={setRotation}
          onCropChange={setCrop}
          onCropComplete={cropComplete}
        />
      </div>
      <DialogActions style={{flexDirection: 'column', mx: 3, my: 2}}>
        <Box style={{width: '100%'}}>
          <Box>
            <Typography>
              {t('word.Zoom')}: {zoomPercent(zoom)}
            </Typography>
            <Slider
              valueLableDisplay="auto"
              valueLabelFormat={zoomPercent}
              min={1}
              max={3}
              step={0.05}
              value={zoom}
              onChange={(e, z) => setZoom(z)}
            />
          </Box>
        </Box>
        <Box style={{width: '100%'}}>
          <Box>
            <Typography>
              {t('word.Rotation')}: {rotation}
            </Typography>
            <Slider
              valueLableDisplay="auto"
              min={0}
              max={360}
              value={rotation}
              onChange={(e, r) => setRotation(r)}
            />
          </Box>
        </Box>
        <Box
          style={{
            display: 'flex',
            gap: '2',
            flexWrap: 'wrap',
          }}>
          <Button
            style={{paddingLeft: '15px'}}
            variant="outline"
            startIcon={<CancelIcon />}
            onClick={() => setOpenCrop(false)}>
            {t('word.cancel')}:
          </Button>
          <Button
            style={{paddingLeft: '15px'}}
            variant="contained"
            startIcon={<CropIcon />}
            onClick={cropImage}>
            {t('word.Crop')}
          </Button>
        </Box>
      </DialogActions>

      {/* <div className="avatar-icon-wrapper d-120">
                <div className="avatar-icon rounded-circle">
                  <img alt="..." className="img-fluid" src={photoURL} />
                </div>
            </div> */}
    </div>
  );
}
