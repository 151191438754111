import DashboardTwoToneIcon from '@material-ui/icons/DashboardTwoTone';
import CalendarTodayOutlined from '@material-ui/icons/CalendarTodayOutlined';
import Settings from '@material-ui/icons/Settings';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import PersonIcon from '@material-ui/icons/Person';

const iconsMap = {
  DashboardTwoToneIcon,
  Settings,
  CalendarTodayOutlined,
  AutorenewIcon,
  PersonIcon,
};

export default [
  {
    label: 'word.menu-bar.menu',
    content: JSON.parse(
      `[
  {
    "label": "word.menu-bar.schedule",
    "icon": "CalendarTodayOutlined",
    "content": [{
      "label": "word.menu-bar.calendar",
      "description": "Calendário",
      "key": "calendar",
      "to": "/calendar"
    }]
  },
  {
    "label": "word.menu-bar.forms",
    "icon": "DashboardTwoToneIcon",
    "content": [
      {
        "label": "word.menu-bar.activities",
        "description": "Atividades",
        "key": "activity",
        "to": "/activity"
      },
      {
        "label": "word.menu-bar.placesofsale",
        "description": "Local de Venda",
        "key": "placeofsale",
        "to": "/placeofsale"
      },
      {
        "label": "word.menu-bar.type-activities",
        "description": "Tipos de atividade",
        "key": "typeactivity",
        "to": "/typeactivity"
      },
      {
        "label": "word.menu-bar.users",
        "description": "Listas de Usuários",
        "key": "user",
        "to": "/user"
      },
      {
        "label": "word.menu-bar.occupations",
        "description": "Funções",
        "key": "occupation",
        "to": "/occupation"
      },
      {
        "label": "word.menu-bar.profiles",
        "description": "Perfis",
        "key": "profile",
        "to": "/profile"
      },
      {
        "label": "word.menu-bar.holidays",
        "description": "Feriados",
        "key": "holiday",
        "to": "/holiday"
      }
    ]
  },
  {
    "label": "word.menu-bar.configs",
    "icon": "Settings",
    "content": [
      {
        "label": "word.menu-bar.company",
        "description": "Configuração da Empresa",
        "key": "config",
        "to": "/config/company"
      }
    ]
  },
  {
    "label": "word.menu-bar.customers",
    "icon": "PersonIcon",
    "content": [
      {
        "label": "word.menu-bar.search",
        "description": "Consultar clientes",
        "key": "customersearch",
        "to": "/customer"
      }

    ]
  },
  {
    "label": "word.menu-bar.integration-pms",
    "icon": "AutorenewIcon",
    "pms": "true",
    "content": [
      {
        "label": "word.menu-bar.maintenance",
        "description": "Agendamentos com falha em integração",
        "key": "maintance",
        "to": "/pms/schedule",
        "pms": "true"
      }

    ]
  }
]`,
      (key, value) => {
        if (key === 'icon') {
          return iconsMap[value];
        }
        return value;
      },
    ),
  },
];
