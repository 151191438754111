import React, {useRef} from 'react';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import './assets/base.scss';
import CssBaseline from '@material-ui/core/CssBaseline';

import {SnackbarProvider} from 'notistack';

import CloseIcon from '@material-ui/icons/Close';
import ScrollToTop from './utils/ScrollToTop';
import Routes from './Routes';
import {store, persistor} from './config/configureStore';

const App = () => {
  const notistackRef = useRef();

  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <CssBaseline />
          <ScrollToTop>
            <SnackbarProvider
              maxSnack={4}
              ref={notistackRef}
              action={(key) => (
                <>
                  <div className="hov" style={{marginRight: '10px'}}>
                    <CloseIcon
                      onClick={onClickDismiss(key)}
                      style={{fontSize: '15px'}}
                    />
                  </div>
                </>
              )}>
              <Routes />
            </SnackbarProvider>
          </ScrollToTop>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
};

export default App;
