import React, {Fragment} from 'react';
import {useHistory} from 'react-router-dom';
import clsx from 'clsx';
import {IconButton, Box, Tooltip} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {connect} from 'react-redux';

const FormatImage = (props) => {
  if (props.photo)
    return (
      <img
        style={{width: '100px'}}
        src={`data:${props.type};base64,${props.photo}`}
        alt="imagem"
      />
    );
  return (
    <img style={{width: '100px'}} src="/desbravador-logo.png" alt="imagem" />
  );
};

const SidebarUserbox = (props) => {
  const {sidebarToggle, sidebarHover} = props;
  const history = useHistory();

  return (
    <>
      <Box
        style={{backgroundColor: props.bar}}
        className={clsx('app-sidebar-userbox', {
          'app-sidebar-userbox--collapsed': sidebarToggle && !sidebarHover,
        })}>
        <IconButton
          aria-controls="userMenu"
          onClick={() => history.push('/config/company')}
          className="app-sidebar-userbox-btn"
          size="small">
          <MoreVertIcon fontSize="inherit" />
        </IconButton>
        <Tooltip arrow title={props.companyConfigs.company}>
          <Box className="app-sidebar-userbox-name">
            <FormatImage
              photo={props.companyConfigs.photo}
              type={props.companyConfigs.mimetype}
            />
            {/* <Box>
              <b>{props.companyConfigs.company}</b>
            </Box> */}
          </Box>
        </Tooltip>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarHover: state.ThemeOptions.sidebarHover,
});

export default connect(mapStateToProps)(SidebarUserbox);
