import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import Cookies from 'js-cookie';
import {isLogged} from '../helpers/auth';

const func = ({children, ...rest}) => {
  const logged = isLogged();
  const authorized = !(rest.private && !logged);
  const company = Cookies.get('company');
  // if(rest.configs) {
  //     if(!rest.configs[rest.path.split(/[/]/)[1]]){
  //         authorized = false
  //     }
  // }
  return (
    <Route
      {...rest}
      render={() =>
        authorized ? children : <Redirect to={`/company/${company}`} />
      }
    />
  );
};

export default func;
