/* eslint-disable no-restricted-syntax */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/prop-types */
import React, {Fragment, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {getCustomer} from 'helpers/api';
import {
  Grid,
  Card,
  TextField,
  MenuItem,
  Button,
  AppBar,
  Box,
  Typography,
  Tabs,
  Tab,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {makeStyles} from '@material-ui/core/styles';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {useParams} from 'react-router';
import moment from 'moment';
import {Page} from 'store/reducers/Page/pageAction';
import ScheduleCustomer from '../../List/ScheduleCustomer';
import Person from '../../List/Person';
import Enum from '../../../helpers/enum';

const languages = [
  {
    value: 'pt-BR',
    label: 'Português',
  },
  {
    value: 'es-ES',
    label: 'Espanhol',
  },
  {
    value: 'en-US',
    label: 'Inglês',
  },
];

const useStyles = makeStyles(() => ({
  error: {
    margin: '10px 0px',
    backgroundColor: '#F2DEDE',
    color: '#A94442',
    border: '1px solid #EBCCD1',
    padding: '10px',
  },
  root: {
    flexGrow: 1,
    backgroundColor: '#FFF',
  },
}));
function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function Formulario(props) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const dispatch = useDispatch();
  const {t} = useTranslation();
  const history = useHistory();
  const {id} = useParams();
  const classes = useStyles();
  const [result, setResult] = useState({});

  const getOne = async () => {
    const res = await getCustomer(id);

    if (res && !res.errors) {
      setResult(res);
    }
    if (res.errors) {
      for (const i of res.errors) {
        props.openAlert(
          t(`message.${i.code}`, {param: t(`word.${i.field}`)}),
          i.color,
        );
      }
      history.replace('/customer');
    }
  };

  useEffect(() => {
    dispatch(Page(t('word.customer')));
    getOne();

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Card className="p-4 mb-4">
        <Grid container direction="rowclose={" spacing={12}>
          <Grid container xs={12} sm={8}>
            <Typography
              color="primary"
              component="div"
              className="d-flex align-items-center">
              <div className="text-first font-weight-bold">
                {t('word.name')}
              </div>
              <div className="ml-auto font-size-xs" />
            </Typography>
            <TextField
              className="m-2"
              // label={t('word.name')}
              id="standard-size-small"
              size="small"
              required
              disabled
              value={result.name}
              fullWidth
              inputProps={{maxLength: 128}}
            />
          </Grid>
          <Grid container xs={12} sm={4}>
            <Typography
              color="primary"
              component="div"
              className="d-flex align-items-center">
              <div className="text-first font-weight-bold">
                {t('word.document')}
              </div>
              <div className="ml-auto font-size-xs" />
            </Typography>
            <TextField
              className="m-2"
              // label={t('word.document')}
              id="standard-size-small"
              size="small"
              required
              disabled
              value={result.document}
              type="number"
              fullWidth
            />
          </Grid>
          <Grid container xs={12} sm={8}>
            <Typography
              color="primary"
              component="div"
              className="d-flex align-items-center">
              <div className="text-first font-weight-bold">
                {t('word.email')}
              </div>
              <div className="ml-auto font-size-xs" />
            </Typography>
            <TextField
              className="m-2"
              // label={t('word.email')}
              id="standard-size-small"
              size="small"
              required
              disabled
              value={result.email}
              fullWidth
              inputProps={{maxLength: 128}}
            />
          </Grid>
          <Grid container xs={12} sm={4}>
            <Typography
              color="primary"
              component="div"
              className="d-flex align-items-center">
              <div className="text-first font-weight-bold">
                {t('word.language')}
              </div>
              <div className="ml-auto font-size-xs" />
            </Typography>
            <TextField
              className="m-2"
              id="standard-select-currency"
              required
              disabled
              value={result.language}
              fullWidth>
              {languages.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {Enum.arrPms.includes(props.pms) && (
            <>
              <div className="heading-3 my-2 pg-2 pb-2 pt-2">
                {t('word.integration-pms')}
              </div>
              <Grid container direction="row" spacing={12}>
                <Grid container xs={12} sm={4}>
                  <Typography
                    color="primary"
                    component="div"
                    className="d-flex align-items-center">
                    <div className="text-first font-weight-bold">
                      {t('word.N-account')}
                    </div>
                    <div className="ml-auto font-size-xs" />
                  </Typography>
                  <TextField
                    className="m-2"
                    id="standard-select-currency"
                    required
                    disabled
                    value={result.idaccountpms}
                    fullWidth
                  />
                </Grid>
                <Grid container xs={12} sm={4}>
                  <Typography
                    color="primary"
                    component="div"
                    className="d-flex align-items-center">
                    <div className="text-first font-weight-bold">
                      {t('word.uhpms')}
                    </div>
                    <div className="ml-auto font-size-xs" />
                  </Typography>
                  <TextField
                    className="m-2"
                    id="standard-select-currency"
                    required
                    disabled
                    value={result.uhpms}
                    fullWidth
                  />
                </Grid>
                <Grid container xs={12} sm={4}>
                  <Typography
                    color="primary"
                    component="div"
                    className="d-flex align-items-center">
                    <div className="text-first font-weight-bold">
                      {t('word.typeaccountpms')}
                    </div>
                    <div className="ml-auto font-size-xs" />
                  </Typography>
                  <TextField
                    className="m-2"
                    id="standard-select-currency"
                    required
                    disabled
                    value={t(
                      `word.integrate-pms.${props.pms}.typeaccount.${result.typeaccountpms}`,
                    )}
                    fullWidth
                  />
                </Grid>
                <Grid container xs={12} sm={4}>
                  <Typography
                    color="primary"
                    component="div"
                    className="d-flex align-items-center">
                    <div className="text-first font-weight-bold">
                      {t('word.checkinpms')}
                    </div>
                    <div className="ml-auto font-size-xs" />
                  </Typography>
                  <TextField
                    className="m-2"
                    id="standard-select-currency"
                    required
                    disabled
                    value={
                      result.checkinpms
                        ? `${moment(result.checkinpms).format(
                            t('format.date'),
                          )}, ${moment(result.checkinpms).format('LT')}`
                        : ''
                    }
                    fullWidth
                  />
                </Grid>
                <Grid container xs={12} sm={4}>
                  <Typography
                    color="primary"
                    component="div"
                    className="d-flex align-items-center">
                    <div className="text-first font-weight-bold">
                      {t('word.checkoutpms')}
                    </div>
                    <div className="ml-auto font-size-xs" />
                  </Typography>
                  <TextField
                    className="m-2"
                    id="standard-select-currency"
                    required
                    disabled
                    value={
                      result.checkoutpms
                        ? `${moment(result.checkoutpms).format(
                            t('format.date'),
                          )}, ${moment(result.checkoutpms).format('LT')}`
                        : ''
                    }
                    fullWidth
                  />
                </Grid>
                <Grid container xs={12} sm={4}>
                  <Typography
                    color="primary"
                    component="div"
                    className="d-flex align-items-center">
                    <div className="text-first font-weight-bold">
                      {t('word.statusaccountpms')}
                    </div>
                    <div className="ml-auto font-size-xs" />
                  </Typography>
                  <TextField
                    className="m-2"
                    id="standard-select-currency"
                    required
                    disabled
                    value={t(
                      `word.integrate-pms.${props.pms}.status.${result.statusaccountpms}`,
                    )}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </>
          )}

          <div className="heading-3 my-2 pg-2 pb-2 pt-2">
            {t('word.informations')}
          </div>
          <Grid container direction="row" spacing={12}>
            <Grid container xs={12} sm={4}>
              <Typography
                color="primary"
                component="div"
                className="d-flex align-items-center">
                <div className="text-first font-weight-bold">
                  {t('word.created')}
                </div>
                <div className="ml-auto font-size-xs" />
              </Typography>
              <TextField
                className="m-2"
                id="standard-select-currency"
                required
                disabled
                value={
                  result.created
                    ? `${moment(result.created).format(
                        t('format.date'),
                      )}, ${moment(result.created).format('LT')}`
                    : ''
                }
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
        <div style={{float: 'right'}}>
          <Button
            style={{textTransform: 'none'}}
            onClick={() => history.replace('/customer')}
            className="m-2"
            variant="contained"
            color="secondary">
            <ArrowBackIcon />
            {t('word.back')}
          </Button>
        </div>
      </Card>
      <div className={classes.root} style={{display: 'inline'}}>
        <AppBar
          position="static"
          style={{borderRadius: '5px 5px 0px 0px', backgroundColor: '#FFF'}}>
          <Tabs
            textColor="secondary"
            value={value}
            onChange={handleChange}
            centered>
            {Enum.arrPms.includes(props.pms) && (
              <Tab label={t('word.linked-people')} />
            )}
            <Tab label={t('word.schedules')} />
          </Tabs>
        </AppBar>
        {Enum.arrPms.includes(props.pms) && (
          <TabPanel value={value} index={0} style={{backgroundColor: '#FFF'}}>
            <Person openAlert={props.openAlert} id={id} />
          </TabPanel>
        )}
        {(value === 1 || !Enum.arrPms.includes(props.pms)) && (
          <TabPanel
            value={value}
            index={Enum.arrPms.includes(props.pms) ? 1 : 0}
            style={{backgroundColor: '#FFF'}}>
            <ScheduleCustomer
              companyConfigs={props.companyConfigs}
              pms={props.pms}
              openAlert={props.openAlert}
              id={id}
            />
          </TabPanel>
        )}
      </div>
    </>
  );
}
