import React, {useState} from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import {Dialog, Button} from '@material-ui/core';
import {useTranslation} from 'react-i18next';

export default function ButtonDelete(props) {
  const {t} = useTranslation();
  const [modal2, setModal2] = useState(false);
  const toggle1 = () => {
    props.func(props.id);
    setModal2(!modal2);
  };
  const toggle2 = () => setModal2(!modal2);
  return (
    <>
      {props.id && (
        <Button
          style={{textTransform: 'none'}}
          disabled={props.disable}
          className="m-2"
          variant="contained"
          color="secondary"
          onClick={toggle2}>
          <DeleteIcon />
          {t('word.delete')}
        </Button>
      )}
      <Dialog open={modal2} onClose={toggle2}>
        <div className="text-center p-5">
          <div className="avatar-icon-wraper rounded-circle m-0">
            <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-danger text-danger m-0 d-130">
              <span style={{fontSize: '60px', color: 'orange'}}>?</span>
            </div>
          </div>
          <h4 className="font-weight-bold mt-4">{t('message.title-delete')}</h4>
          <p className="mb-0 font-size-lg text-muted text-black-50">
            {t('message.description-delete')}
          </p>
          <div className="pt-4">
            <Button
              style={{height: '40px'}}
              onClick={toggle2}
              variant="contained"
              className="mx-1">
              <span className="btn-wrapper--label">{t('word.no')}</span>
            </Button>
            <Button
              onClick={toggle1}
              style={{height: '40px'}}
              color="primary"
              variant="contained"
              className="mx-1">
              <span className="btn-wrapper--label">
                {t('message.confirm-remove')}
              </span>
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
}
