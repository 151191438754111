import React from 'react';
import {Dialog, Grid} from '@material-ui/core';
import CropEasy from '../../CropEasy';

export default function ModalView(props) {
  const close = () => props.setOpenCrop(!props.OpenCrop);

  const {photoURL, setOpenCrop, setPhotoURL, setEditImage, formatCrop} = props;

  return (
    <>
      <Grid container direction="row" spacing={12}>
        <Dialog
          open={props.OpenCrop}
          onClose={close}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <div className="text-center p-5">
            <CropEasy
              {...{
                photoURL,
                setOpenCrop,
                setPhotoURL,
                setEditImage,
                formatCrop,
              }}
            />
          </div>
        </Dialog>
      </Grid>
    </>
  );
}
