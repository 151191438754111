/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import React, {Fragment, useState, useEffect} from 'react';
import {
  getAllUsers,
  getActiveActivities,
  SchedulePmsFilter,
  getSchedulePmsFilterPdf,
} from 'helpers/api';

import {useDispatch} from 'react-redux';
import clsx from 'clsx';
import {useTranslation} from 'react-i18next';
import {FaEllipsisV, FaFilter} from 'react-icons/fa';
import PerfectScrollbar from 'react-perfect-scrollbar';
import MomentUtils from '@date-io/moment';
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {Page} from 'store/reducers/Page/pageAction';
import PrintIcon from '@material-ui/icons/Print';
import {
  Grid,
  Fab,
  Typography,
  Divider,
  TextField,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  List,
  ListItem,
  Menu,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import moment from 'moment';
import 'moment/locale/pt-br';
import 'moment/locale/es';
import Enum from 'helpers/enum';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import AccountBoxIcon from '@material-ui/icons/AccountBox';

import SchedulePms from '../../components/List/SchedulePms';

function LivePreviewExample(props) {
  const {t, i18n} = useTranslation();
  const dispatch = useDispatch();

  const [users, setUsers] = useState([]);
  const [act, setAct] = useState([]);
  const [opt1, setOpt1] = useState(true);
  const [opt2, setOpt2] = useState(false);
  const [usersID, setUsersID] = useState([]);
  const [usersName, setUsersName] = useState([]);
  const [actID, setActID] = useState([]);
  const [actName, setActName] = useState([]);
  const [list, setList] = useState([]);
  const [nro, setNro] = useState('');
  const date = new Date();
  const [firstD, setFirstD] = useState(
    moment(new Date(date.setDate(date.getDate() - 15))).format('YYYY/MM/DD'),
  );
  const [lastD, setLastD] = useState(moment(new Date()).format('YYYY/MM/DD'));
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const [details, setDetails] = useState(false);

  const [maxDate, setMaxDate] = useState(new Date());

  moment.locale(i18n.language);

  const getAll = async (idactivity = [], iduser = []) => {
    props.setOp(true);
    const arr = [
      ...(opt1 ? [Enum.schedule_status.scheduled] : []),
      ...(opt2 ? [Enum.schedule_status.canceled] : []),
    ];
    const res = await SchedulePmsFilter(
      firstD,
      lastD,
      idactivity,
      iduser,
      arr,
      nro,
    );
    props.setOp(false);
    if (res && !res.errors) {
      for (const i in res) {
        res[i].date = moment(res[i].date).format(t('format.date'));
      }
      setList(res);
    } else {
      for (const i of res.errors) {
        props.openAlert(
          t(`message.${i.code}`, {param: t(`word.${i.field}`)}),
          i.color,
        );
      }
    }
  };
  const getByFilter = async () => {
    props.setOp(true);
    const arr = [
      ...(opt1 ? [Enum.schedule_status.scheduled] : []),
      ...(opt2 ? [Enum.schedule_status.canceled] : []),
    ];
    const res = await SchedulePmsFilter(
      firstD,
      lastD,
      actID,
      usersID,
      arr,
      nro,
    );
    if (res && !res.errors) {
      for (const i in res) {
        res[i].date = moment(res[i].date).format(t('format.date'));
      }
      setList(res);
    } else {
      setList([]);
      for (const i of res.errors) {
        props.openAlert(
          t(`message.${i.code}`, {param: t(`word.${i.field}`)}),
          i.color,
        );
      }
    }
    props.setOp(false);
  };

  useEffect(() => {
    dispatch(Page(t('word.maintenance')));
    setMaxDate(
      new Date(
        `${maxDate.getFullYear() + 2}-${
          maxDate.getMonth() + 1
        }-${maxDate.getDate()} 01:00:00`,
      ),
    );
    const getUsers = async () => {
      const res = await getAllUsers();
      if (res && !res.errors) {
        setUsers(res);
      } else if (res.errors) {
        for (const i of res.errors) {
          props.openAlert(
            t(`message.${i.code}`, {param: t(`word.${i.field}`)}),
            i.color,
          );
        }
      }
    };
    const getActivities = async () => {
      const res = await getActiveActivities();
      if (res && !res.errors) {
        setAct(res);
      } else if (res.errors) {
        for (const i of res.errors) {
          props.openAlert(
            t(`message.${i.code}`, {param: t(`word.${i.field}`)}),
            i.color,
          );
        }
      }
    };
    getAll();
    getActivities();
    getUsers();
    // eslint-disable-next-line
  }, []);

  const handleUsers = (value) => {
    const arr = [];
    const arr2 = [];
    value.map((i) => {
      arr.push(i.iduser);
      arr2.push(i.name);
      return true;
    });
    setUsersID(arr);
    setUsersName(arr2);
  };

  const handleActs = (value) => {
    const arr = [];
    const arrName = [];
    value.map((i) => {
      arr.push(i.idactivity);
      arrName.push(i.activity);
      return true;
    });
    setActID(arr);
    setActName(arrName);
  };

  const handleClickMenu = (event) => {
    setAnchorElMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  const getByDatePdf = async (first, last, status, status2, typeFilter) => {
    const res = await getSchedulePmsFilterPdf(
      first,
      last,
      actID,
      actName,
      usersID,
      usersName,
      status,
      status2,
      typeFilter,
      details,
      nro,
    );
    if (res && !res.errors) {
      const pdfWindow = window.open('');
      pdfWindow.document.write(
        `<iframe width='100%' height='100%' src='data:application/pdf;base64, ${encodeURI(
          res,
        )}'></iframe>`,
      );
      setAnchorElMenu(false);
      await getByFilter();
    } else {
      for (const i of res.errors) {
        props.openAlert(
          t(`message.${i.code}`, {param: t(`word.${i.field}`)}),
          i.color,
        );
      }
    }
  };

  const handleFiltersPdf = async (tpFilter) => {
    props.setOp(true);
    const arr = [
      ...(opt1 ? [Enum.schedule_status.scheduled] : []),
      ...(opt2 ? [Enum.schedule_status.canceled] : []),
    ];
    const arr2 = [
      ...(opt1 ? [t('word.schedule-pms.0')] : []),
      ...(opt2 ? [t('word.schedule-pms.1')] : []),
    ];
    await getByDatePdf(firstD, lastD, arr, arr2, tpFilter);
    props.setOp(false);
  };

  const handleFilter = (e) => {
    const filt = e;
    handleFiltersPdf(filt);
  };

  const [isSidebarMenuOpen, setIsSidebarMenuOpen] = useState(false);

  const toggleSidebarMenu = () => setIsSidebarMenuOpen(!isSidebarMenuOpen);

  return (
    <>
      <Grid container>
        <div className="app-inner-content-layout app-inner-content-layout-fixed">
          <div className="d-flex d-lg-none p-4 order-0 justify-content-between align-items-center">
            <Fab onClick={toggleSidebarMenu} color="primary" size="small">
              <FaEllipsisV className="font-size-sm" />
            </Fab>
          </div>
          <div
            className={clsx(
              'app-inner-content-layout--sidebar app-inner-content-layout--sidebar__lg bg-secondary border-right',
              {'layout-sidebar-open': isSidebarMenuOpen},
            )}>
            <PerfectScrollbar>
              <div className="px-4 pt-4 pb-3">
                <Typography color="primary" component="div" className="d-flex">
                  <div
                    className="ml-auto font-size-xs"
                    style={{paddingLeft: '80px'}}>
                    <Fab
                      size="small"
                      aria-label="add"
                      color="secondary"
                      onClick={() => getByFilter()}>
                      <FaFilter />
                    </Fab>
                  </div>
                  <div className="ml-auto font-size-xs">
                    <Fab
                      size="small"
                      disabled={list.length < 1}
                      aria-label="add"
                      color="secondary"
                      onClick={handleClickMenu}>
                      <PrintIcon />
                    </Fab>
                  </div>

                  <div>
                    <Menu
                      anchorEl={anchorElMenu}
                      keepMounted
                      open={Boolean(anchorElMenu)}
                      onClose={handleCloseMenu}
                      classes={{list: 'p-0'}}
                      getContentAnchorEl={null}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}>
                      <div className="overflow-hidden dropdown-menu-xl">
                        <List className="nav-danger nav-pills flex-column p-3">
                          <Typography
                            className="text-black py-2 px-3 font-weight-bold"
                            component="div">
                            {t('word.ordination')}
                          </Typography>
                          <ListItem
                            onClick={() => handleFilter('0')}
                            button
                            className="rounded-sm">
                            <div className="nav-link-icon opacity-6">
                              <CalendarTodayIcon />
                            </div>
                            <span>{t('word.by-date')}</span>
                          </ListItem>
                          <ListItem
                            onClick={() => handleFilter('1')}
                            button
                            className="rounded-sm">
                            <div className="nav-link-icon opacity-6">
                              <AccountBoxIcon />
                            </div>
                            <span>{t('word.by-account')}</span>
                          </ListItem>
                          <Divider className="w-100 mt-2" />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={details}
                                onChange={() => setDetails(!details)}
                                value={details}
                              />
                            }
                            label={t('word.print-details')}
                          />
                        </List>
                      </div>
                    </Menu>
                  </div>
                </Typography>
                <Grid item direction="column">
                  <MuiPickersUtilsProvider
                    libInstance={moment}
                    utils={MomentUtils}
                    locale={i18n.language}>
                    <DatePicker
                      label={t('word.date1')}
                      className="m-1"
                      variant="inline"
                      disableToolbar
                      value={firstD}
                      maxDate={maxDate}
                      onChange={(e) =>
                        setFirstD(moment(e).format('YYYY/MM/DD'))
                      }
                      format="L"
                      autoOk
                      invalidDateMessage={t('message.invalid-date')}
                    />
                    <DatePicker
                      label={t('word.date2')}
                      className="m-1"
                      variant="inline"
                      disableToolbar
                      value={lastD}
                      maxDate={maxDate}
                      onChange={(e) => setLastD(moment(e).format('YYYY/MM/DD'))}
                      format="L"
                      autoOk
                      invalidDateMessage={t('message.invalid-date')}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <div className="text-first font-weight-bold mt-2">
                  {t('word.integration-status')}
                </div>
                <FormControl component="fieldset">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={opt1}
                          onChange={(e) => setOpt1(e.target.checked)}
                          value={Enum.schedule_pms.notintegrated}
                        />
                      }
                      label={t('word.schedule-pms.0')}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={opt2}
                          onChange={(e) => setOpt2(e.target.checked)}
                          value={Enum.schedule_pms.integrated}
                        />
                      }
                      label={t('word.schedule-pms.1')}
                    />
                  </FormGroup>
                </FormControl>
              </div>
              <div className="divider mt-2" />
              <div className="p-4 bg-white">
                <Typography
                  color="primary"
                  component="div"
                  className="d-flex pb-3 align-items-center">
                  <div className="text-first font-weight-bold">
                    {t('word.activities')}
                  </div>
                  <div className="ml-auto font-size-xs" />
                </Typography>
                <Grid container spacing={4} className="font-size-xs">
                  <Grid item xs={12}>
                    <Autocomplete
                      multiple
                      id="fixed-tags-demo"
                      options={act}
                      onChange={(event, newValue) => {
                        handleActs(newValue);
                      }}
                      getOptionLabel={(option) => option.activity}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            label={option.activity}
                            {...getTagProps({index})}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t('word.select')}
                          variant="outlined"
                          placeholder={t('word.activities')}
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </div>
              <Divider />
              <div className="p-4">
                <Typography
                  color="primary"
                  component="div"
                  className="d-flex pb-3 align-items-center">
                  <div className="text-first font-weight-bold">
                    {t('word.professionals')}
                  </div>
                  <div className="ml-auto font-size-xs" />
                </Typography>
                <Grid container spacing={4} className="font-size-xs">
                  <Grid item xs={12}>
                    <Autocomplete
                      multiple
                      id="fixed-tags-demo"
                      options={users}
                      onChange={(event, newValue) => {
                        handleUsers(newValue);
                      }}
                      getOptionLabel={(option) => option.name}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip label={option.name} {...getTagProps({index})} />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t('word.select')}
                          variant="outlined"
                          placeholder={t('word.professionals')}
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </div>

              {Enum.arrPms.includes(props.pms) && (
                <>
                  <Divider />
                  <div className="p-4">
                    <Typography
                      color="primary"
                      component="div"
                      className="d-flex pb-3 align-items-center">
                      <div className="text-first font-weight-bold">
                        {t('word.N-account')}
                      </div>
                      <div className="ml-auto font-size-xs" />
                    </Typography>
                    <Grid container spacing={4} className="font-size-xs">
                      <Grid item xs={12}>
                        <TextField
                          id="outlined-helperText"
                          label={t('word.N-account')}
                          variant="outlined"
                          value={nro}
                          type="number"
                          onChange={(e) => setNro(e.target.value)}
                          inputProps={{maxLength: 128}}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </>
              )}
            </PerfectScrollbar>
          </div>
          <div className="app-inner-content-layout--main bg-white p-0 card-box">
            <PerfectScrollbar>
              <div className="p-4">
                <SchedulePms
                  companyConfigs={props.companyConfigs}
                  setList={setList}
                  list={list}
                  openAlert={props.openAlert}
                  setOp={props.setOp}
                />
              </div>
            </PerfectScrollbar>
          </div>
          {/* <div
            onClick={toggleSidebarMenu}
            className={clsx('sidebar-inner-layout-overlay', {
              active: isSidebarMenuOpen,
            })}
          /> */}
        </div>
      </Grid>
    </>
  );
}

export default React.memo(LivePreviewExample);
