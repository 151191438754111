import React, {Fragment, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {
  IconButton,
  Box,
  Popover,
  Button,
  Tooltip,
  Hidden,
  Grid,
  Divider,
  List,
  ListItem,
  Fab,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import {useTranslation} from 'react-i18next';
import CalendarTodayOutlined from '@material-ui/icons/CalendarTodayOutlined';
// import UpdateIcon from '@material-ui/icons/Update';
import PollTwoToneIcon from '@material-ui/icons/PollTwoTone';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';
import Enum from '../../helpers/enum';
import {
  getPmsPending,
  getCountProfessionalScheduleDay,
  getAllScheduleBeforeDayByProfessional,
} from '../../helpers/api';

export default function HeaderDots(props) {
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [anchorEl3, setAnchorEl3] = useState(null);
  const history = useHistory();
  const {t} = useTranslation();
  const [pendingPms, setPendingPms] = useState(0);
  const [pendingS, setPendingS] = useState({});
  const [scheduleBefore, setScheduleBefore] = useState([]);

  const handleGo = (path) => {
    setAnchorEl2(false);
    setAnchorEl1(false);
    history.push(path);
  };

  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const handleClick3 = (event) => {
    setAnchorEl3(event.currentTarget);
  };
  const handleClose3 = () => {
    setAnchorEl3(null);
  };

  const open1 = Boolean(anchorEl1);
  const open2 = Boolean(anchorEl2);
  const open3 = Boolean(anchorEl3);

  const functionPage = async () => {
    if (props.configs.maintance === true && Enum.arrPms.includes(props.pms)) {
      const x = await getPmsPending();
      setPendingPms(x);
    }
    const s = await getCountProfessionalScheduleDay();
    setPendingS(s);

    const res = await getAllScheduleBeforeDayByProfessional();
    if (res && !res.errors) {
      setScheduleBefore(res);
    }
  };

  useEffect(() => {
    const get = async () => {
      await functionPage();
    };
    get();
    // eslint-disable-next-line
  }, [props.pms]);

  const handleDate = (date) => {
    setAnchorEl3(false);
    window.location.href = `/home?date=${date}`;
  };

  return (
    <>
      <div className="d-flex align-items-center popover-header-wrapper">
        {scheduleBefore.count > 0 && (
          <div className="d-flex align-items-center popover-header-wrapper">
            <Hidden>
              <Box component="span" pr="2">
                <Tooltip
                  arrow
                  title={t(
                    `word.${
                      scheduleBefore === 1
                        ? 'schedule-pending'
                        : 'schedules-pending'
                    }`,
                  )}>
                  <IconButton
                    onClick={handleClick3}
                    color="inherit"
                    className="btn-inverse mx-1 d-50">
                    <div className="badge badge-pill badge-warning badge-header">
                      {scheduleBefore.count}
                    </div>
                    <PollTwoToneIcon />
                  </IconButton>
                </Tooltip>
                <Popover
                  open={open3}
                  anchorEl={anchorEl3}
                  onClose={handleClose3}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  classes={{
                    paper: 'app-header-dots',
                  }}>
                  <Grid container xs={12} spacing={12}>
                    <div className="popover-custom-xl overflow-hidden">
                      <Grid item xs={12} lg={12}>
                        <div className="bg-composed-wrapper bg-midnight-bloom border-0 text-center rounded-sm m-2">
                          <div className="bg-composed-img-3 bg-composed-wrapper--image" />
                          <div className="bg-composed-wrapper--content text-light px-2 py-4">
                            <h4 className="font-size-xl font-weight-bold mb-2">
                              {t('message.schedule-pending-losers')}
                            </h4>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={12} lg={12}>
                        <div className="height-280">
                          {/* <Card className="mb-4"> */}
                          <div className="scroll-area-sm shadow-overflow">
                            <PerfectScrollbar>
                              <List>
                                {scheduleBefore.arr &&
                                  scheduleBefore.arr.map((i) => (
                                    <>
                                      <ListItem className="d-flex justify-content-between align-items-center py-3">
                                        <div className="d-flex align-items-center">
                                          {/* <Avatar alt="..." src={avatar2} className="mr-2" /> */}
                                          <div>
                                            <a
                                              href="#/"
                                              onClick={(e) =>
                                                e.preventDefault()
                                              }
                                              className="font-weight-bold text-black"
                                              title="">
                                              {moment(i.date).format(
                                                t('format.date'),
                                              )}
                                            </a>
                                          </div>
                                          {i.scheduled > 0 && (
                                            <Tooltip
                                              arrow
                                              title={t(
                                                `word.schedule-status.0`,
                                              )}>
                                              <span className="btn-pill mx-3 badge badge-primary">
                                                {i.scheduled}
                                              </span>
                                            </Tooltip>
                                          )}
                                          {i.inprogress > 0 && (
                                            <Tooltip
                                              arrow
                                              title={t(
                                                `word.schedule-status.2`,
                                              )}>
                                              <span className="btn-pill mx-3 badge badge-warning">
                                                {i.inprogress}
                                              </span>
                                            </Tooltip>
                                          )}
                                        </div>
                                        <Tooltip
                                          arrow
                                          title={t('word.Consult')}>
                                          <Fab
                                            onClick={() =>
                                              handleDate(
                                                moment(i.date).format(
                                                  t('format.date'),
                                                ),
                                              )
                                            }
                                            size="small"
                                            color="secondary"
                                            aria-label="add"
                                            className="m-2">
                                            <SearchIcon />
                                          </Fab>
                                        </Tooltip>
                                      </ListItem>
                                      <Divider className="opacity-6" />
                                    </>
                                  ))}
                              </List>
                            </PerfectScrollbar>
                          </div>
                          {/* </Card> */}
                          <div className="text-center">
                            <Button
                              onClick={() => handleClose3()}
                              variant="contained"
                              className="align-items-center"
                              size="small"
                              color="primary">
                              {t('word.back')}
                            </Button>
                          </div>
                        </div>
                      </Grid>
                    </div>
                  </Grid>
                </Popover>
              </Box>
            </Hidden>
          </div>
        )}

        {props.configs.maintance === true &&
          pendingPms > 0 &&
          Enum.arrPms.includes(props.pms) && (
            <Box component="span" pr="2">
              <Tooltip arrow title={t('word.integration-pending')}>
                <IconButton
                  onClick={handleClick1}
                  color="inherit"
                  className="btn-inverse mx-1 d-50">
                  <div
                    style={{backgroundColor: 'red', fontSize: '10px'}}
                    className="badge badge-pill badge-header">
                    {' '}
                    {/* badge-warning */}
                    {pendingPms >= 100 ? '+99' : pendingPms}
                  </div>
                  <AutorenewIcon />
                </IconButton>
              </Tooltip>
              <Popover
                open={open1}
                anchorEl={anchorEl1}
                onClose={handleClose1}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}>
                <Box className="overflow-hidden border-0 p-0 dropdown-mega-menu-sm">
                  <div className="px-3 py-3 text-center">
                    <div className="mx-3 mb-2 mt-3 d-inline-block text-center">
                      <IconButton
                        color="primary"
                        className="p-0 bg-arielle-smile d-inline-block text-center text-white d-50 rounded border-0 mb-2">
                        <AutorenewIcon />
                      </IconButton>
                      <div className="d-block text-black-50">
                        {pendingPms > 1
                          ? t(`message.integrations-count`, {param: pendingPms})
                          : t(`message.integrations-count`, {
                              param: pendingPms,
                            })}
                      </div>
                    </div>
                  </div>
                  <div className="divider mb-3" />
                  <div className="text-center mb-3">
                    <Button
                      onClick={() => handleGo('/pms/schedule')}
                      variant="contained"
                      size="small"
                      color="primary">
                      {t('word.integrate')}
                    </Button>
                  </div>
                </Box>
              </Popover>
            </Box>
          )}
        {pendingS.total > 0 && (
          <Box component="span" pr="2">
            <Tooltip arrow title={t('word.schedule')}>
              <IconButton
                onClick={handleClick2}
                color="inherit"
                className="btn-inverse mx-1 d-50">
                <div
                  style={{fontSize: '10px'}}
                  className="badge badge-pill badge-secondary badge-header">
                  {pendingS.total >= 100 ? '+99' : pendingS.total}
                </div>
                <CalendarTodayOutlined />
              </IconButton>
            </Tooltip>
            <Popover
              open={open2}
              anchorEl={anchorEl2}
              onClose={handleClose2}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}>
              <Box className="overflow-hidden border-0 p-0 dropdown-mega-menu-sm">
                <div className="px-3 py-3 text-center">
                  <div className="mx-3 mb-2 mt-3 d-inline-block text-center">
                    <IconButton
                      color="primary"
                      className="p-0 bg-arielle-smile d-inline-block text-center text-white d-50 rounded border-0 mb-2">
                      <CalendarTodayOutlined />
                    </IconButton>
                    <div className="d-block text-black-50">
                      {t('message.professional-schedule')}
                    </div>
                  </div>
                  <div className="mx-3 mb-2 mt-3 d-inline-block text-center">
                    <Tooltip arrow title={t(`word.schedule-status.0`)}>
                      <span className="btn-pill mx-3 badge badge-primary">
                        {pendingS['0']}
                      </span>
                    </Tooltip>
                    <Tooltip arrow title={t(`word.schedule-status.1`)}>
                      <span className="btn-pill mx-3 badge badge-danger">
                        {pendingS['1']}
                      </span>
                    </Tooltip>
                    <Tooltip arrow title={t(`word.schedule-status.2`)}>
                      <span className="btn-pill mx-3 badge badge-warning">
                        {pendingS['2']}
                      </span>
                    </Tooltip>
                    <Tooltip arrow title={t(`word.schedule-status.3`)}>
                      <span className="btn-pill mx-3 badge badge-success">
                        {pendingS['3']}
                      </span>
                    </Tooltip>
                  </div>
                </div>

                <div className="divider mb-3" />
                <div className="text-center mb-3">
                  <Button
                    onClick={() => handleGo('/home')}
                    variant="contained"
                    size="small"
                    color="primary">
                    {t('word.Home')}
                  </Button>
                </div>
              </Box>
            </Popover>
          </Box>
        )}
      </div>
    </>
  );
}
