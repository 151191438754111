/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/prop-types */
import React, {Fragment, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
  IconButton,
  List,
  ListItem,
  Divider,
  Dialog,
  Grid,
  TextField,
  Button,
  Tooltip,
  Menu,
  Typography,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core';
import './style.css';
import {FaCheck, FaPlay, FaTimes} from 'react-icons/fa';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import Enum from 'helpers/enum';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ModalEditListSchedule from '../modals/modalEditListSchedule/index';
import ModalErrorPms from '../modals/ModalErrorPms';
import {getIdUser} from '../../helpers/auth';
import {editSchedule, editScheduleList} from '../../helpers/api';

export default function Accordeon(props) {
  const [expanded, setExpanded] = React.useState(false);
  const {t} = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [reason, setReason] = useState('');
  const [idSchedule, setIdSchedule] = useState();
  const [newStatus, setNewStatus] = useState();
  const [oldStatus, setOldStatus] = useState();
  const [optionsStart, setOptionsStart] = useState(false);
  const [optionsCancel, setOptionsCancel] = useState(false);
  const [optionsFinish, setOptionsFinish] = useState(false);
  const [index, setIndex] = useState();
  const [name, setName] = useState([]);
  const [status, setStatus] = useState();
  const [modalList, setModalList] = useState(false);
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const iduser = getIdUser();
  const [modalPms, setModalPms] = useState(false);
  const [pms, setPms] = useState([]);
  const [disable, setDisable] = useState(false);

  const options = () => {
    for (const i of props.card.schedule) {
      if (optionsStart === true && optionsFinish === true) {
        break;
      }
      if (i.status === Enum.schedule_status.scheduled) {
        setOptionsStart(true);
        if (i.disableCancel === false) {
          setOptionsCancel(true);
        }
      }
      if (i.status === Enum.schedule_status.inprogress) {
        setOptionsFinish(true);
        if (i.disableCancel === false) {
          setOptionsCancel(true);
        }
      }
    }
  };

  useEffect(() => {
    options();

    // eslint-disable-next-line
  }, [props.card.schedule]);

  const editListOne = () => {
    setOptionsStart(false);
    setOptionsFinish(false);
    setOptionsCancel(false);
    let c = 0;
    let d = 0;
    let e;
    for (const i of props.card.schedule) {
      if (
        i.status !== Enum.schedule_status.canceled &&
        i.status !== Enum.schedule_status.finished
      ) {
        if (i.status === Enum.schedule_status.scheduled && c < 1) {
          c += 1;
          setOptionsStart(true);
          if (i.disableCancel === false) {
            setOptionsCancel(true);
            e += 1;
          }
        } else if (i.status === Enum.schedule_status.inprogress && d < 1) {
          d += 1;
          setOptionsFinish(true);
          if (i.disableCancel === false) {
            setOptionsCancel(true);
            e += 1;
          }
        }
        if (c > 0 && d > 0 && e > 0) {
          break;
        }
      }
    }
  };

  const handleClose = () => {
    if (disable === false) {
      setOpenModal(false);
      setReason('');
    }
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleCancel = async (idschedule, oldstatus, k, disableCancel) => {
    if (disableCancel === true) {
      props.openAlert(
        t('message.impossible-cancel-schedule-starttime'),
        'error',
      );
      props.getAll(props.selectedDate);
    } else {
      setIndex(k);
      setOldStatus(oldstatus);
      setIdSchedule(idschedule);
      setNewStatus(Enum.schedule_status.canceled);
      setOpenModal(true);
    }
  };

  const handleStart = async (idschedule, oldstatus, k) => {
    setIndex(k);
    setOldStatus(oldstatus);
    setIdSchedule(idschedule);
    setNewStatus(Enum.schedule_status.inprogress);
    setOpenModal(true);
  };

  const handleFinish = async (idschedule, oldstatus, k) => {
    setIndex(k);
    setOldStatus(oldstatus);
    setIdSchedule(idschedule);
    setNewStatus(Enum.schedule_status.finished);
    setOpenModal(true);
  };

  const handleSubmit = async (e) => {
    props.setOp(true);
    e.preventDefault();
    setDisable(true);
    let res;
    setModalPms(false);
    setPms([]);
    if (newStatus === Enum.schedule_status.canceled) {
      res = await editSchedule(
        idSchedule,
        iduser,
        '',
        oldStatus,
        newStatus,
        reason,
      );
      if ((res && !res.errors) || (res.result && !res.result.errors)) {
        props.card.schedule[index].status = newStatus;
        editListOne();
        handleClose();
        props.openAlert(
          t('message.cancel-record', {param: t('word.schedule2')}),
          'success',
        );
      }
    }

    if (newStatus === Enum.schedule_status.inprogress) {
      res = await editSchedule(
        idSchedule,
        iduser,
        '',
        oldStatus,
        newStatus,
        reason,
      );
      if ((res && !res.errors) || (res.result && !res.result.errors)) {
        props.card.schedule[index].status = newStatus;
        editListOne();
        handleClose();
        props.openAlert(
          t('message.start-record', {param: t('word.schedule2')}),
          'success',
        );
      }
    }

    if (newStatus === Enum.schedule_status.finished) {
      res = await editSchedule(idSchedule, iduser, '', oldStatus, 3);
      if ((res && !res.errors) || (res.result && !res.result.errors)) {
        props.card.schedule[index].status = newStatus;
        editListOne();
        handleClose();
        props.openAlert(
          t('message.finish-record', {param: t('word.schedule2')}),
          'success',
        );
      }
    }

    if (res.errors) {
      for (const i of res.errors) {
        props.openAlert(
          t(`message.${i.code}`, {param: t(`word.${i.field}`)}),
          i.color,
        );
        if (i.field === 'update') {
          props.getAll(new Date(props.selectedDate));
          options();
        }
      }
      handleClose();
    }

    if (newStatus === 3 && res.pms !== undefined) {
      if (
        res.pms[0].error.error !== undefined &&
        res.pms[0].error.error === true
      ) {
        setModalPms(true);
        setPms(res.pms);
      }
    }
    setDisable(false);
    props.setOp(false);
  };

  const handleClickMenu = (event) => {
    setAnchorElMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  const getNames = (newStatus) => {
    let arr = [];
    const n = [];
    newStatus === Enum.schedule_status.canceled
      ? (arr = [
          Enum.schedule_status.scheduled,
          Enum.schedule_status.inprogress,
        ])
      : newStatus === Enum.schedule_status.inprogress
      ? (arr = [Enum.schedule_status.scheduled])
      : newStatus === Enum.schedule_status.finished
      ? (arr = [Enum.schedule_status.inprogress])
      : (arr = []);

    for (const i of props.card.schedule) {
      if (arr.includes(i.status)) {
        if (
          i.status === Enum.schedule_status.canceled &&
          i.disableCancel === false
        ) {
          n.push([i['person.name']]);
        } else if (i.status !== Enum.schedule_status.canceled) {
          n.push([i['person.name']]);
        }
      }
    }
    if (n.length === 0) {
      props.openAlert(
        t('message.impossible-cancel-schedule-starttime'),
        'warning',
      );
      props.getAll(props.selectedDate);
      return;
    }
    setName(n);
  };

  const handleModalList = (e) => {
    setStatus(e);
    getNames(e);
    setModalList(true);
  };

  const editList = (newStatus) => {
    setOptionsStart(false);
    setOptionsFinish(false);
    setOptionsCancel(false);
    for (const i of props.card.schedule) {
      if (
        i.status !== Enum.schedule_status.canceled &&
        i.status !== Enum.schedule_status.finished
      ) {
        if (
          newStatus === Enum.schedule_status.finished &&
          i.status === Enum.schedule_status.inprogress
        ) {
          i.status = newStatus;
        } else if (
          newStatus === Enum.schedule_status.inprogress &&
          i.status === Enum.schedule_status.scheduled
        ) {
          i.status = newStatus;
          setOptionsFinish(true);
          setOptionsCancel(true);
        }
        if (
          newStatus === Enum.schedule_status.canceled &&
          i.disableCancel === false
        ) {
          i.status = newStatus;
        }
        if (i.status === Enum.schedule_status.scheduled) {
          setOptionsStart(true);
          setOptionsCancel(true);
        }
      }
    }
  };

  const editListSch = async (
    idactivity,
    starttime,
    endtime,
    newStatus,
    formatDate,
    reason,
  ) => {
    props.setOp(true);
    const customer = '';
    setDisable(true);
    if (newStatus === 1 && !reason) {
      props.openAlert(
        t(`message.field-required`, {field: t(`word.reason`)}),
        'error',
      );
      return;
    }
    setModalPms(false);
    setPms([]);
    const res = await editScheduleList(
      idactivity,
      starttime,
      endtime,
      newStatus,
      formatDate,
      reason,
      customer,
    );
    props.setOp(false);
    if (res && !res.errors) {
      editList(newStatus);
      handleCloseMenu();
      props.openAlert(
        t(`message.edit-records`, {
          param: t(`word.schedules`),
          changed: t(`word.editschedule.${newStatus}`),
        }),
        'success',
      );
    }
    if (res.errors) {
      for (const i of res.errors) {
        props.openAlert(
          t(`message.${i.code}`, {param: t(`word.${i.field}`)}),
          i.color,
        );
        if (i.field === 'update') {
          props.getAll(new Date(props.selectedDate));
          options();
        }
      }
    }

    if (newStatus === 3 && res.pms !== undefined) {
      if (
        res.pms[0].error.error !== undefined &&
        res.pms[0].error.error === true
      ) {
        setModalPms(true);
        setPms(res.pms);
        setModalList(false);
      }
      return;
    }
    setModalList(false);
    setDisable(false);
  };

  const handleSchedule = (e, reason) => {
    const newStatus = e;
    const d = new Date(props.selectedDate);
    const date = moment(d).format('L');
    let formatDate;
    if (t('format.date') === 'DD/MM/YYYY') {
      formatDate = date.split('/').reverse().join('-');
      formatDate = moment(formatDate).format('YYYY-MM-DD');
    } else {
      formatDate = moment(date).format('YYYY-MM-DD');
    }
    editListSch(
      props.idactivity,
      props.card.starttime,
      props.card.endtime,
      newStatus,
      formatDate,
      reason,
    );
  };

  return (
    <>
      {modalPms === true && (
        <ModalErrorPms
          pms={pms}
          setModalPms={setModalPms}
          modalPms={modalPms}
        />
      )}

      <Dialog open={openModal} onClose={handleClose}>
        <div className="text-center p-5">
          {newStatus === Enum.schedule_status.canceled && (
            <>
              <div className="avatar-icon-wraper rounded-circle m-0">
                <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-danger text-danger m-0 d-130">
                  <span>
                    <FaTimes className="font-size-auto mx-auto" />
                  </span>
                </div>
              </div>
              <h4 className="font-weight-bold mt-4">
                {t('message.schedule-cancel')}
              </h4>
              {Enum.arrHaveNoShowPolicy.includes(props.act.noshowpolicy) &&
                props.act.noshowvalue > 0 && (
                  <>
                    <ListItem className="d-flex justify-content-between align-items-center py-3">
                      <div className="d-flex align-items-center">
                        {/* <Avatar alt="" src={avatar2} className="mr-2" /> */}
                        <div>
                          <a
                            href="#/"
                            onClick={(e) => e.preventDefault()}
                            className="font-weight-bold text-black"
                            title="">
                            {t('message.noshow-message', {
                              param: `
                          ${
                            props.companyConfigs.currencysymbol
                              ? props.companyConfigs.currencysymbol
                              : ''
                          }
                        ${
                          props.act.noshowpolicy === 1
                            ? (props.act.noshowvalue * props.act.price) / 100
                            : props.act.noshowvalue
                        }
                        `,
                            })}
                          </a>
                        </div>
                      </div>
                    </ListItem>
                    <Divider className="opacity-6" />
                  </>
                )}
            </>
          )}
          {newStatus === Enum.schedule_status.inprogress && (
            <>
              <div className="avatar-icon-wraper rounded-circle m-0">
                <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-primary text-primary m-0 d-130">
                  <span>
                    <FaPlay
                      icon={['fas', 'play']}
                      className="font-size-auto mx-auto"
                    />
                  </span>
                </div>
              </div>
              <h4 className="font-weight-bold mt-4">
                {t('message.schedule-start')}
              </h4>
            </>
          )}
          {newStatus === Enum.schedule_status.finished && (
            <>
              <div className="avatar-icon-wraper rounded-circle m-0">
                <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-success text-success m-0 d-130">
                  <span>
                    <FaCheck
                      icon={['fas', 'check']}
                      className="font-size-auto mx-auto"
                    />
                  </span>
                </div>
              </div>
              <h4 className="font-weight-bold mt-4">
                {t('message.schedule-finish')}
              </h4>
            </>
          )}
          <form onSubmit={handleSubmit}>
            {newStatus === Enum.schedule_status.canceled && (
              <Grid container align="left">
                <TextField
                  label={t('message.schedule-cancel-reason')}
                  id="standard-size-small"
                  className="mt-4"
                  size="small"
                  required
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  fullWidth
                />
              </Grid>
            )}
            <div className="pt-4">
              <Button
                disabled={disable}
                onClick={handleClose}
                style={{height: '40px'}}
                variant="contained"
                className="mx-1">
                <span className="btn-wrapper--label">{t('word.no')}</span>
              </Button>
              <Button
                disabled={disable}
                type="submit"
                style={{height: '40px'}}
                color="primary"
                variant="contained"
                className="mx-1">
                <span className="btn-wrapper--label">{t('word.yes')}</span>
              </Button>
            </div>
          </form>
        </div>
      </Dialog>
      {modalList && (
        <ModalEditListSchedule
          disable={disable}
          user={name}
          handleSchedule={handleSchedule}
          op={modalList}
          setOp={setModalList}
          status={status}
        />
      )}
      <ExpansionPanel
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header">
          <span
            style={{color: props.card.schedule.length > 0 ? '#000' : '#CCC'}}>
            {`${props.card.starttime} -- ${props.card.endtime}`}
          </span>
          {props.card.schedule.length > 0 && (
            <span className="btn-pill mx-3 badge badge-primary">
              {props.card.schedule.length}
            </span>
          )}
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className="d-block p-0">
          <Divider />

          <PerfectScrollbar>
            <List style={{maxHeight: '350px'}} className="py-0">
              {props.card.schedule &&
                props.card.schedule.length !== 0 &&
                props.card.schedule.map((i, k) => (
                  <>
                    <ListItem
                      // eslint-disable-next-line react/no-array-index-key
                      key={k}
                      className="hover-show-hide-container d-flex justify-content-between align-items-center py-3 border-0">
                      <Tooltip
                        arrow
                        title={t(`word.schedule-status.${i.status}`)}>
                        <span
                          className={`badge-circle mt-2 mr-2 align-self-start badge badge-${t(
                            `word.schedule-status-color.${i.status}`,
                          )}`}>
                          .
                        </span>
                      </Tooltip>
                      <div className="font-weight-bold flex-grow-1">
                        <div
                          style={{marginTop: '5px'}}
                          className="font-weight-bold d-block opacity-8">
                          {i['person.name']}
                        </div>
                        {i['person.main'] === false && (
                          <span
                            style={{fontSize: '14px'}}
                            className="opacity-8">
                            <span className="text-dark opacity-5">
                              {i['person.customer.name']}
                            </span>
                          </span>
                        )}
                      </div>

                      {i.status === Enum.schedule_status.scheduled && (
                        <>
                          <div className="avatar-icon-wrapper avatar-initials avatar-icon-xs">
                            <div
                              className="avatar-icon text-primary box-shadow-buttons"
                              style={{
                                backgroundColor: 'rgba(13, 98, 255, 0.2)',
                              }}>
                              <Tooltip
                                arrow
                                title={t(`message.start-activity`)}>
                                <IconButton
                                  onClick={() =>
                                    handleStart(i.idschedule, i.status, k)
                                  }
                                  className="text-primary rounded-circle p-0 ml-1">
                                  <FaPlay
                                    style={{paddingRight: '2px'}}
                                    className="font-size-md mx-auto"
                                  />
                                </IconButton>
                              </Tooltip>
                            </div>
                          </div>
                        </>
                      )}
                      {i.status === Enum.schedule_status.inprogress && (
                        <div className="avatar-icon-wrapper avatar-initials avatar-icon-xs">
                          <div
                            className="avatar-icon text-primary box-shadow-buttons"
                            style={{backgroundColor: 'rgba(4, 237, 0, 0.2)'}}>
                            <Tooltip arrow title={t(`message.finish-activity`)}>
                              <IconButton
                                onClick={() =>
                                  handleFinish(i.idschedule, i.status, k)
                                }
                                className="text-success rounded-circle p-0 ml-1">
                                <FaCheck
                                  style={{paddingRight: '3px'}}
                                  className="font-size-md mx-auto"
                                />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </div>
                      )}
                      {(i.status === Enum.schedule_status.scheduled ||
                        i.status === Enum.schedule_status.inprogress) && (
                        <div className="avatar-icon-wrapper avatar-initials avatar-icon-xs">
                          <div
                            className="avatar-icon text-primary box-shadow-buttons"
                            style={{
                              backgroundColor:
                                i.disableCancel === true
                                  ? 'rgba(117, 120, 120 ,0.2)'
                                  : 'rgba(242, 23, 7,0.2)',
                            }}>
                            <Tooltip arrow title={t(`message.cancel-activity`)}>
                              <IconButton
                                disabled={i.disableCancel}
                                style={{
                                  color:
                                    i.disableCancel === true
                                      ? 'rgb(117, 120, 120)'
                                      : 'rgba(242, 23, 7)',
                                }}
                                onClick={() =>
                                  handleCancel(
                                    i.idschedule,
                                    i.status,
                                    k,
                                    i.disableCancel,
                                  )
                                }
                                className="text-danger rounded-circle p-0 ml-1">
                                <FaTimes
                                  style={{
                                    paddingRight: '3px',
                                    color:
                                      i.disableCancel === true
                                        ? 'rgb(117, 120, 120)'
                                        : 'rgba(242, 23, 7)',
                                  }}
                                  // style={{  }}
                                  className="font-size-md mx-auto"
                                />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </div>
                      )}
                    </ListItem>
                    <Divider />
                  </>
                ))}

              {props.card.schedule.length > 0 &&
                (optionsStart === true ||
                  optionsFinish === true ||
                  optionsCancel === true) && (
                  <>
                    <Button
                      color="primary"
                      onClick={handleClickMenu}
                      className="m-2 text-primary">
                      <span>{t('word.options')}</span>
                    </Button>
                    <div>
                      <Menu
                        anchorEl={anchorElMenu}
                        keepMounted
                        open={Boolean(anchorElMenu)}
                        onClose={handleCloseMenu}
                        classes={{list: 'p-0'}}
                        getContentAnchorEl={null}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}>
                        <div className="overflow-hidden dropdown-menu-xl">
                          <List className="nav-danger nav-pills flex-column p-3">
                            <Typography
                              className="text-black py-2 px-3 font-weight-bold"
                              component="div">
                              {t('message.options-all-period')}
                            </Typography>
                            {optionsStart === true && (
                              <ListItem
                                onClick={() => handleModalList(2)}
                                button
                                className="rounded-sm">
                                <div className="nav-link-icon opacity-6">
                                  <FaPlay className="font-size-md mx-auto" />
                                </div>
                                <span>{t('message.start-all-schedule')}</span>
                              </ListItem>
                            )}
                            {optionsFinish === true && (
                              <ListItem
                                onClick={() => handleModalList(3)}
                                button
                                className="rounded-sm">
                                <div className="nav-link-icon opacity-6">
                                  <FaCheck
                                    style={{paddingRight: '3px'}}
                                    className="font-size-md mx-auto"
                                  />
                                </div>
                                <span>
                                  {t('message.finished-all-schedule')}
                                </span>
                              </ListItem>
                            )}
                            {optionsCancel === true && (
                              <ListItem
                                onClick={() => handleModalList(1)}
                                button
                                className="rounded-sm">
                                <div className="nav-link-icon opacity-6">
                                  <FaTimes className="font-size-md mx-auto" />
                                </div>
                                <span>{t('message.cancel-all-schedule')}</span>
                              </ListItem>
                            )}
                          </List>
                        </div>
                      </Menu>
                    </div>
                  </>
                )}
              {(!props.card.schedule || props.card.schedule.length === 0) && (
                <ListItem>{t('message.no-schedule')}</ListItem>
              )}
            </List>
          </PerfectScrollbar>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </>
  );
}
