const INITIAL = {
  img: '',
  type: '',
};

export default function imgUser(state = INITIAL, action = {}) {
  switch (action.type) {
    case 'IMG_USER':
      return {...state, img: action.payload.img, type: action.payload.type};
    default:
      return state;
  }
}
